
import { Magic } from "magic-sdk"

// Initialize the Magic instance
// @ts-ignore
export const magic = new Magic(process.env.REACT_APP_MAGICLINK_KEY, {
  network: {
    // @ts-ignore
    rpcUrl: process.env.REACT_APP_RPC_TARGET,
    // @ts-ignore
    chainId: process.env.REACT_APP_CHAIN_ID,
  }
})