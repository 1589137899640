import Heading from "components/Heading/Heading";
import React from "react";
import NcImage from "shared/NcImage/NcImage";

export interface People {
  id: string;
  name: string;
  job: string;
  social: string;
  avatar: string;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Gian Irizarry`,
    job: "Founder and CEO",
    social: "https://www.linkedin.com/in/gianrizarry/",
    avatar:
      "https://res.cloudinary.com/cloudbloks/image/upload/v1700017332/2_nwpvlf.png",
  },
  {
    id: "2",
    name: `Oscar Rivera`,
    job: "Engineering Lead",
    social: "https://www.linkedin.com/in/oscarriverazayas",
    avatar:
      "https://res.cloudinary.com/cloudbloks/image/upload/v1701100017/osca_mttqnm.jpg",
  },
  {
    id: "3",
    name: `Luz Adriana`,
    job: "Business Developer",
    social: "https://www.linkedin.com/in/luz-adrianashern%C3%A1ndez",
    avatar:
      "https://res.cloudinary.com/cloudbloks/image/upload/v1700017333/5_qksiuz.png",
  },
  {
    id: "4",
    name: `Max Torres`,
    job: "Advisor",
    social: "https://www.linkedin.com/in/maxaotorres",
    avatar:
      "https://res.cloudinary.com/cloudbloks/image/upload/v1700017331/3_lwvwxx.png",
  },
  {
    id: "5",
    name: `Agnel Nieves`,
    job: "Advisor",
    social: " https://www.linkedin.com/in/agnelnieves",
    avatar:
      "https://res.cloudinary.com/cloudbloks/image/upload/v1700017331/6_ofwtxc.png",
  },
  {
    id: "6",
    name: `Yiselle Dipiní`,
    job: "Advisor",
    social: " https://www.linkedin.com/in/dipiniandreu",
    avatar:
      "https://res.cloudinary.com/cloudbloks/image/upload/v1700017334/7_axhsjv.png",
  },
  {
    id: "7",
    name: `Leandro Rios`,
    job: "Advisor",
    social: "",
    avatar:
      "https://res.cloudinary.com/cloudbloks/image/upload/v1700017336/8_fujqeh.png",
  },
];

const SectionFounder = () => {
  return (
    <div className="nc-SectionFounder relative">
      <h3 className="mt-2 md:mt-3 font-normal block text-base sm:text-xl text-neutral-500 dark:text-neutral-400">Meet the Heart of Our Company: <b>Our Team</b>. Get to know the talented individuals who drive innovation, creativity, and success within our organization. From visionary leaders to dedicated specialists, each team member plays a crucial role in shaping our company's journey.</h3>
      <br></br><br></br>
      <div className="grid sm:grid-cols-2 gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8">
        {FOUNDER_DEMO.map((item) => (
          <div key={item.id} className="max-w-sm">
            <a href={item.social} target="_blank">
              <NcImage
                containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                className="absolute inset-0 object-cover"
                src={item.avatar}
              />
            </a>
            <h3 className="text-lg font-semibold text-neutral-900 mt-4 md:text-xl dark:text-neutral-200">
              {item.name}
            </h3>
            <span className="block text-sm text-neutral-500 sm:text-base dark:text-neutral-400">
              {item.job}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionFounder;
