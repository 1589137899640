import React ,{ useEffect } from "react";
import { Switch, Route, useLocation } from "react-router-dom";
import { Page } from "./types";
import analytics from "utils/analytics";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/FooterDbloks";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import UserAccountPage from "containers/AccountPage/UserAccount";
import AccountPage from "containers/AccountPage/AccountPage";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogRebrand from "containers/BlogPage/BlogRebrand";
import SiteHeader from "containers/SiteHeader";
import NftDetailPage from "containers/NftDetailPage/NftDetailPage";
import CreateEventPage from "containers/CreateEventPage/CreateEventPage"
import EventCreated from "containers/CreateEventPage/EventCreatedPage";
// @ts-ignore
import NftPurchase from "containers/NftDetailPage/NftPurchase";
import PageCollection from "containers/PageCollection";
import PageSearch from "containers/PageSearch";
import PageUploadItem from "containers/PageUploadItem";
import PageConnectWallet from "containers/PageConnectWallet";
import PageSuccessPayment from "containers/PageSuccessPayment";
import PageSuccessRegistration from "containers/PageSuccessRegistration";
import PageErrorRegistration from "containers/PageErrorRegistration";
import PageHome2 from "containers/PageHome/PageHome2";
import MyCollectibles from "containers/MyCollectibles/MyCollectibles";
import InvestorMixerPage from "containers/NftDetailPage/InvestorMixer";
import QRAuth from "containers/Authentication/QRAuth";
import PageTermsOfUse from "containers/PoliciesPage/TermsOfUse";
import PageTermsOfUseES from "containers/PoliciesPage/TermsOfUseES";
import PagePrivacyPolicy from "containers/PoliciesPage/PrivacyPolicy";
import PagePrivacyPolicyES from "containers/PoliciesPage/PrivacyPolicyES";
import RegistrationDetails from "containers/NftDetailPage/RegistrationDetailsPage";
import PageDeck from "containers/PageDeck/Deck";
import RandomSelector from "containers/Giveaway/RandomSelector";
import BlogSingle from "containers/BlogPage/BlogSingle";
import MyTikey from "containers/Authentication/MyTikey";
import VirtualMeet from "containers/Authentication/VirtualMeet";
import PreRegistration from "containers/NftDetailPage/PreRegistration";
import EventChat from "containers/Authentication/EventChat";
import EventGallery from "containers/NftDetailPage/EventGallery";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome2 },
  { path: "/#", exact: true, component: PageHome2 },
  { path: "/home2", exact: true, component: PageHome },
  //
  { path: "/home-header-2", exact: true, component: PageHome },
  { path: "/nft-detailt", component: NftDetailPage },
  { path: "/event/:id", component: NftPurchase },
  { path: "/my-collectibles", component: MyCollectibles },
  { path: "/investor-mixer/:id", component: InvestorMixerPage },
  { path: "/create-event", component: CreateEventPage },
  { path: "/event-created", component: EventCreated},
  { path: "/page-collection", component: PageCollection },
  { path: "/page-search", component: PageSearch },
  { path: "/user/:id", component: UserAccountPage },
  { path: "/account", component: AccountPage },
  { path: "/page-upload-item", component: PageUploadItem },
  { path: "/connect-wallet", component: PageConnectWallet },
  { path: "/success-payment", component: PageSuccessPayment },
  { path: "/success-registration", component: PageSuccessRegistration },
  { path: "/error-registration", component: PageErrorRegistration },
  //
  { path: "/blog", component: BlogPage },
  { path: "/blog-single", component: BlogSingle },
  { path: "/rebranding-dbloks-to-tiike", component: BlogRebrand },
  
  //
  { path: "/contact", component: PageContact },
  { path: "/about", component: PageAbout },
  { path: "/signup", component: PageSignUp },
  { path: "/login", component: PageLogin },
  { path: "/subscription", component: PageSubcription },
  { path: "/qr-auth/:id", component: QRAuth },
  { path: "/terms-of-use", component: PageTermsOfUse },
  { path: "/terms-of-useES", component: PageTermsOfUseES },
  { path: "/privacy-policy", component: PagePrivacyPolicy },
  { path: "/privacy-policyES", component: PagePrivacyPolicyES },
  { path: "/event-details/:id", component: RegistrationDetails },
  { path: "/deck", component: PageDeck },
  { path: "/random-selector/:id", component: RandomSelector },
  { path: "/my-tikey", component: MyTikey },
  { path: "/virtual/:id", component: VirtualMeet },
  { path: "/pre-register/:id", component: PreRegistration },
  { path: "/chat", component: EventChat },
  { path: "/gallery/:id", component: EventGallery },
];

const usePageTracking = () => {
  const location = useLocation();

  useEffect(() => {

    const pathname = location.pathname
    const pathsCountOnString = (pathname.split("/").length - 1)

    if (pathname === '/') {
      
      analytics.track('pageview_home');

    } else if (pathsCountOnString == 1) {

      const pagePath = pathname.substring(pathname.indexOf("/") + 1, pathname.lastIndexOf(""));
      analytics.track('pageview_' + pagePath);

    } else if (pathsCountOnString >= 2) {
     
      const pagePath = pathname.substring(pathname.indexOf("/") + 1, pathname.lastIndexOf("/"));
      analytics.track('pageview_' + pagePath);

    }

  }, [location]);
};

const Routes = () => {

  // Track page for analytics
  usePageTracking();

  return (
    <div>
      <ScrollToTop />
      <SiteHeader />
      <Switch>
        {pages.map(({ component, path, exact }) => {
          return (
            <Route
              key={path}
              component={component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>
      <Footer />
    </div>
  );
};

export default Routes;