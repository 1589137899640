import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
//
import "./styles/index.scss";
import "./index.css";
import "./fonts/line-awesome-1.3.0/css/line-awesome.css";
import "rc-slider/assets/index.css";

//

import reportWebVitals from "./reportWebVitals";
import { Provider } from "react-redux";
import { persistor, store } from "app/store";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";
import { PersistGate } from "redux-persist/integration/react";
import * as log from "loglevel";
import { I18nextProvider } from "react-i18next";
import "./i18n";
import i18next from "i18next";

import { UserProvider } from "./context/UserContext"
import MagicProvider from "context/MagicProvider";



// replace console.* for disable log on production
if (process.env.REACT_APP_NODE_ENV === "production") {
  console.log = () => {};
  console.error = () => {};
  console.debug = () => {};
  console.warn = () => {};
  console.info = () => {};
  log.disableAll();
}

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DNS,
  integrations: [
    new BrowserTracing({
      tracePropagationTargets: [],
    }),
  ],
  environment: process.env.REACT_APP_NODE_ENV,
  tracesSampleRate: 1.0,
});

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

root.render(
  <Provider store={store}>
    <Suspense fallback="Loading translations">
      <I18nextProvider i18n={i18next}>
      <PersistGate loading={null} persistor={persistor}>
      <MagicProvider>
        <UserProvider>
          <App />
        </UserProvider>
        </MagicProvider>
      </PersistGate>
      </I18nextProvider>
    </Suspense>
  </Provider>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
