import { CheckIcon } from "@heroicons/react/solid";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface PageTermsOfUseProps {
  className?: string;
}

const PageTermsOfUse: FC<PageTermsOfUseProps> = ({ className = "" }) => {

  return (
    <div
      className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
      data-nc-id="PageTermsOfUse"
    >
      <header className="text-center max-w-2xl mx-auto my-20">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          TÉRMINOS DE USO
        </h2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Última actualización: 26 de agosto de 2023
        </span>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">Lea estos términos y condiciones detenidamente antes de utilizar Nuestro Servicio.</span>
      </header>
      <section className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Interpretación y Definiciones</h1><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Interpretación</h2><br></br>
          <p>Las palabras cuya letra inicial está en mayúscula tienen significados definidos bajo las siguientes condiciones. Las siguientes definiciones tendrán el mismo significado independientemente de que aparezcan en singular o en plural.</p><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Definiciones</h2><br></br>
          <p>A los efectos de estos Términos y Condiciones:</p><br></br>
          <ul>
            <li>
              <p><strong>Afiliado</strong> significa una entidad que controla, es controlada o está bajo el control común de una parte, donde &quot;control&quot; significa la propiedad del 50% o más de las acciones, participación accionaria u otros valores con derecho a voto para la elección de directores u otra autoridad administrativa.</p>
            </li>
            <li>
              <p><strong>Cuenta</strong> significa una cuenta única creada para que Usted acceda a nuestro Servicio o partes de nuestro Servicio.</p>
            </li>
            <li>
              <p><strong>País</strong> se refiere a: Puerto Rico</p>
            </li>
            <li>
              <p><strong>Compañía</strong> (denominada como &quot;la Compañía&quot;, &quot;Nosotros&quot;, &quot;Nos&quot; o &quot;Nuestro&quot; en este Acuerdo) refers to dBloks LLC, 100 Calle Alondra Unidad 30951 San Juan PR 00924.</p>
            </li>
            <li>
              <p><strong>Contenido</strong> se refiere a contenido como texto, imágenes u otra información que usted puede publicar, cargar, vincular o poner a disposición de otro modo, independientemente de la forma de ese contenido.</p>
            </li>
            <li>
              <p><strong>Dispositivo</strong> significa cualquier dispositivo que pueda acceder al Servicio, como una computadora, un teléfono celular o una tableta digital.</p>
            </li>
            <li>
              <p><strong>Feedback</strong> significa comentarios, innovaciones o sugerencias enviadas por Usted con respecto a los atributos, el rendimiento o las características de nuestro Servicio.</p>
            </li>
            <li>
              <p><strong>Bienes</strong> referirse a los artículos ofrecidos a la venta en el Servicio.</p>
            </li>
            <li>
              <p><strong>Pedidos</strong> significa una solicitud por su parte para comprarnos Bienes.</p>
            </li>
            <li>
              <p><strong>Servicio</strong> hace referencia al Sitio Web.</p>
            </li>
            <li>
              <p><strong>Términos y condiciones</strong> (también conocido como &quot;Términos&quot;) significa estos Términos y Condiciones que forman el acuerdo completo entre Usted y la Compañía con respecto al uso del Servicio.</p>
            </li>
            <li>
              <p><strong>Servicio de redes sociales de terceros</strong> significa cualquier servicio o contenido (incluidos datos, información, productos o servicios) proporcionado por un tercero que puede mostrarse, incluirse o ponerse a disposición mediante el Servicio.</p>
            </li>
            <li>
              <p><strong>Website</strong> se refiere a dBloks, accesible desde <a href="https://marketplace.dbloks.com/" rel="external nofollow noopener" target="_blank">https://marketplace.dbloks.com/</a></p>
            </li>
            <li>
              <p><strong>Tú</strong> significa la persona que accede o utiliza el Servicio, o la empresa u otra entidad legal en nombre de la cual dicha persona accede o utiliza el Servicio, según corresponda.</p><br></br>
            </li>
          </ul>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Reconocimiento</h1><br></br>
          <p>Estos son los Términos y Condiciones que rigen el uso de este Servicio y el acuerdo que opera entre Usted y la Compañía. Estos Términos y Condiciones establecen los derechos y obligaciones de todos los usuarios con respecto al uso del Servicio.</p>
          <p>Su acceso y uso del Servicio está condicionado a Su aceptación y cumplimiento de estos Términos y condiciones. Estos Términos y condiciones se aplican a todos los visitantes, usuarios y otras personas que acceden o utilizan el Servicio.</p>
          <p>Al acceder o utilizar el Servicio, usted acepta estar sujeto a estos Términos y condiciones. Si no está de acuerdo con alguna parte de estos Términos y condiciones, no podrá acceder al Servicio.</p>
          <p>Usted declara que es mayor de 18 años. La Compañía no permite que los menores de 18 años usen el Servicio.</p>
          <p>Su acceso y uso del Servicio también está condicionado a Su aceptación y cumplimiento de la Política de Privacidad de la Compañía. Nuestra Política de privacidad describe Nuestras políticas y procedimientos sobre la recopilación, el uso y la divulgación de Su información personal cuando utiliza la Aplicación o el Sitio web y le informa sobre Sus derechos de privacidad y cómo la ley lo protege. Lea atentamente Nuestra Política de Privacidad antes de utilizar Nuestro Servicio.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Realización de pedidos de mercancías</h1><br></br>
          <p>Al realizar un Pedido de Bienes a través del Servicio, usted garantiza que es legalmente capaz de celebrar contratos vinculantes.</p><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Tu información</h2><br></br>
          <p>Si desea realizar un Pedido de Bienes disponibles en el Servicio, se le puede solicitar que proporcione cierta información relevante para Su Pedido, que incluye, entre otros, Su nombre, Su correo electrónico, Su número de teléfono, Su número de tarjeta de crédito, la fecha de vencimiento de Su tarjeta de crédito, Su dirección de facturación y Su información de envío.</p>
          <p>Usted declara y garantiza que: (i) tiene el derecho legal de usar cualquier tarjeta de crédito o débito u otro método de pago en relación con cualquier Pedido; y que (ii) la información que nos proporciona es verdadera, correcta y completa.</p>
          <p>Al enviar dicha información, nos otorga el derecho de proporcionar la información a terceros que procesan pagos con el fin de facilitar la finalización de su pedido.</p><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Cancelación de orden</h2><br></br>
          <p>Nos reservamos el derecho de rechazar o cancelar Su Pedido en cualquier momento por ciertos motivos, incluidos, entre otros, los siguientes:</p>
          <ul>
            <li>Disponibilidad de mercancías</li>
            <li>Errores en la descripción o precios de los Bienes</li>
            <li>Errores en su pedido</li>
          </ul>
          <p>Nos reservamos el derecho de rechazar o cancelar Su Pedido si se sospecha de fraude o de una transacción no autorizada o ilegal.</p>
          <h3>Sus derechos de cancelación de pedidos</h3>
          <p>Cualquier Producto que compre solo puede devolverse de acuerdo con estos Términos y Condiciones y Nuestra Política de Devoluciones.</p>
          <p>Nuestra Política de Devoluciones forma parte de estos Términos y Condiciones. Lea nuestra Política de devoluciones para obtener más información sobre su derecho a cancelar su pedido.</p>
          <p>Su derecho a cancelar un Pedido solo se aplica a los Bienes que se devuelven en las mismas condiciones en que los recibió. También debe incluir todas las instrucciones, documentos y envoltorios de los productos. No se reembolsarán los productos que estén dañados o que no estén en las mismas condiciones en que los recibió o que estén desgastados simplemente más allá de abrir el embalaje original. Por lo tanto, debe tener un cuidado razonable de los Bienes comprados mientras estén en Su posesión.</p>
          <p>Le reembolsaremos a más tardar 14 días a partir del día en que recibamos los Bienes devueltos. Usaremos el mismo medio de pago que usó para el Pedido, y no incurrirá en ninguna tarifa por dicho reembolso.</p>
          <p>Usted no tendrá ningún derecho a cancelar un Pedido para el suministro de cualquiera de los siguientes Bienes:</p>
          <ul>
            <li>El suministro de Bienes hechos según Sus especificaciones o claramente personalizados.</li>
            <li>El suministro de Bienes que por su naturaleza no sean aptos para ser devueltos, se deterioren rápidamente o hayan superado la fecha de caducidad.</li>
            <li>El suministro de Bienes que no sean aptos para la devolución por razones de protección de la salud o higiene y hayan sido desprecintados después de la entrega.</li>
            <li>El suministro de Bienes que, después de la entrega, según su naturaleza, se mezclen inseparablemente con otros artículos.</li>
            <li>El suministro de contenido digital que no se suministre en un medio tangible si la ejecución ha comenzado con Su previo consentimiento expreso y Usted ha reconocido Su pérdida del derecho de cancelación.</li>
          </ul><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Disponibilidad, errores e imprecisiones</h2><br></br>
          <p>Estamos constantemente actualizando Nuestras ofertas de Bienes en el Servicio. Los Bienes disponibles en Nuestro Servicio pueden tener un precio incorrecto, una descripción incorrecta o no estar disponibles, y podemos experimentar demoras en la actualización de la información sobre nuestros Bienes en el Servicio y en Nuestra publicidad en otros sitios web.</p>
          <p>No podemos y no garantizamos la exactitud o integridad de cualquier información, incluidos precios, imágenes de productos, especificaciones, disponibilidad y servicios. Nos reservamos el derecho de cambiar o actualizar la información y corregir errores, inexactitudes u omisiones en cualquier momento sin previo aviso.</p><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Política de Precios</h2><br></br>
          <p>La Compañía se reserva el derecho de revisar sus precios en cualquier momento antes de aceptar una Orden.</p>
          <p>Los precios cotizados pueden ser revisados por la Compañía después de aceptar un Pedido en caso de que ocurra algo que afecte la entrega debido a una acción gubernamental, variación en los aranceles aduaneros, aumento de los costos de envío, costos de cambio de divisas más altos y cualquier otro asunto fuera del control de la Compañía. . En ese caso, tendrá derecho a cancelar Su Pedido.</p><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Pagos</h2><br></br>
          <p>Todos los Bienes comprados están sujetos a un pago único. El pago puede realizarse a través de varios métodos de pago que tenemos disponibles, como Visa, MasterCard, Affinity Card, tarjetas American Express o métodos de pago en línea (PayPal, por ejemplo).</p>
          <p>Las tarjetas de pago (tarjetas de crédito o tarjetas de débito) están sujetas a controles de validación y autorización por parte del emisor de su tarjeta. Si no recibimos la autorización requerida, no seremos responsables de ningún retraso o falta de entrega de Su Pedido.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">User AccounCuentas de usuariots</h1><br></br>
          <p>Cuando crea una cuenta con nosotros, debe proporcionarnos información que sea precisa, completa y actual en todo momento. El no hacerlo constituye un incumplimiento de los Términos, lo que puede resultar en la terminación inmediata de Su cuenta en Nuestro Servicio.</p>
          <p>Usted es responsable de salvaguardar la contraseña que usa para acceder al Servicio y de cualquier actividad o acción bajo Su contraseña, ya sea que Su contraseña sea con Nuestro Servicio o con un Servicio de redes sociales de terceros.</p>
          <p>Usted acepta no revelar Su contraseña a ningún tercero. Debe notificarnos inmediatamente al darse cuenta de cualquier violación de seguridad o uso no autorizado de su cuenta.</p>
          <p>No puede usar como nombre de usuario el nombre de otra persona o entidad o que no esté legalmente disponible para su uso, un nombre o marca registrada que esté sujeto a cualquier derecho de otra persona o entidad que no sea Usted sin la autorización correspondiente, o un nombre que esté de otro modo ofensivo, vulgar u obsceno.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Contenido</h1><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Su derecho a publicar contenido</h2><br></br>
          <p>Nuestro Servicio le permite publicar Contenido. Usted es responsable del Contenido que publica en el Servicio, incluida su legalidad, confiabilidad y adecuación.</p>
          <p>Al publicar Contenido en el Servicio, nos otorga el derecho y la licencia para usar, modificar, realizar públicamente, mostrar públicamente, reproducir y distribuir dicho Contenido en el Servicio y a través de este. Usted conserva todos y cada uno de Sus derechos sobre cualquier Contenido que envíe, publique o muestre en o a través del Servicio y es responsable de proteger esos derechos. Usted acepta que esta licencia incluye el derecho de que Nosotros pongamos Su Contenido a disposición de otros usuarios del Servicio, quienes también pueden usar Su Contenido sujeto a estos Términos.</p>
          <p>Usted declara y garantiza que: (i) el Contenido es suyo (lo posee) o tiene derecho a usarlo y otorgarnos los derechos y la licencia según lo dispuesto en estos Términos, y (ii) la publicación de Su contenido en o a través del Servicio no viola los derechos de privacidad, derechos de publicidad, derechos de autor, derechos de contrato o cualquier otro derecho de cualquier persona.</p><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Restricciones de contenido</h2><br></br>
          <p>La Compañía no es responsable por el contenido de los usuarios del Servicio. Usted comprende y acepta expresamente que es el único responsable del Contenido y de toda la actividad que ocurra en su cuenta, ya sea que la realice usted o cualquier tercero que use su cuenta.</p>
          <p>No puede transmitir ningún Contenido que sea ilegal, ofensivo, perturbador, con la intención de disgustar, amenazante, calumnioso, difamatorio, obsceno o de otra manera objetable. Los ejemplos de dicho Contenido objetable incluyen, entre otros, los siguientes:</p>
          <ul>
            <li>Ilícitas o que promuevan actividades ilícitas.</li>
            <li>Contenido difamatorio, discriminatorio o mezquino, incluidas referencias o comentarios sobre religión, raza, orientación sexual, género, origen nacional/étnico u otros grupos objetivo.</li>
            <li>Spam, generado por máquina o al azar, que constituya publicidad no autorizada o no solicitada, cartas en cadena, cualquier otra forma de solicitud no autorizada o cualquier forma de lotería o juego.</li>
            <li>Contener o instalar virus, gusanos, malware, caballos de Troya u otro contenido diseñado o destinado a interrumpir, dañar o limitar el funcionamiento de cualquier software, hardware o equipo de telecomunicaciones o para dañar u obtener acceso no autorizado a cualquier dato u otro información de una tercera persona.</li>
            <li>Infringir cualquier derecho de propiedad de cualquier parte, incluidas patentes, marcas comerciales, secretos comerciales, derechos de autor, derechos de publicidad u otros derechos.</li>
            <li>Hacerse pasar por cualquier persona o entidad, incluida la Compañía y sus empleados o representantes.</li>
            <li>Violar la privacidad de cualquier tercera persona.</li>
            <li>Información y características falsas.</li>
          </ul>
          <p>La Compañía se reserva el derecho, pero no la obligación, de, a su exclusivo criterio, determinar si algún Contenido es apropiado o no y cumple con estos Términos, rechazar o eliminar este Contenido. La Compañía además se reserva el derecho de formatear y editar y cambiar la forma de cualquier Contenido. La Compañía también puede limitar o revocar el uso del Servicio si publica dicho Contenido objetable.
             Como la Compañía no puede controlar todo el contenido publicado por los usuarios y/o terceros en el Servicio, usted acepta usar el Servicio bajo su propio riesgo. Usted comprende que al usar el Servicio puede estar expuesto a contenido que puede encontrar ofensivo, indecente, incorrecto u objetable, y acepta que bajo ninguna circunstancia la Compañía será responsable de ningún modo por ningún contenido, incluidos los errores u omisiones en cualquier contenido, o cualquier pérdida o daño de cualquier tipo incurrido como resultado de su uso de cualquier contenido.</p><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Copias de seguridad de contenido</h2><br></br>
          <p>Aunque se realizan copias de seguridad regulares del Contenido, la Compañía no garantiza que no habrá pérdida o corrupción de datos.</p>
          <p>Los puntos de copia de seguridad corruptos o inválidos pueden ser causados, entre otros, por Contenido que está dañado antes de que se realice la copia de seguridad o que cambia durante el tiempo que se realiza la copia de seguridad.</p>
          <p>La Compañía brindará soporte e intentará solucionar cualquier problema conocido o descubierto que pueda afectar las copias de seguridad del Contenido. Pero Usted reconoce que la Compañía no tiene ninguna responsabilidad relacionada con la integridad del Contenido o la falla en restaurar el Contenido a un estado utilizable.</p>
          <p>Usted acepta mantener una copia completa y precisa de cualquier Contenido en una ubicación independiente del Servicio.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Política de derechos de autor</h1><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Infracción de propiedad intelectual</h2><br></br>
          <p>Respetamos los derechos de propiedad intelectual de otros. Nuestra política es responder a cualquier reclamo de que el Contenido publicado en el Servicio infringe los derechos de autor u otra infracción de propiedad intelectual de cualquier persona.</p>
          <p>Si es propietario de derechos de autor, o está autorizado en nombre de uno, y cree que el trabajo protegido por derechos de autor se ha copiado de una manera que constituye una infracción de derechos de autor que se está produciendo a través del Servicio, debe enviar Su notificación por escrito a la atención de nuestro agente de derechos de autor por correo electrónico a nft@dbloks.com e incluya en Su notificación una descripción detallada de la supuesta infracción.</p>
          <p>Usted puede ser responsable por daños y perjuicios (incluidos los costos y honorarios de abogados) por tergiversar que cualquier Contenido está infringiendo Sus derechos de autor.</p><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Aviso de DMCA y procedimiento de DMCA para reclamos por infracción de derechos de autor</h2><br></br>
          <p>Puede enviar una notificación de conformidad con la Ley de derechos de autor del milenio digital (DMCA) proporcionando a nuestro Agente de derechos de autor la siguiente información por escrito (consulte 17 U.S.C 512(c)(3) para obtener más detalles):</p>
          <ul>
            <li>Una firma electrónica o física de la persona autorizada para actuar en nombre del propietario del derecho de autor.</li>
            <li>Una descripción del trabajo protegido por derechos de autor que Usted afirma que se ha infringido, incluida la URL (es decir, la dirección de la página web) de la ubicación donde existe el trabajo protegido por derechos de autor o una copia del trabajo protegido por derechos de autor.</li>
            <li>Identificación de la URL u otra ubicación específica en el Servicio donde se encuentra el material que Usted afirma que está infringiendo.</li>
            <li>Su dirección, número de teléfono y dirección de correo electrónico.</li>
            <li>Una declaración suya de que cree de buena fe que el uso en disputa no está autorizado por el propietario de los derechos de autor, su agente o la ley.</li>
            <li>Una declaración suya, hecha bajo pena de perjurio, de que la información anterior en su aviso es precisa y que usted es el propietario de los derechos de autor o está autorizado para actuar en nombre del propietario de los derechos de autor.</li>
          </ul>
          <p>Puede comunicarse con nuestro agente de derechos de autor por correo electrónico a nft@dbloks.com.
          Al recibir una notificación, la Compañía tomará cualquier acción, a su exclusivo criterio, que considere apropiada, incluida la eliminación del contenido cuestionado del Servicio.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Propiedad intelectual</h1><br></br>
          <p>El Servicio y su contenido original (excluyendo el Contenido proporcionado por Usted u otros usuarios), las características y la funcionalidad son y seguirán siendo propiedad exclusiva de la Compañía y sus licenciantes.</p>
          <p>El Servicio está protegido por derechos de autor, marcas registradas y otras leyes tanto del País como de países extranjeros.</p>
          <p>Nuestras marcas comerciales e imagen comercial no se pueden utilizar en relación con ningún producto o servicio sin el consentimiento previo por escrito de la Compañía.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Sus comentarios para nosotros</h1><br></br>
          <p>Usted asigna todos los derechos, títulos e intereses sobre cualquier comentario que proporcione a la empresa. Si por alguna razón dicha cesión no es efectiva, usted acepta otorgar a la Compañía un derecho y una licencia no exclusivos, perpetuos, irrevocables, libres de regalías y en todo el mundo para usar, reproducir, divulgar, sublicenciar, distribuir, modificar y explotar dichos Comentarios sin restricción.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Enlaces a otros sitios web</h1><br></br>
          <p>Nuestro Servicio puede contener enlaces a sitios web o servicios de terceros que no son propiedad ni están controlados por la Compañía.</p>
          <p>La Compañía no tiene control ni asume ninguna responsabilidad por el contenido, las políticas de privacidad o las prácticas de los sitios web o servicios de terceros. Además, reconoce y acepta que la Compañía no será responsable, directa o indirectamente, de ningún daño o pérdida causados o presuntamente causados por o en relación con el uso o la confianza en dicho contenido, bienes o servicios disponibles en o a través de dichos sitios web o servicios.</p>
          <p>Le recomendamos encarecidamente que lea los términos y condiciones y las políticas de privacidad de cualquier sitio web o servicio de terceros que visite.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Terminación</h1><br></br>
          <p>Podemos rescindir o suspender su cuenta de inmediato, sin previo aviso ni responsabilidad, por cualquier motivo, incluido, entre otros, si infringe estos Términos y condiciones.</p>
          <p>Tras la rescisión, su derecho a utilizar el Servicio cesará inmediatamente. Si desea cancelar Su Cuenta, simplemente puede dejar de utilizar el Servicio.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Limitación de responsabilidad</h1><br></br>
          <p>Sin perjuicio de los daños en los que pueda incurrir, la responsabilidad total de la Compañía y cualquiera de sus proveedores en virtud de cualquier disposición de estos Términos y Su recurso exclusivo para todo lo anterior se limitará al monto que realmente pagó a través del Servicio o 100 USD. si no ha comprado nada a través del Servicio.</p>
          <p>En la medida máxima permitida por la ley aplicable, en ningún caso la Compañía o sus proveedores serán responsables de daños especiales, incidentales, indirectos o consecuentes de ningún tipo (incluidos, entre otros, daños por lucro cesante, pérdida de datos o otra información, por interrupción del negocio, por lesiones personales, pérdida de privacidad que surja o esté relacionada de alguna manera con el uso o la incapacidad de usar el Servicio, software de terceros y/o hardware de terceros utilizado con el Servicio, o de lo contrario en relación con cualquier disposición de estos Términos), incluso si la Compañía o cualquier proveedor ha sido advertido de la posibilidad de tales daños e incluso si el remedio no cumple con su propósito esencial.</p>
          <p>Algunos estados no permiten la exclusión de garantías implícitas o la limitación de responsabilidad por daños incidentales o consecuentes, lo que significa que algunas de las limitaciones anteriores pueden no aplicarse. En estos estados, la responsabilidad de cada parte se limitará en la mayor medida permitida por la ley.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">&quot;TAL CUAL&quot; y &quot;SEGÚN DISPONIBILIDAD&quot; Descargo de responsabilidad</h1><br></br>
          <p>El Servicio se le proporciona &quot;TAL CUAL&quot; y &quot;SEGÚN DISPONIBILIDAD&quot; y con todas las faltas y defectos sin garantía de ningún tipo. En la medida máxima permitida por la ley aplicable, la Compañía, en su propio nombre y en nombre de sus Afiliados y sus respectivos otorgantes de licencias y proveedores de servicios, renuncia expresamente a todas las garantías, ya sean expresas, implícitas, estatutarias o de otro tipo, con respecto a la Servicio, incluidas todas las garantías implícitas de comerciabilidad, idoneidad para un propósito particular, título y no infracción, y garantías que puedan surgir del curso de la negociación, el curso del desempeño, el uso o la práctica comercial. Sin limitación a lo anterior, la Compañía no ofrece ninguna garantía o compromiso, y no hace ninguna representación de que el Servicio cumplirá con Sus requisitos, logrará los resultados previstos, será compatible o funcionará con cualquier otro software, aplicación, sistema o servicio, operará sin interrupción, cumplir con los estándares de rendimiento o confiabilidad o estar libre de errores o que cualquier error o defecto puede o será corregido.</p>
          <p>Sin limitar lo anterior, ni la Compañía ni ninguno de los proveedores de la compañía hace ninguna representación o garantía de ningún tipo, expresa o implícita: (i) en cuanto a la operación o disponibilidad del Servicio, o la información, contenido y materiales o productos incluido en el mismo; (ii) que el Servicio será ininterrumpido o libre de errores; (iii) en cuanto a la precisión, confiabilidad o actualidad de cualquier información o contenido proporcionado a través del Servicio; o (iv) que el Servicio, sus servidores, el contenido o los correos electrónicos enviados desde o en nombre de la Compañía están libres de virus, secuencias de comandos, troyanos, gusanos, malware, bombas de tiempo u otros componentes dañinos.</p>
          <p>Some jurisdictions do not allow the exclusion of certain types of warranties or limitations on applicable statutory rights of a consumer, so some or all of the above exclusions and limitations may not apply to You. But in such a case the exclusions and limitations set forth in this section shall be applied to the greatest extent enforceable under applicable law.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Ley que rige</h1><br></br>
          <p>Las leyes del País, excluyendo sus conflictos de leyes, regirán estos Términos y Su uso del Servicio. Su uso de la Aplicación también puede estar sujeto a otras leyes locales, estatales, nacionales o internacionales.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Disputes Resolution</h1><br></br>
          <p>Si tiene alguna inquietud o disputa sobre el Servicio, acepta intentar primero resolver la disputa de manera informal comunicándose con la Compañía.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Para usuarios de la Unión Europea (UE)</h1><br></br>
          <p>Si es un consumidor de la Unión Europea, se beneficiará de las disposiciones obligatorias de la ley del país en el que reside.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Cumplimiento legal de los Estados Unidos</h1><br></br>
          <p>Usted declara y garantiza que (i) no se encuentra en un país que esté sujeto al embargo del gobierno de los Estados Unidos, o que haya sido designado por el gobierno de los Estados Unidos como "apoyador del terrorismo"; país, y (ii) no figura en ninguna lista del gobierno de los Estados Unidos de partes prohibidas o restringidas.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Divisibilidad y renuncia</h1><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Divisibilidad</h2><br></br>
          <p>Si alguna disposición de estos Términos se considera inaplicable o inválida, dicha disposición se cambiará e interpretará para lograr los objetivos de dicha disposición en la mayor medida posible según la ley aplicable y las disposiciones restantes continuarán en pleno vigor y efecto.</p><br></br>
          <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Exención</h2><br></br>
          <p>Salvo lo dispuesto en el presente, la falta de ejercicio de un derecho o de exigir el cumplimiento de una obligación en virtud de estos Términos no afectará la capacidad de una parte para ejercer dicho derecho o exigir dicho cumplimiento en cualquier momento posterior ni la renuncia a un incumplimiento constituirá una renuncia a cualquier incumplimiento posterior.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Traducción Interpretación</h1><br></br>
          <p>Estos Términos y condiciones pueden haber sido traducidos si los hemos puesto a su disposición en nuestro Servicio.
          Usted acepta que el texto original en inglés prevalecerá en caso de disputa.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Cambios a estos Términos y Condiciones</h1><br></br>
          <p>Nos reservamos el derecho, a Nuestro exclusivo criterio, de modificar o reemplazar estos Términos en cualquier momento. Si una revisión es importante, haremos todos los esfuerzos razonables para proporcionar un aviso de al menos 30 días antes de que entren en vigencia los nuevos términos. Lo que constituye un cambio material se determinará a Nuestro exclusivo criterio.</p>
          <p>Al continuar accediendo o utilizando Nuestro Servicio después de que esas revisiones entren en vigencia, Usted acepta estar sujeto a los términos revisados. Si no está de acuerdo con los nuevos términos, en su totalidad o en parte, deje de usar el sitio web y el Servicio.</p><br></br>
          <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">Contacta con nosotros</h1><br></br>
          <p>Si tiene alguna pregunta sobre estos Términos y Condiciones, puede contactarnos:</p>
          <ul>
            <li>Por correo electrónico: nft@dbloks.com</li>
          </ul>
      </section>
    </div>
  );
};

export default PageTermsOfUse;
