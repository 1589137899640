import { FC } from "react";
import { useTranslation } from "react-i18next";
import { FormattedNumber, IntlProvider } from "react-intl";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import Prices from "./Prices";
import RemainingTimeNftCard from "./RemainingTimeNftCard";

export interface CardNFT2Props {
  className?: string;
  isLiked?: boolean;
  event: {
    id: number;
    attributes: {
      name: string;
      price: number;
      currency: string;
      date: string;
      quantity: number;
      eventDateTime: string;
      mainImage: {
        data: {
          attributes: {
            url: string;
          }
        }
      };
    };
  };
}

const CardNFT2: FC<CardNFT2Props> = ({ className = "", isLiked, event }) => {

  const attributes = event.attributes
  const price = attributes.price ? attributes.price : 0;
  const image = attributes.mainImage.data ? attributes.mainImage.data.attributes.url : ''
  const currency = attributes.currency ? attributes.currency : ''
  const formatPrice = parseFloat((price / 100).toFixed(2));
  const priceEUR = <IntlProvider locale={"es"}><FormattedNumber value={formatPrice} style="currency" currency={currency} /></IntlProvider>;
  const priceUSD = <IntlProvider locale={"us"}><FormattedNumber value={formatPrice} style="currency" currency={currency} /></IntlProvider>;
  const { t, i18n } = useTranslation();
  const renderAvatars = () => {
    
    return (
      <div className="hidden md:flex -space-x-1.5 ">
        <Avatar
          containerClassName="ring-2 ring-white "
          sizeClass="h-5 w-5 text-sm"
        />
        <Avatar
          containerClassName="ring-2 ring-white "
          sizeClass="h-5 w-5 text-sm"
        />
        <Avatar
          containerClassName="ring-2 ring-white "
          sizeClass="h-5 w-5 text-sm"
        />
      </div>
    );
  };

  return (
    <div
      className={`nc-CardNFT2 relative bg-white dark:bg-neutral-900 rounded-3xl flex flex-col group p-2.5  ${className}`}
      data-nc-id="CardNFT2"
    >
      <div className="relative flex-shrink-0 ">
        <div>
          <NcImage
            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0 rounded-3xl overflow-hidden z-0"
            src={image}
            className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
          />
        </div>

        {/* ----TIME--- */}
        <RemainingTimeNftCard time={attributes.eventDateTime} contentClassName="right-5 top-1/2 -translate-y-1/2 pb-1" />

        <div className="absolute left-[-1px] bottom-[-0.4px] ">
          <svg
            className="text-white dark:text-neutral-900 w-64 md:w-[281px]"
            width="281"
            viewBox="0 0 258.1 111"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M 0 0 V 111 H 258.059 C 245 107 240 93 236.089 84.6606 L 205.167 14.3394 C 201.335 5.6257 192.716 0 183.197 0 H 0 Z"
              fill="currentColor"
            />
          </svg>

          <div className="absolute left-4 bottom-0 w-48 ">
            <h2 className={`text-lg font-semibold `}>
              {attributes.name}
            </h2>

            <div className="w-full mt-1.5 flex justify-between items-end ">
              {/* <Prices2 /> */}
              {currency === "eur" ?
              <Prices price={"" + formatPrice + "€"} labelText={t("nft-item.price")} labelTextClassName="bg-white dark:bg-neutral-900" />:
              <Prices price={"$" + formatPrice} labelText={t("nft-item.price")} labelTextClassName="bg-white dark:bg-neutral-900" />}
              <span className="block text-neutral-500 dark:text-neutral-400 text-xs">
                {attributes.quantity} {t("nft-item.available")}
              </span>
            </div>
          </div>
        </div>
      </div>

      <Link to={"/event/" + event.id} className="absolute inset-0"></Link>
    </div>
  );
};

export default CardNFT2;
