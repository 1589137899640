import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
//For api calls
import axios from "axios";

//Imports for wallet connect
import qs from "qs";

import { useAppSelector } from "app/hooks";
import * as Sentry from '@sentry/react';

import { useUser } from "context/UserContext";
import { useMagic } from "context/MagicProvider";
//import { magic } from "libs/magic";


//For rainbow wallet
let allRedeemed = true;

const delay = (ms: number | undefined) => new Promise(res => setTimeout(res, ms));

export interface PageSubcriptionProps {
    className?: string;
}

const MyCollectibles: FC<PageSubcriptionProps> = ({ className = "" }) => {
    const [headerText, setHeaderText] = useState("");
    const [availableStyle, setAvailableStyle] = useState("block");
    const [loadingStyle, setloadingStyle] = useState("none");
    const [authenticateStyle, setAuthenticateStyle] = useState("block");
    const [retrieveResponse, setRetrieveResponse] = useState(0);
    const [retrieveFound, setRetrieveFound] = useState([]);
    const [retrieveMssg, setRetrieveMssg] = useState("");
    const [connectedWalletAddress, setconnectedWalletAddress] = useState("");
    const buttonRef = useRef(null);
    const { t, i18n } = useTranslation();
    const { user } = useUser();
    const { magic } = useMagic()



    //***************************************** */

    useEffect(() => {
        displayCollectibles();
    },[user])

    async function displayCollectibles(){
        try{
            //const isLoggedIn = await magic.user.isLoggedIn();
            if (user) {
                //const userInfo = await magic?.user.getInfo();
    
                const body = qs.stringify({
                    user: user?.address,
                    chain:process.env.REACT_APP_OPEN_LOGIN_NETWORK,
                    pkey: process.env.REACT_APP_PKEY
                });
    
                setAuthenticateStyle("hidden");
                setloadingStyle("flex");
                // Send Email
                const response = await axios.post('https://lamp.dbloks.com/event_creation/retrieve_nfts.php', body, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                });
    
                setRetrieveResponse(response?.data?.su);
    
                if (response?.data?.su == 1) {
                    let nftArray = response?.data?.found;
                    for (let i = 0; i < nftArray.length; i++) {
                        if (nftArray[i][2]?.toLowerCase().includes("ipfs://")) {
                            nftArray[i][2] = nftArray[i][2].replace("ipfs://", "https://ipfs.io/ipfs/");
                        }
                        if (!nftArray[i][3]) {
                            allRedeemed = false;
                        }
                    }
                    setRetrieveFound(nftArray);
                    setloadingStyle("none");
    
                } else if (response?.data?.su == 2) {
                    if (response?.data?.found != null) {
                        let nftArray = response?.data?.found;
                        for (let i = 0; i < nftArray.length; i++) {
                            if (nftArray[i][2]?.toLowerCase().includes("ipfs://")) {
                                nftArray[i][2] = nftArray[i][2].replace("ipfs://", "https://ipfs.io/ipfs/");
                            }
                        }
                        setRetrieveFound(nftArray);
                        setRetrieveMssg(response?.data?.mssg);
                    }
                    setloadingStyle("none");
                    setAuthenticateStyle("hidden");
                } else {
                    setRetrieveMssg(response?.data?.mssg);
                    setloadingStyle("none");
                    setAuthenticateStyle("hidden");
                }
            }else{
                setRetrieveResponse(0);
                setAuthenticateStyle("block");
            }
        }catch(error: any){
            Sentry.captureException(error);
            window.location.reload();
        }
    }

    async function authenticate() {
        try{
            //const isLoggedIn = await magic.user.isLoggedIn();
            if (user) {
                //const userInfo = await magic?.user.getInfo();
    
                const body = qs.stringify({
                    user: user?.address,
                    chain:process.env.REACT_APP_OPEN_LOGIN_NETWORK,
                    pkey: process.env.REACT_APP_PKEY
                });
    
                setAuthenticateStyle("hidden");
                setloadingStyle("flex");
                // Send Email
                const response = await axios.post('https://lamp.dbloks.com/event_creation/retrieve_nfts.php', body, {
                    headers: {
                        "Content-Type": "application/x-www-form-urlencoded"
                    }
                });
    
                setRetrieveResponse(response?.data?.su);
    
                if (response?.data?.su == 1) {
                    let nftArray = response?.data?.found;
                    for (let i = 0; i < nftArray.length; i++) {
                        if (nftArray[i][2]?.toLowerCase().includes("ipfs://")) {
                            nftArray[i][2] = nftArray[i][2].replace("ipfs://", "https://ipfs.io/ipfs/");
                        }
                        if (!nftArray[i][3]) {
                            allRedeemed = false;
                        }
                    }
                    setRetrieveFound(nftArray);
                    setloadingStyle("none");
    
                } else if (response?.data?.su == 2) {
                    if (response?.data?.found != null) {
                        let nftArray = response?.data?.found;
                        for (let i = 0; i < nftArray.length; i++) {
                            if (nftArray[i][2]?.toLowerCase().includes("ipfs://")) {
                                nftArray[i][2] = nftArray[i][2].replace("ipfs://", "https://ipfs.io/ipfs/");
                            }
                        }
                        setRetrieveFound(nftArray);
                        setRetrieveMssg(response?.data?.mssg);
                    }
                    setloadingStyle("none");
                    setAuthenticateStyle("hidden");
                } else {
                    setRetrieveMssg(response?.data?.mssg);
                    setloadingStyle("none");
                    setAuthenticateStyle("hidden");
                }
            } else {
                if(localStorage.getItem('magic')=="true"){
                    localStorage.setItem('magic', 'false');
                    window.location.reload();
                    return;
                  }else{
                    let wcbutton: HTMLElement = (document.getElementById("walletConnecDiv")?.childNodes[0] as HTMLElement)?.children[0] as HTMLElement;
                    wcbutton.click();
                    return;
                  }
            }
        }catch(error: any){
            Sentry.captureException(error);
            window.location.reload();
        }
    }

    return (
        <div
            className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
            data-nc-id="MyCollectibles"
        >
            <header className="text-center max-w-2xl mx-auto mt-20 mb-0">
                <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    {t("user-profile.my-collectibles")}
                </h2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                </span>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200" style={{ fontSize: "12px" }}>
                    {t("user-profile.collectibles-noshow-yet")}
                </span>
            </header>
            <section className="text-neutral-600 text-sm md:text-base overflow-hidden" style={{ textAlign: "center" }}>

                <div className="loader" style={{ display: loadingStyle }}>
                    <div className="loading"></div>
                </div><br></br>

                {retrieveResponse == 0 ? (
                    <></>
                ) :
                    retrieveResponse == 1 ? (
                        <div className="ulNftResults">
                            <h2 style={{ color: "white" }}>{headerText}</h2>
                            <ul style={{ marginTop: "10px" }}>
                                {
                                    retrieveFound.map((nft, index) => {
                                        index++;
                                        if (nft[2] != null) {
                                            if (nft[3]) {
                                                return <li key={"li" + index}>
                                                    <input type="checkbox" id={"myCheckbox" + index} name="nftCheckbox" value={nft[0] + "-" + nft[1]} key={"input" + index} ></input>
                                                    <label htmlFor={"myCheckbox" + index} key={"label" + index}>
                                                        <img src={nft[2]} key={"img" + index}></img>
                                                        <div className="redeemtext2" key={"div" + index}>
                                                            <h4 style={{ marginBottom: "0px" }} key={"h4" + index}>REDEEMED</h4>
                                                        </div>
                                                    </label>
                                                    <p style={{ marginBottom: "0px", paddingLeft: "5px", paddingRight: "5px", fontSize: "13px", color: "white" }} key={"p" + index}>{nft[4]}</p>
                                                </li>
                                            } else {
                                                return <li style={{ display: availableStyle }} key={"li" + index}>
                                                    <input type="checkbox" id={"myCheckbox" + index} name="nftCheckbox" value={nft[0] + "-" + nft[1]} key={"input" + index}></input>
                                                    <label htmlFor={"myCheckbox" + index} key={"label" + index}>
                                                        {
                                                            nft[4]!="MP4"
                                                            ? <img src={nft[2]} key={"img" + index}></img>
                                                            : <video controls autoPlay muted loop playsInline key={"img" + index}>
                                                                <source src={nft[2]} type="video/mp4" />Sorry, your browser doesn't support embedded videos.
                                                              </video>
                                                        }
                                                    </label>
                                                    <p style={{ color: "white" }}>{t("user-profile.edition")} #{nft[1]}</p>
                                                </li>
                                            }
                                        }
                                    })
                                }
                            </ul>
                        </div>
                    ) : retrieveResponse == 2 ? (
                        <div className="ulNftResults">
                            <img className="singleImg" src={retrieveFound[0][2]}></img><br></br>
                            <p style={{ color: "white" }}>{retrieveMssg}</p>
                        </div>
                    ) : (
                        <div className="ulNftResults">
                            <img className="singleImg" src="https://dbloks.com/wp-content/themes/neve-child/images/failed.png"></img><br></br>
                            <p style={{ color: "white" }}>{retrieveMssg}</p>
                        </div>
                    )
                }
                <ButtonPrimary onClick={authenticate} className={`rounded bg-[#4d996a] ${authenticateStyle}`} type="button">{t("user-profile.see-journey")}</ButtonPrimary><br />
            </section>
        </div>
    );
};

export default MyCollectibles;
