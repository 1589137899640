import { FC, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
//For api calls

//Imports for wallet connect
import * as Sentry from '@sentry/react';
import { useUser } from "context/UserContext";
import { useMagic } from "context/MagicProvider";
import qs from 'qs';
import { Gallery, ThumbnailImageProps } from 'react-grid-gallery';
import { useParams } from 'react-router-dom';
import axiosInstance, { axiosWP } from 'utils/api';
import Lightbox from 'yet-another-react-lightbox';
import 'yet-another-react-lightbox/styles.css';

// Plugins

interface GalleryImgs {
  src: string;
  width: number;
  height: number;
}

export interface PageSubcriptionProps {
  className?: string;
}

const EventGallery: FC<PageSubcriptionProps> = ({ className = '' }) => {
  const [loadingStyle, setloadingStyle] = useState('none');
  const [authenticateStyle, setAuthenticateStyle] = useState('block');
  const [retrieveResponse, setRetrieveResponse] = useState(0);
  const [imgSrc, setimgSrc] = useState<GalleryImgs[]>([]);
  const [imgSrcLightbox, setimgSrcLightbox] = useState<GalleryImgs[]>([]);

  const [retrieveMssg, setRetrieveMssg] = useState('');
  const { t, i18n } = useTranslation();
  const { user } = useUser();
  const params = useParams();

  const [index, setIndex] = useState(-1);
  const handleClick = (index: number) => setIndex(index);

  const tileViewportStyle = {
    border: '2px solid #ccc', // Example border style
    borderRadius: '5px', // Example border radius
    boxShadow: '0 0 5px rgba(0, 0, 0, 0.3)', // Example box shadow
    width: 'calc(33.33% - 20px)', // Set the width for mobile devices (3 columns with some margin)
    maxWidth: '300px', // Set a maximum width for responsiveness
    margin: '10px', // Add some margin between tiles
  };

  //***************************************** */

  useEffect(() => {
    displayGallery();
  }, [user]);

  async function displayGallery() {
    try{
        if (user) {
            let photoCount = 0;
            var { data: res } = await axiosInstance.get(
                // @ts-ignore
                '/api/contracts/' + params.id + '?populate=*'
              );
            if (res.data) {
                const allEvents = res.data.attributes;
                if (allEvents.photos) {
                    photoCount = allEvents.photos.totalPhotos;
                }
            }

            const body = qs.stringify({
              user: user?.address,
              pkey: process.env.REACT_APP_PKEY,
              chain: process.env.REACT_APP_OPEN_LOGIN_NETWORK,
              // @ts-ignore
              id: params.id,
              photoQty: photoCount,
            });
      
            setAuthenticateStyle('hidden');
            setloadingStyle('flex');
      
            const response = await axiosWP.post(
              '/event_creation/retrieve_gallery.php',
              body,
              {
                headers: {
                  'Content-Type': 'application/x-www-form-urlencoded',
                },
              }
            );
      
            setRetrieveResponse(response?.status);
      
            if (response?.data?.su != 404) {
              let imgs: GalleryImgs[] = response?.data?.imageData;
              let imgsLightbox: GalleryImgs[] = response?.data?.imageDataLightbox;
              setimgSrc(imgs);
              setimgSrcLightbox(imgsLightbox);
              setloadingStyle('none');
            } else {
              setRetrieveMssg(response?.data?.mssg);
              setloadingStyle('none');
              setAuthenticateStyle('hidden');
            }
          } else {
            setRetrieveResponse(0);
            setAuthenticateStyle('block');
          }
    }catch(error: any){
        Sentry.captureException(error);
        window.location.reload();
    }
  }

  async function authenticate() {
    try{
        if (user) {
           await displayGallery();
        } else {
            if(localStorage.getItem('magic')=="true"){
                localStorage.setItem('magic', 'false');
                window.location.reload();
                return;
              }else{
                let wcbutton: HTMLElement = (document.getElementById("walletConnecDiv")?.childNodes[0] as HTMLElement)?.children[0] as HTMLElement;
                wcbutton.click();
                return;
              }
        }
    }catch(error: any){
        Sentry.captureException(error);
        window.location.reload();
    }
  }

  const ImageComponent = (props: ThumbnailImageProps) => {
    const { src, alt, style, title } = props.imageProps;
    return (
      <img
        alt={alt}
        src={src}
        title={title || ''}
        style={{
          cursor: 'pointer',
          maxWidth: '400px',
          minWidth: '200px',
          height: 'auto',
        }}
        className="max-w-96 h-auto"
      />
    );
  };

  return (
    <div
      className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
      data-nc-id="EventGallery"
    >
      <header className="text-center max-w-2xl mx-auto mt-5 mb-0">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('gallery-page.title')}
        </h2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200"></span>
        <span
          className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200"
          style={{ fontSize: '12px' }}
        >
          {t('gallery-page.notice')}
        </span>
      </header>
      <section
        className="text-neutral-600 text-sm md:text-base overflow-hidden"
        style={{ textAlign: 'center' }}
      >
        <div className="loader" style={{ display: loadingStyle }}>
          <div className="loading"></div>
        </div>
        <br></br>

        {retrieveResponse == 0 ? (
          <></>
        ) : retrieveResponse == 200 ? (
          <div>
            <Gallery
              images={imgSrc}
              enableImageSelection={false}
              onClick={handleClick}
              rowHeight={200}
              /*thumbnailImageComponent={ImageComponent}
              tileViewportStyle={{
                maxWidth: '400px',
                minWidth: '200px',
                height: 'auto',
              }}*/
            />
            <Lightbox
              index={index}
              slides={imgSrcLightbox}
              open={index >= 0}
              close={() => setIndex(-1)}
            />
          </div>
        ) : (
          <div className="ulNftResults">
            <img
              className="singleImg"
              src="https://dbloks.com/wp-content/themes/neve-child/images/failed.png"
            ></img>
            <br></br>
            <p style={{ color: 'white' }}>{retrieveMssg}</p>
          </div>
        )}
        <ButtonPrimary
          onClick={authenticate}
          className={`rounded bg-[#4d996a] ${authenticateStyle}`}
          type="button"
        >
          See Gallery
        </ButtonPrimary>
        <br />
      </section>
    </div>
  );
};

export default EventGallery;
