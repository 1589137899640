import { FC, useEffect, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';

//For api calls

import qs from 'qs';
import { useParams } from 'react-router-dom';
import { axiosWP } from 'utils/api';

import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';

import { useUser } from 'context/UserContext';
//import { magic } from 'libs/magic';
import { FormattedNumber, IntlProvider } from 'react-intl';
import * as Sentry from '@sentry/react';
import { useTranslation } from 'react-i18next';


export interface PageSubcriptionProps {
  className?: string;
}

const RegistrationDetails: FC<PageSubcriptionProps> = ({ className = '' }) => {
  const [totalTickets, setTotalTickets] = useState('');
  const [ticketsLeft, setTicketsLeft] = useState('');
  const [totalAuthenticated, setTotalAuthenticated] = useState('');
  const [totalSales, setTotalSales] = useState(0.0);
  const [authenticateStyle, setAuthenticateStyle] = useState('hidden');
  const [loginStyle, setLoginStyle] = useState('hidden');

  const [retrieveMssg, setRetrieveMssg] = useState('');
  const [authenticatedRows, setAuthenticatedRows] = useState([]);
  const [registeredRows, setRegisteredRows] = useState([]);
  const [redeemedStyle, setRedeemedStyle] = useState('none');
  const [registeredStyle, setRegisteredStyle] = useState('none');
  const [tabStyle, setTabStyle] = useState('none');
  const [loadingStyle, setLoadingStyle] = useState("hidden");
  const { t } = useTranslation();

  


  const { user } = useUser();


  const params = useParams();

  useEffect(() => {
    authenticate();
  }, [user]);

  function getTabs() {
    var tabs = document.querySelectorAll('.tabs_wrap2 ul li');
    tabs.forEach((tab) => {
      tab.addEventListener('click', () => {
        tabs.forEach((tab) => {
          tab.classList.remove('active');
        });
        tab.classList.add('active');
        var tabval = tab.getAttribute('data-tabs');

        if (tabval == 'registered') {
          setRegisteredStyle('block');
          setRedeemedStyle('none');
        } else {
          setRedeemedStyle('block');
          setRegisteredStyle('none');
        }
      });
    });
  }

  async function login() {
    if (!user?.address) {
      if(localStorage.getItem('magic')=="true"){
        localStorage.setItem('magic', 'false');
        window.location.reload();
        return;
      }else{
        let wcbutton: HTMLElement = (document.getElementById("walletConnecDiv")?.childNodes[0] as HTMLElement)?.children[0] as HTMLElement;
        wcbutton.click();
        return;
      }
    }
  }

  async function authenticate() {
    setRetrieveMssg('');
    try{
      //const isLoggedIn = await magic.user.isLoggedIn();
      if (user) {
        setLoginStyle('hidden');
        setAuthenticateStyle("hidden");
        setLoadingStyle("flex");
        //const userInfo = await magic.user.getInfo();

        const body = qs.stringify({
          user: user?.address ? user?.address : '',
          pkey: process.env.REACT_APP_PKEY,
          // @ts-ignore
          scankey: params.id,
        });

        const response = await axiosWP.post(
          '/event_creation/EventAttendance.php',
          body,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        setLoadingStyle("hidden");

        if (response?.data?.su == 1) {
          setAuthenticatedRows(response?.data?.dataRedeemed);
          setRegisteredRows(response?.data?.dataRegistered);
          setTotalTickets(response?.data?.totalTickets);
          setTicketsLeft(response?.data?.ticketsLeft);
          setTotalSales(response?.data?.totalSales);
          setTotalAuthenticated(response?.data?.totalAuthenticated);
          setAuthenticateStyle('block');
          setTabStyle('block');
          getTabs();
          setRegisteredStyle('block');
        } else {
          setRetrieveMssg(response?.data.mssg);
        }
      }else{
        setLoginStyle('block');
      }

    }catch(error: any){
      setLoadingStyle("hidden");
        Sentry.captureException(error);
        window.location.reload();
    }
  }

  async function refreshTable() {
    setRetrieveMssg('');

    try{
      //const isLoggedIn = await magic.user.isLoggedIn();
      if (user) {
        setLoadingStyle("flex");
        //const userInfo = await magic.user.getInfo();
        const body = qs.stringify({
          user: user?.address ? user?.address : '',
          pkey: process.env.REACT_APP_PKEY,
          // @ts-ignore
          scankey: params.id,
        });
  
        const response = await axiosWP.post(
          '/event_creation/EventAttendance.php',
          body,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
        setLoadingStyle("hidden");
  
        if (response?.data?.su == 1) {
          setAuthenticatedRows(response?.data?.dataRedeemed);
          setRegisteredRows(response?.data?.dataRegistered);
          setTotalTickets(response?.data?.totalTickets);
          setTicketsLeft(response?.data?.ticketsLeft);
          setTotalSales(response?.data?.totalSales);
          setTotalAuthenticated(response?.data?.totalAuthenticated);
        } else {
          setRetrieveMssg(response?.data.mssg);
        }
      }
    }catch(error: any){
      setLoadingStyle("hidden");
        Sentry.captureException(error);
        window.location.reload();
    }
  }

  const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
      backgroundColor: theme.palette.common.black,
      color: theme.palette.common.white,
    },
    [`&.${tableCellClasses.body}`]: {
      fontSize: 14,
    },
  }));

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));

  return (
    <div
      className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
      data-nc-id="RegistrationDetails"
    >
      <header className="text-center max-w-2xl mx-auto my-10">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          Event Dashboard
        </h2>
      </header>

      
      <div className={`items-center justify-center pb-2 ${loadingStyle}`}>
        <svg className="animate-spin text-green-500" width="60px" height="60px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path fillRule="evenodd" clipRule="evenodd" d="M13.917 7A6.002 6.002 0 0 0 2.083 7H1.071a7.002 7.002 0 0 1 13.858 0h-1.012z"/>
        </svg> 
      </div>

      <section className="text-neutral-600 text-sm md:text-base overflow-hidden" style={{ textAlign: "center" }}>
            <ButtonPrimary onClick={login} className={`rounded bg-[#4d996a] mb-2 mt-2 ${loginStyle}`} type="button">Login</ButtonPrimary><br />
      </section>
          
      <div className="wrapper2" style={{ display: tabStyle }} id="tabsWrap">
        <div className="tabs_wrap2">
          <ul style={{ margin: 'auto !important' }}>
            <li
              style={{
                borderTopLeftRadius: '12px',
                borderBottomLeftRadius: '12px',
              }}
              data-tabs="registered"
              className="active"
            >
              {t("registration-details.registered")}
            </li>
            <li
              style={{
                borderTopRightRadius: '12px',
                borderBottomRightRadius: '12px',
              }}
              data-tabs="redeemed"
            >
              {t("registration-details.authenticated")}
            </li>
          </ul>
        </div>
        <br></br>
      </div>

      <div className='flex flex-wrap mb-2'>
        <div className='flex-1' style={{paddingRight:"200px"}}>
          <ButtonPrimary
          onClick={refreshTable}
          className={`rounded-xl bg-[#1f90a6] ${authenticateStyle}`}
          type="button"
        >
          {t("registration-details.refresh")}
        </ButtonPrimary>
        </div>
      <div>
      <p
        className="text-3xl pt-5 pr-4"
        style={{ display: registeredStyle }}
      >
        Total:&nbsp;<b>{totalTickets}</b>
      </p>
      </div>
      {totalSales > 0 && (
        <div>
        <p
          className="text-3xl pt-5 pr-4"
          style={{ display: registeredStyle }}
        >
          {t("registration-details.sales")}:&nbsp;
          <b>
            <IntlProvider locale={'en'}>
              <FormattedNumber
                value={totalSales}
                style="currency"
                currency="usd"
                minimumFractionDigits={2}
                maximumFractionDigits={2}
              />
            </IntlProvider>
          </b>
        </p>
        </div>
      )}
      <div>
      <div>
        <p
        className="text-3xl pt-5 pr-4"
        style={{ display: registeredStyle }}
      >
        {t("registration-details.remain")}:&nbsp;<b>{ticketsLeft}</b>
      </p>
      </div>
      <p
        className="inline-flex float-right text-3xl pt-5 pr-4"
        style={{ display: redeemedStyle }}
      >
        Total:&nbsp;<b>{totalAuthenticated}</b>
      </p>{' '}
        </div>
      </div>
      <h2>{retrieveMssg}</h2>
      <TableContainer
        style={{ display: redeemedStyle, maxHeight: '550px' }}
        component={Paper}
      >
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("registration-details.name")}</StyledTableCell>
              <StyledTableCell align="right">{t("registration-details.mail")}</StyledTableCell>
              <StyledTableCell align="right">{t("registration-details.redeem-date")}</StyledTableCell>
              <StyledTableCell align="right">{t("registration-details.ticket-id")}</StyledTableCell>
              <StyledTableCell align="right">{t("registration-details.redeem-quantity")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {authenticatedRows.map((row) => (
              <StyledTableRow key={row['name'] + 1}>
                <StyledTableCell component="th" scope="row">
                  {row['name']}
                </StyledTableCell>
                <StyledTableCell align="right">{row['email']}</StyledTableCell>
                <StyledTableCell align="right">
                  {row['date_redeemed']}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row['token_id']}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row['redeemed_qty']}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TableContainer
        style={{ display: registeredStyle, maxHeight: '550px' }}
        component={Paper}
      >
        <Table
          sx={{ minWidth: 700 }}
          aria-label="customized table"
          stickyHeader
        >
          <TableHead>
            <TableRow>
              <StyledTableCell>{t("registration-details.name")}</StyledTableCell>
              <StyledTableCell align="right">{t("registration-details.mail")}</StyledTableCell>
              <StyledTableCell align="right">{t("registration-details.ticket-quantity")}</StyledTableCell>
              <StyledTableCell align="right">{t("registration-details.ticket-type")}</StyledTableCell>
              <StyledTableCell align="right">{t("registration-details.date-registration")}</StyledTableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {registeredRows.map((row) => (
              <StyledTableRow key={row['name'] + 1 + 'r'}>
                <StyledTableCell component="th" scope="row">
                  {row['name']}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row['email_address']}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row['quantity']}
                </StyledTableCell>
                <StyledTableCell align="right">
                  {
                    //@ts-ignore
                    row['ticket_type'].indexOf('(') > 0 ? row['ticket_type'].substring(0, row['ticket_type'].indexOf('(')) : row['ticket_type']
                  }
                </StyledTableCell>
                <StyledTableCell align="right">
                  {row['purchase_date']}
                </StyledTableCell>
              </StyledTableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
};

export default RegistrationDetails;
