import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Textarea from "shared/Textarea/Textarea";
import Comment from "shared/Comment/Comment";
import { axiosWP } from "utils/api";
import * as Sentry from '@sentry/react';
import qs from "qs";
import analytics from "utils/analytics";
import { useUser } from "context/UserContext";
//import { magic } from "libs/magic";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import axios from "utils/api";
import GifPicker, { TenorImage } from 'gif-picker-react';
import EmojiPicker, { EmojiClickData, SuggestionMode } from 'emoji-picker-react';
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonThird from "shared/Button/ButtonThird";
import { useMagic } from "context/MagicProvider";



export interface PageConnectWalletProps {
  className?: string;
}

export interface Comments {
  name?: string;
  date?: string;
  comment?: string;
  gifUrl?: string;
  gifWidth?: number;
  gifHeight?: number;
  username?: string;
  image?: string;
}

export interface GifPreview {
  height?: number;
  url?: string;
  width?: number;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const EventChat: FC<PageConnectWalletProps> = ({ className = "" }) => {
  const { t, i18n } = useTranslation();
  const [emoji, setEmoji] = useState(false);
  const [gif, setGif] = useState<GifPreview>();

  const [gifTenor, setGifTenor] = useState(false);
  
  const [messageResponse, setmessageResponse] = useState('');
  const [commentData, setCommentData] = useState<Comments[]>([]);
  const [totalComments, setTotalComments] = useState(0);
  const [loadingStyle, setLoadingStyle] = useState("hidden");
  const [events, setEvents] = useState([]);
  const [contractAddress, setContractAddress] = useState("");
  const [eventTotalComments, setEventTotalComments] = useState([]);
  const [loadComm, setLoadComm] = useState(false);
  const [loadEven, setLoadEven] = useState(true);
  const [authenticateStyle, setAuthenticateStyle] = useState("hidden");

  const { user } = useUser();
  const { magic } = useMagic()


  function setEmojiOption() {
    if(emoji) {
      setEmoji(false);
    }
    else {
      setEmoji(true);
      setGifTenor(false);
    }
  }

  function setGifOption() {
    if(gifTenor) {
      setGifTenor(false);
    }
    else {
      setGifTenor(true);
      setEmoji(false);
    }
  }


  useEffect(() => {
    const getContracts = async () => {
      try{
        //const isLoggedIn = await magic.user.isLoggedIn();
        if(user){
          setAuthenticateStyle("hidden");
          setLoadingStyle("flex");
            //const userInfo = await magic?.user.getInfo();
            
            const body = qs.stringify({
            user: user?.address,
            pkey: process.env.REACT_APP_PKEY,
            chain:process.env.REACT_APP_OPEN_LOGIN_NETWORK,
            });
        
            const response = await axiosWP.post('/event_creation/chat_events.php', body, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
            });
                
            if (response?.data?.su == 2) {
                setmessageResponse("");
                setEventTotalComments(response?.data?.events);
            }else {
                setmessageResponse(response?.data.mssg);
                setLoadingStyle("hidden");
                return;
            }

            const query = qs.stringify({
            fields: ['mainImage', 'name', 'id','address','photos'],
            filters: {
            isActive: true,
            address:{
                $in: response?.data?.addresses
            }
            },
            sort: ['eventDateTime:desc'],
            populate: ['mainImage','photos']
            }, {
            encodeValuesOnly: true,
            });

            const reponse = await axios.get(`/api/contracts?${query}`);
            if (reponse.status === 200) {
                const allEvents = reponse.data.data
                setEvents(allEvents);
                setLoadEven(true);
            } else {
                Sentry.captureException(reponse);
                console.log("Error ocurred requesting contracts", reponse);
            }
            setLoadingStyle("hidden");
        }else{
          setAuthenticateStyle("block");
          setLoadEven(false);
          setLoadComm(false);
        }
      }catch(error: any){
          Sentry.captureException(error);
          window.location.reload();
      }
    }
    getContracts();
  }, [user]);

  async function reloadComments(){
    try{
      //const isLoggedIn = await magic.user.isLoggedIn();
      if (user) {
        setLoadEven(false);
        setLoadingStyle("flex");
        //const userInfo = await magic?.user.getInfo();
  
        //setContractAddress(event.target.dataset.address);
    
        const query = {
          pkey: process.env.REACT_APP_PKEY,
          event: contractAddress,
          user: user?.address,
          chain:process.env.REACT_APP_OPEN_LOGIN_NETWORK,
        };
    
        const body = qs.stringify(query);
        const response = await axiosWP.post(
          '/event_creation/retrieve_event_comment.php',
          body,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
    
        if (response?.data?.su === 200) {
          analytics.track('event_comment_retrieved');
          setLoadingStyle("hidden");
          setLoadComm(true);
          setCommentData(response?.data?.result);
          setTotalComments(response?.data?.result.length);
        } else{
          Sentry.captureException(response?.data?.mssg);
          console.log('Error ocurred:', response?.data?.mssg);
          setLoadingStyle("hidden");
          setLoadEven(false);
          alert(response?.data?.mssg);
        }
      }
    }catch(error: any){
        Sentry.captureException(error);
        window.location.reload();
    }
  }

  async function loadComments(event:any){
    try{
      //const isLoggedIn = await magic.user.isLoggedIn();
      if (user) {
        setLoadEven(false);
        setLoadingStyle("flex");
        //const userInfo = await magic?.user.getInfo();
        if(event.target.dataset.address == ""){
          setmessageResponse("Event can't be empty");
          return;
        }
        setmessageResponse("");
  
        setContractAddress(event.target.dataset.address);
    
        const query = {
          pkey: process.env.REACT_APP_PKEY,
          event: event.target.dataset.address,
          user: user?.address,
          chain:process.env.REACT_APP_OPEN_LOGIN_NETWORK,
        };
    
        const body = qs.stringify(query);
        const response = await axiosWP.post(
          '/event_creation/retrieve_event_comment.php',
          body,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
    
        if (response?.data?.su === 200) {
          analytics.track('event_comment_retrieved');
          setLoadingStyle("hidden");
          setLoadComm(true);
          setCommentData(response?.data?.result);
          setTotalComments(response?.data?.result.length);
        } else{
          Sentry.captureException(response?.data?.mssg);
          console.log('Error ocurred:', response?.data?.mssg);
          setLoadingStyle("hidden");
          setLoadEven(false);
          alert(response?.data?.mssg);
        }
      }
    }catch(error: any){
        Sentry.captureException(error);
        window.location.reload();
    }
  }

  async function submitComment(event: any) {
    try{
      //const isLoggedIn = await magic.user.isLoggedIn();
      event.target.disabled = true;
      if (user) {
        
        var commentArea = document.getElementById("commentText") as HTMLTextAreaElement;
        //const userInfo = await magic?.user.getInfo();
  
        if(commentArea.value == "" && !gif?.url){
          setmessageResponse("Comment can't be empty");
          event.target.disabled = false;
          return;
        }
  
        if(contractAddress == ""){
          setmessageResponse("Event can't be empty");
          event.target.disabled = false;
          return;
        }
  
        if(contractAddress == ""){
          setmessageResponse("Name can't be empty");
          event.target.disabled = false;
          return;
        }
        setmessageResponse("");
        setEmoji(false);
        setGifTenor(false);
  
        const query = {
          pkey: process.env.REACT_APP_PKEY,
          user: user?.address,
          event: contractAddress,
          name: "name",
          gif: gif,
          comment: commentArea.value,
          chain:process.env.REACT_APP_OPEN_LOGIN_NETWORK,
  
        };
  
        const body = qs.stringify(query);
        const response = await axiosWP.post(
          '/event_creation/event_comment.php',
          body,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
  
        if (response?.data?.su === 200) {
          analytics.track('event_comment');
          reloadComments();
          commentArea.value = "";
          setGif({});
        } else{
          Sentry.captureException(response?.data?.mssg);
          console.log('Error ocurred:', response?.data?.mssg);
        }
      }
      event.target.disabled = false;
    }catch(error: any){
        Sentry.captureException(error);
        event.target.disabled = false;
        window.location.reload();
    }
  }

  function getClickedEmoji(emojiData: EmojiClickData) {
    var commentArea = document.getElementById("commentText") as HTMLTextAreaElement;
    commentArea.value = commentArea.value + emojiData.emoji;
  }

  function getClickedGif(ternorData: TenorImage) {
    setGif(ternorData.preview);
    setGifTenor(false);
  }

  function backToEvents() {
    setmessageResponse("");
    setLoadComm(false);
    setLoadEven(true);
  }

  async function authenticate() {
    if (!user?.address) {
      if(localStorage.getItem('magic')=="true"){
        localStorage.setItem('magic', 'false');
        window.location.reload();
        return;
      }else{
        let wcbutton: HTMLElement = (document.getElementById("walletConnecDiv")?.childNodes[0] as HTMLElement)?.children[0] as HTMLElement;
        wcbutton.click();
        return;
      }
    }
}

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
         <div className="mt-2 mb-2 space-x-3 text-center">
            <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200 inline">
              Tiiks ({totalComments})
            </h3>
            <ButtonSecondary className="inline" onClick={backToEvents}>Back to Events</ButtonSecondary>
          </div>
        
        <div className="nc-SingleCommentForm mt-5">
          {gif?.url && <div className="flex justify-center items-center mb-2">
            <img
                src={gif?.url}
                width={gif?.width}
                height={gif?.height}
                alt="Selected GIF"
            />
          </div>
          }
          <Textarea placeholder="Hype up the comment section" className="h-10" id="commentText" />
          <div className="mt-2 mb-2 space-x-3 text-right">
          <a onClick={setGifOption}><img style={{width:'24px', cursor: 'pointer', display:'inherit'}} src="https://res.cloudinary.com/cloudbloks/image/upload/v1705374968/gif_1_p1li65.png"></img></a>
          <a onClick={setEmojiOption}><img style={{width:'24px', cursor: 'pointer', display:'inherit'}} src="https://res.cloudinary.com/cloudbloks/image/upload/v1705190484/pngwing.com_1_np1hfy.png"></img></a>
            <ButtonPrimary onClick={submitComment}>Submit</ButtonPrimary>
          </div>

          {
            gifTenor && 
            <GifPicker clientKey="tiike" onGifClick={getClickedGif} tenorApiKey={"AIzaSyDnyAlZwkmc3e6avDOU5dW4agnvwlDbGmI"} height="400px" width="18em" />
          }
          {
            emoji && 
            <EmojiPicker style={{marginLeft:'auto', marginRight:'0',marginTop:'7px', zIndex:'1'}} suggestedEmojisMode={SuggestionMode.RECENT} onEmojiClick={getClickedEmoji} searchDisabled={true} height="400px" width="18em" />
            }
        </div>
      </div>
    );
  };

  const renderCommentLists = () => {

    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          {
            commentData.map((comment, index) => {
              index++;
              return <li key={"li" + index}><Comment key={"comment" + index} name={comment.name} text={comment.comment} created={comment.date} gifUrl={comment.gifUrl} gifWidth={comment.gifWidth} gifHeight={comment.gifHeight} userName={comment.username} image={comment.image} /></li>
             })
          }
        </ul>
      </div>
    );
  };

  const renderEventList = () => {
    return (
      <div className="container relative space-y-24 my-10 lg:space-y-32 lg:my-15">
        <SectionGridAuthorBox onClick={(event) => {
          loadComments(event);
          //analytics.track('event_chat_selected');
        }} sectionStyle="style2" boxCard="box4" data={events} comments={eventTotalComments}/>
      </div>
    );
  };

  return (
    <div
      className={`nc-PageConnectWallet ${className}`}
      data-nc-id="EventChat"
    >
        <Helmet>
            <title>Tiikea</title>
        </Helmet>

        <header className="text-center max-w-2xl mx-auto mt-5 mb-0">
                <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Tiikea
                </h2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200" style={{ fontSize: "12px" }}>
                    Select the event to tiik (chat) with other ticket holders
                </span>
        </header>

        {
            <div className={`items-center justify-center ${loadingStyle}`}>
              <svg className="animate-spin text-green-500" width="60px" height="60px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path fillRule="evenodd" clipRule="evenodd" d="M13.917 7A6.002 6.002 0 0 0 2.083 7H1.071a7.002 7.002 0 0 1 13.858 0h-1.012z"/>
              </svg> 
            </div>
          }
          <section className={`text-neutral-600 text-sm md:text-base overflow-hidden ${authenticateStyle}`} style={{ textAlign: "center" }}>
            <ButtonPrimary onClick={authenticate} className={`rounded bg-[#4d996a] mb-2 mt-2`} type="button">Login</ButtonPrimary><br />
            <p className="mb-2" style={{ color: "white" }}>{messageResponse}</p>
          </section>



        {loadEven? renderEventList() : null}
        {loadComm? renderCommentForm() : null}
        {loadComm? renderCommentLists(): null}
    </div>
  );
};

export default EventChat;
