import Label from "components/Label/Label";
import React, { FC, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { useMagic } from "context/MagicProvider";
import { useUser } from "context/UserContext";
import qs from "qs";
import { axiosWP } from "utils/api";
import * as Sentry from '@sentry/react';
import { useTranslation } from "react-i18next";
import ReactS3Client from 'react-aws-s3-typescript';


const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;
const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
const S3URL = process.env.REACT_APP_S3URL;

const s3Config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  s3Url: S3URL,
};


export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {

  const { magic } = useMagic()
  const { user } = useUser();
  const { t, i18n } = useTranslation();

  // @ts-ignore
  const s3 = new ReactS3Client(s3Config);


  const [username, setUsername] = useState('');
  const [name, setName] = useState('');
  const [dob, setDob] = useState('');
  const [twitter, setTW] = useState('');
  const [instagram, setIG] = useState('');
  const [telegram, setTG] = useState('');
  const [facebook, setFB] = useState('');
  const [bio, setBio] = useState('');
  const [email, setEmail] = useState('');
  const [emailUpdate, setEmailUpdate] = useState('');
  const [image, setImage] = useState<File>();
  const [imageURL, setImageURL] = useState('');


  const [loadingStyle, setloadingStyle] = useState("none");
  const [authenticateStyle, setAuthenticateStyle] = useState("block");
  const [retrieveResponse, setRetrieveResponse] = useState(0);
  const [retrieveMssg, setRetrieveMssg] = useState("");

  async function userDetails(){
      try{
        if (user) {
            const body = qs.stringify({
                user: user?.address,
                pkey: process.env.REACT_APP_PKEY
            });

            setAuthenticateStyle("hidden");
            setloadingStyle("flex");

            const response = await axiosWP.post(
                '/event_creation/profileView.php',
                body,
                {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                }
              );

            setRetrieveResponse(response?.data?.su);

            if (response?.data?.su == 200) {
                setEmail(response?.data?.profile[0]);
                setName(response?.data?.profile[1]);
                setIG(response?.data?.profile[2]);
                setFB(response?.data?.profile[3]);
                setTW(response?.data?.profile[4]);
                setTG(response?.data?.profile[5]);
                setBio(response?.data?.profile[6]);
                setImageURL(response?.data?.profile[7]);
                setUsername(response?.data?.profile[8]);
                setDob(response?.data?.profile[9]);
                setloadingStyle("none");
            } else {
                setRetrieveMssg(response?.data?.mssg);
                setloadingStyle("none");
                setAuthenticateStyle("hidden");
            }
        }else{
            setRetrieveResponse(0);
            setAuthenticateStyle("block");
        }      
    }catch(error: any){
        Sentry.captureException(error);
        window.location.reload();
    }
  }

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();
      try{
        if (user) {

          let imgUlr=imageURL;

          if(image){
            const res: any = await s3
            .uploadFile(image!, image!.name)
            .then((data) => {
              return data;
            })
            .catch((err) => console.error(err));
            imgUlr = res.location;
            setImageURL(res.location);
          }


            const body = qs.stringify({
                user: user?.address,
                pkey: process.env.REACT_APP_PKEY,
                name:name,
                twitter:twitter,
                instagram:instagram,
                telegram:telegram,
                facebook:facebook,
                bio:bio,
                email:emailUpdate ? emailUpdate : email,
                username:username,
                dob:dob,
                imageurl: imgUlr,
            });

            setloadingStyle("flex");

            const response = await axiosWP.post(
                '/event_creation/profileUpdate.php',
                body,
                {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                }
              );

            setRetrieveResponse(response?.data?.su);

            if (response?.data?.su == 200) {
                setRetrieveMssg(response?.data?.mssg);
                setloadingStyle("none");
                //window.location.reload();
            } else {
                setRetrieveMssg(response?.data?.mssg);
                setloadingStyle("none");
            }
        }else{
            setRetrieveResponse(0);
            setAuthenticateStyle("block");
        }      
    }catch(error: any){
        Sentry.captureException(error);
        window.location.reload();
    }
  }

  async function updateProfile(){
      try{
        if (user) {

          let imgUlr=imageURL;

          if(image){
            const res: any = await s3
            .uploadFile(image!, image!.name)
            .then((data) => {
              return data;
            })
            .catch((err) => console.error(err));
            imgUlr = res.location;
            setImageURL(res.location);
          }


            const body = qs.stringify({
                user: user?.address,
                pkey: process.env.REACT_APP_PKEY,
                name:name,
                twitter:twitter,
                instagram:instagram,
                telegram:telegram,
                facebook:facebook,
                bio:bio,
                email:emailUpdate ? emailUpdate : email,
                username:username,
                dob:dob,
                imageurl: imgUlr,
            });

            setloadingStyle("flex");

            const response = await axiosWP.post(
                '/event_creation/profileUpdate.php',
                body,
                {
                  headers: {
                    'Content-Type': 'application/x-www-form-urlencoded',
                  },
                }
              );

            setRetrieveResponse(response?.data?.su);

            if (response?.data?.su == 200) {
                setRetrieveMssg(response?.data?.mssg);
                setloadingStyle("none");
                //window.location.reload();
            } else {
                setRetrieveMssg(response?.data?.mssg);
                setloadingStyle("none");
            }
        }else{
            setRetrieveResponse(0);
            setAuthenticateStyle("block");
        }      
    }catch(error: any){
        Sentry.captureException(error);
        window.location.reload();
    }
  }

  async function authenticate() {
        try{
            if (user) {
              userDetails();
            } else {
                if(localStorage.getItem('magic')=="true"){
                    localStorage.setItem('magic', 'false');
                    window.location.reload();
                    return;
                  }else{
                    let wcbutton: HTMLElement = (document.getElementById("walletConnecDiv")?.childNodes[0] as HTMLElement)?.children[0] as HTMLElement;
                    wcbutton.click();
                    return;
                  }
            }
        }catch(error: any){
            Sentry.captureException(error);
            window.location.reload();
        }
    }

  useEffect(() => {
    userDetails();
  }, [user]);

  const handleImage = (event: any) => {
    const file = event.target.files[0];
    const validExtensions = ['png', 'jpeg', 'jpg', 'gif'];
    var fileReader = new FileReader();
    fileReader.onloadend = function (e) {
      // @ts-ignore
      var arr = new Uint8Array(e.target.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      // Check the file signature against known types
      let type = '';
      switch (header) {
        case '89504e47':
          type = 'image/png';
          break;
        case '47494638':
          type = 'image/gif';
          break;
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
        case 'ffd8ffdb':
          type = 'image/jpeg';
          break;
        default:
          type = 'unknown'; // Or you can use the blob.type as fallback
          break;
      }
      const fileExtension = type.split('/')[1];
      if (validExtensions.includes(fileExtension)) {
        setImage(file);
        setImageURL(URL.createObjectURL(file));
      } else {
        alert(`File type ${type} not supported`);
      }
    };
    fileReader.readAsArrayBuffer(file);
  };

  const handleName = (event: any) => {
    setName(event.target.value);
  };

  const handleDate = (event: any) => {
    setDob(event.target.value);
  };

  const handleUsername = (event: any) => {
    setUsername(event.target.value);
  };

  const handleEmailUpdate = (event: any) => {
    setEmailUpdate(event.target.value);
  };

  const handleIG = (event: any) => {
    setIG(event.target.value);
  };

  const handleTG = (event: any) => {
    setTG(event.target.value);
  };

  const handleFB = (event: any) => {
    setFB(event.target.value);
  };

  const handleTW = (event: any) => {
    setTW(event.target.value);
  };

  const handleBio = (event: any) => {
    setBio(event.target.value);
  };
  
  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Account</title>
      </Helmet>
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              Profile settings
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              You can set preferred display name and
              manage other personal settings.
            </span>
            <div className="loader" style={{ display: loadingStyle }}>
                    <div className="loading"></div>
                </div><br></br>
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          {
            retrieveResponse == 0 ?(
            <></>
            )
            :
            (
              <div >
              <form className="flex flex-col md:flex-row" onSubmit={handleSubmit}>
              <div className="flex-shrink-0 flex items-start">
                <div className="relative rounded-full overflow-hidden flex">
                  <Avatar imgUrl={imageURL ? imageURL : "https://res.cloudinary.com/cloudbloks/image/upload/temporaryp.jpg"} sizeClass="w-32 h-32" />
                  <div className="absolute inset-0 bg-black bg-opacity-60 flex flex-col items-center justify-center text-neutral-50 cursor-pointer">
                    <svg
                      width="30"
                      height="30"
                      viewBox="0 0 30 30"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M17.5 5H7.5C6.83696 5 6.20107 5.26339 5.73223 5.73223C5.26339 6.20107 5 6.83696 5 7.5V20M5 20V22.5C5 23.163 5.26339 23.7989 5.73223 24.2678C6.20107 24.7366 6.83696 25 7.5 25H22.5C23.163 25 23.7989 24.7366 24.2678 24.2678C24.7366 23.7989 25 23.163 25 22.5V17.5M5 20L10.7325 14.2675C11.2013 13.7988 11.8371 13.5355 12.5 13.5355C13.1629 13.5355 13.7987 13.7988 14.2675 14.2675L17.5 17.5M25 12.5V17.5M25 17.5L23.0175 15.5175C22.5487 15.0488 21.9129 14.7855 21.25 14.7855C20.5871 14.7855 19.9513 15.0488 19.4825 15.5175L17.5 17.5M17.5 17.5L20 20M22.5 5H27.5M25 2.5V7.5M17.5 10H17.5125"
                        stroke="currentColor"
                        strokeWidth={1.5}
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </svg>

                    <span className="mt-1 text-xs">Change Image</span>
                  </div>

                  <input
                    id="file-upload"
                    name="file-upload"
                    type="file"
                    className="absolute inset-0 opacity-0 cursor-pointer"
                    accept="image/*"
                    onChange={(e) => {
                      handleImage(e);
                    }}
                  />
                </div>
              </div>
              <div className="flex-grow mt-10 md:mt-0 md:pl-16 max-w-3xl space-y-5 sm:space-y-6 md:sm:space-y-7">

                {/* ---- 
                <div>
                  <Label>Username</Label>
                  <Input className="mt-1.5" 
                  required
                  defaultValue={username} 
                  onChange={(e) => {
                    handleUsername(e);
                  }}/>
                </div>
                */}

                {/* ---- */}
                <div>
                  <Label>Name</Label>
                  <Input className="mt-1.5" 
                  required
                  placeholder="Enter your name"
                  defaultValue={name} 
                  onChange={(e) => {
                    handleName(e);
                  }}/>
                </div>

                {/* ---- */}
                <div>
                  <Label>Email</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      <i className="text-2xl las la-envelope"></i>
                    </span>
                    {
                      email ? <Input
                      className="!rounded-l-none"
                      defaultValue={email}
                      disabled
                    /> :
                    <Input
                      className="!rounded-l-none"
                      defaultValue={emailUpdate}
                      placeholder="Enter email address"
                      onChange={(e) => {
                        handleEmailUpdate(e);
                      }}
                    />
                    }
                  </div>
                </div>

                {/* ---- */}
                <div>
                  <Label>Date of birth</Label>
                  <Input
                  required
                  type="date"
                  className="mt-1"
                  defaultValue={dob} 
                  onChange={(e) => {
                    handleDate(e);
                  }}
                />
                </div>

                {/* ---- */}
                <div>
                  <Label>Bio</Label>
                  <Textarea
                    rows={5}
                    className="mt-1.5"
                    placeholder="Something about yourself in a few word."
                    defaultValue={bio} 
                    onChange={(e) => {
                      handleBio(e);
                    }}
                  />
                </div>

                {/* ---- 
                <div className="">
                  <Label>Website</Label>
                  <div className="mt-1.5 flex">
                    <span className="inline-flex items-center px-3 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                      https://
                    </span>
                    <Input
                      className="!rounded-l-none"
                      placeholder="yourwebsite.com"
                    />
                  </div>
                </div>
                */}

                {/* ---- */}
                <div className="grid grid-cols-1 sm:grid-cols-3 gap-5 sm:gap-2.5">
                  <div>
                    <Label>Instagram</Label>
                    <div className="mt-1.5 flex">
                      <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                        <i className="text-2xl lab la-instagram"></i>
                      </span>
                      <Input
                        className="!rounded-l-none"
                        placeholder="Enter username"
                        defaultValue={instagram} 
                        sizeClass="h-11 px-4 pl-2 pr-3"
                        onChange={(e) => {
                          handleIG(e);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <Label>Facebook</Label>
                    <div className="mt-1.5 flex">
                      <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                        <i className="text-2xl lab la-facebook-f"></i>
                      </span>
                      <Input
                        className="!rounded-l-none"
                        placeholder="Enter username"
                        defaultValue={facebook} 
                        sizeClass="h-11 px-4 pl-2 pr-3"
                        onChange={(e) => {
                          handleFB(e);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <Label>X</Label>
                    <div className="mt-1.5 flex">
                      <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                        <i className="text-2xl lab la-twitter"></i>
                      </span>
                      <Input
                        className="!rounded-l-none"
                        placeholder="Enter username"
                        defaultValue={twitter} 
                        sizeClass="h-11 px-4 pl-2 pr-3"
                        onChange={(e) => {
                          handleTW(e);
                        }}
                      />
                    </div>
                  </div>
                  <div>
                    <Label>Telegram</Label>
                    <div className="mt-1.5 flex">
                      <span className="inline-flex items-center px-2.5 rounded-l-2xl border border-r-0 border-neutral-200 dark:border-neutral-700 bg-neutral-50 dark:bg-neutral-800 text-neutral-500 dark:text-neutral-400 text-sm">
                        <i className="text-2xl lab la-telegram-plane"></i>
                      </span>
                      <Input
                        className="!rounded-l-none"
                        placeholder="Enter username"
                        defaultValue={telegram} 
                        sizeClass="h-11 px-4 pl-2 pr-3"
                        onChange={(e) => {
                          handleTG(e);
                        }}
                      />
                    </div>
                  </div>
                </div>

                {/* ---- 
                <div>
                  <Label>Wallet Address</Label>
                  <div className="mt-1.5 relative text-neutral-700 dark:text-neutral-300">
                    <Input
                      className="!pr-10 "
                      disabled
                      defaultValue="0x1bde388826caab77bfe80148abdce6830606e2c6"
                    />

                    <span className="absolute right-2.5 cursor-pointer top-1/2 -translate-y-1/2 ">
                      <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                        <path
                          d="M21.6602 10.44L20.6802 14.62C19.8402 18.23 18.1802 19.69 15.0602 19.39C14.5602 19.35 14.0202 19.26 13.4402 19.12L11.7602 18.72C7.59018 17.73 6.30018 15.67 7.28018 11.49L8.26018 7.30001C8.46018 6.45001 8.70018 5.71001 9.00018 5.10001C10.1702 2.68001 12.1602 2.03001 15.5002 2.82001L17.1702 3.21001C21.3602 4.19001 22.6402 6.26001 21.6602 10.44Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                        <path
                          d="M15.0603 19.3901C14.4403 19.8101 13.6603 20.1601 12.7103 20.4701L11.1303 20.9901C7.16034 22.2701 5.07034 21.2001 3.78034 17.2301L2.50034 13.2801C1.22034 9.3101 2.28034 7.2101 6.25034 5.9301L7.83034 5.4101C8.24034 5.2801 8.63034 5.1701 9.00034 5.1001C8.70034 5.7101 8.46034 6.4501 8.26034 7.3001L7.28034 11.4901C6.30034 15.6701 7.59034 17.7301 11.7603 18.7201L13.4403 19.1201C14.0203 19.2601 14.5603 19.3501 15.0603 19.3901Z"
                          stroke="currentColor"
                          strokeWidth="1.5"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        />
                      </svg>
                    </span>
                  </div>
                </div>
                */}

                {/* ---- */}
                <div className="pt-2">
                  <ButtonPrimary className="w-full">Update profile</ButtonPrimary>
                  <p className="text-center	text-white pt-2.5">{retrieveMssg}</p>
                </div>

                <div className="loader" style={{ display: loadingStyle }}>
                      <div className="loading"></div>
                  </div><br></br>
              </div>
              </form>
            </div>

            )
          }
          <ButtonPrimary onClick={authenticate} className={`rounded bg-[#4d996a] ${authenticateStyle}`} type="button">{"Login"}</ButtonPrimary><br />
        </div>
      </div>
    </div>
  );
};

export default AccountPage;
