import * as Sentry from '@sentry/react';
import qs from 'qs';
import { FC, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import analytics from 'utils/analytics';
import axiosInstance, { axiosWP } from 'utils/api';
import ReactMarkdown from 'react-markdown';
import Input from 'shared/Input/Input';



export interface PreRegistrationProps {
  className?: string;
}

const PreRegistration: FC<PreRegistrationProps> = ({ className = '' }) => {
  const [retrieveResponse, setRetrieveResponse] = useState(0);
  const [event, setEvent] = useState(false);

  const [retrieveMssg, setRetrieveMssg] = useState('');
  const [authenticateStyle, setAuthenticateStyle] = useState('block');
  const { t, i18n } = useTranslation();
  const params = useParams();
  const [image, setImage] = useState('');
  const [title, setTitle] = useState('');
  const [subTitle, setSubTitle] = useState('');
  const [isVideo, setIsVideo] = useState(false);
  const [isMuted, setIsMuted] = useState(false);
  const [about, setAbout] = useState('');
  const [email, setEmail] = useState('');


  analytics.track('pre_register');

  useEffect(() => {
    const fetchData = async () => {
      var { data: res } = await axiosInstance.get(
        // @ts-ignore
        '/api/contracts/' + params.id + '?populate=*'
      );
      var contract = res.data.attributes;
      if (contract.preRegisterMainImage.data) {
        setEvent(true);
        setImage(contract.preRegisterMainImage.data.attributes.url);
      } else {
      }

      if(contract.preRegisterTitle != ''){
        setTitle(contract.preRegisterTitle);
      }

      if(contract.preRegisterSubTitle != ''){
        setSubTitle(contract.preRegisterSubTitle);
      }

      if(contract.preRegisterVideo){
        setIsVideo(true);
        if(contract.preRegisterVideoMuted){
          setIsMuted(true)
        }else{
          setIsMuted(false);
        }
      }else{
        setIsVideo(false);
      }

      if(contract.about != ''){
        setAbout(contract.about);
      }

    };

    fetchData().catch((error) => {
      console.error(error);
      Sentry.captureException(error);
    });
  }, []);

  const handleEmail = (event: any) => {
    console.info(event.target.value);
    setEmail(event.target.value);
  };

  async function emailSubmit() {
    try{
      setRetrieveMssg("");
      if (!email) {     
        setRetrieveMssg("Email field can't be empty");
        return;
      } else {  
        const body = qs.stringify({
          email: email,
          // @ts-ignore
          eventID: params.id,
          pkey: process.env.REACT_APP_PKEY,
        });
  
        setAuthenticateStyle('hidden');
  
        const response = await axiosWP.post(
          '/event_creation/pre_registration.php',
          body,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );
  
        setRetrieveResponse(response?.data?.su);
  
        if (response?.data?.su == 200) {
          setRetrieveMssg(response?.data?.mssg);
        } else {
          setRetrieveMssg('Reload page and try again');
        }
      }
    }catch(error: any){
        Sentry.captureException(error);
        window.location.reload();
    }
  }

  return (
    <div
      className={`nc-PreRegistration overflow-hidden relative ${className}`}
      data-nc-id="PreRegistration"
    >
      <Helmet>
        <title>Get Notified</title>
      </Helmet>

      {event ? (
        <div>
          <header className="text-center mx-auto mt-10 mb-0">
            <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center pr-1.5 pl-1.5">
              {title ? title : t('pre-register-page.title')}
            </h2>
            <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200"></span>
            <span
              className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200"
              style={{ fontSize: '12px' }}
            >
              {subTitle ? subTitle : t('pre-register-page.subtitle')}
            </span>
          </header>
          <section
            className="text-neutral-600 text-sm md:text-base overflow-hidden"
            style={{ textAlign: 'center' }}
          >
            <br></br>

            <div className="ulNftResults">
            { isVideo ? 
              (
                isMuted ? 
                  <video className="singleImg" controls autoPlay loop playsInline muted >
                    <source src={image} type="video/mp4"/>
                  </video>
                : 
                  <video className="singleImg" controls autoPlay loop playsInline >
                    <source src={image} type="video/mp4"/>
                  </video>
              )
              :
              <img className='singleImg' src={image}></img>
            }
              <br></br>
              <br></br>
            </div>

            {retrieveResponse == 200 ? (
              <div className="ulNftResults">
                <p style={{ color: 'white' }}>{retrieveMssg}</p>
                <br></br>
              </div>
            ) : (
              <div className="ulNftResults">
                <p style={{ color: 'red' }}>{retrieveMssg}</p>
                <br></br>
              </div>
            )}

          <div className={`block mx-auto rounded-md max-w-md ${authenticateStyle}`}>
            <label className="block mb-2 mx-2.5">
              <Input
                placeholder={t('pre-register-page.email')}
                type="text"
                className="mt-1 text-white"
                id="emailInput"
                name="emailInput"
                required={true}
                onChange={(e) => {
                  handleEmail(e);
                }}
              />
            </label>
            </div>

            <ButtonPrimary
              onClick={emailSubmit}
              className={`rounded bg-[#4d996a] ${authenticateStyle}`}
              type="button"
            >
              {t('pre-register-page.button')}
            </ButtonPrimary>
            <br />
            <br />
            <br />
          </section>
        </div>
      ) : (
        <></>
      )}

      {about ? (
              <main className="container flex-col mb-8">
                <div className="mb-4">
                  <span className="text-2xl sm:text-2xl font-semibold"></span>
                </div>
                <article className="prose max-w-none prose-invert">
                  <ReactMarkdown linkTarget={'_blank'}>
                    {about}
                  </ReactMarkdown>
                </article>
              </main>
            ) : null}
    </div>
  );
};

export default PreRegistration;
