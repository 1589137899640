import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import CardNFT2 from "components/CardNFT2";
import HeaderFilterSection from "components/HeaderFilterSection";
import moment from "moment";
import qs from "qs";
import { FC, useEffect, useState } from "react";
import axios from "utils/api";

export interface SectionGridFeatureNFT2Props {}

const SectionGridFeatureNFT2: FC<SectionGridFeatureNFT2Props> = () => {

  const [events, setEvents] = useState([])

  useEffect(() => {

    const getContracts = async () => {

      const currentDay = moment().format('YYYY-MM-DD');
      const query = qs.stringify({
        fields: ['mainImage', 'price', 'eventDateTime', 'name', 'quantity', 'currency', 'id'],
        filters: {
          isActive: true,
          isHidden: false,
          eventDateTime: {
            $gte: currentDay
          }
        },
        sort: ['eventDateTime:asc'],
        populate: 'mainImage'
      }, {
        encodeValuesOnly: true,
      });

      const reponse = await axios.get(`/api/contracts?${query}`);
      if (reponse.status === 200) {
        const allEvents = reponse.data.data
        setEvents(allEvents);
      } else {
        console.log("Error ocurred requesting contracts", reponse);
      }
    }

    getContracts()

  }, []);

  return (
   <div>
    { events.length > 0  &&
      <div className="relative py-20 lg:py-28">
        <BackgroundSection className="bg-neutral-100/70 dark:bg-black/20 " />
        <div className="nc-SectionGridFeatureNFT2 relative">
            <HeaderFilterSection />
            <div className={`grid gap-6 lg:gap-8 sm:grid-cols-2 xl:grid-cols-3`}>
              {events.map((event, index) => (
                <CardNFT2 event={event} key={index} />
              ))}
            </div>
            {/* <div className="flex mt-16 justify-center items-center">
              <ButtonPrimary loading>Show me more</ButtonPrimary>
            </div> */}
        </div>
      </div>
    }
   </div>
  );
};

export default SectionGridFeatureNFT2;
