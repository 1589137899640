import React, { FC, useEffect, useRef, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";

//For QR Code
import {Html5Qrcode, Html5QrcodeSupportedFormats} from "html5-qrcode"
//For api calls
import axios from "axios";

import qs from "qs";
import { CameraDevice } from "html5-qrcode/esm/camera/core";
import { Html5QrcodeError, Html5QrcodeResult } from "html5-qrcode/esm/core";
import { useParams } from "react-router-dom";
import * as Sentry from "@sentry/react";
import { useAppSelector } from "app/hooks";
import { ethers } from "ethers";
import Swal from 'sweetalert2'
import axiosInstance, { axiosWP } from "utils/api";

import { useUser } from "context/UserContext";
import { useMagic } from "context/MagicProvider";
//import { magic } from "libs/magic";



//For qr code
let procs = 0;

const delay = (ms: number | undefined) => new Promise(res => setTimeout(res, ms));


export interface PageSubcriptionProps {
  className?: string;
}

const QRAuth: FC<PageSubcriptionProps> = ({ className = "" }) => {
  const [innerText, setInnerText] = useState("");
  const [totalTickets, setTotalTickets] = useState("");
  const [qrReaderStyle, setqrReaderStyle] = useState("none");
  const [selectCameraStyle, setSelectCameraStyle] = useState("none");
  const [loadingStyle, setloadingStyle] = useState("none");
  const [cameraOptions, setcameraOptions] = useState<CameraDevice[]>([]);
  const [modalStyle, setmodalStyle] = useState("none");
  const [retrieveImg, setRetrieveImg] = useState("");
  const [redeemedColorStyle, setredeemedColorStyle] = useState("");
  const [authenticateStyle, setAuthenticateStyle] = useState("hidden");
  const [retrieveMssg, setRetrieveMssg] = useState("");
  const [retrieveErr, setRetrieveErr] = useState(false);
  const [authMethod, setAuthMethod] = useState("wallet");
  const { user } = useUser();
  const { magic } = useMagic()


  const params = useParams();

  let html5QrCode: Html5Qrcode;

  const onOptionChange = (e: { target: { value: React.SetStateAction<string>; }; }) => {
    setAuthMethod(e.target.value);
  }

  window.onclick = function(event) {
    let wmdl = document.getElementById("walletModal") as HTMLSelectElement
    if (event.target ==  wmdl) {
        setmodalStyle("none");
    }
  }

  useEffect(() => {
    if(!user){
      setqrReaderStyle("none");
      setSelectCameraStyle("none");
      setAuthenticateStyle("block");
    }else{
      authenticate();
    }
},[user])

  async function authenticate() {

    setAuthenticateStyle("hidden");
    setloadingStyle("flex");
    setRetrieveMssg("");

    try{
      //const isLoggedIn = await magic.user.isLoggedIn();
      if (user) {
        //const userInfo = await magic?.user.getInfo();
          
          const body = qs.stringify({
            user: user?.address,
            pkey: process.env.REACT_APP_PKEY,
            // @ts-ignore
            scankey: params.id
          });
      
          const response = await axiosWP.post('/event_creation/qr_code_admin_authenticator.php', body, {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded"
            }
          });
              
          if (response?.data?.su == 1) {
            setqrReaderStyle("flex");
            Html5Qrcode.getCameras().then(devices => {   
                if (devices && devices.length) {
                    setcameraOptions(devices);
                }
            }).catch(err => {
                // handle err
            });
            setloadingStyle("none");    
            setSelectCameraStyle("flex");
          }else {
            setloadingStyle("none");
            setRetrieveMssg(response?.data.mssg);
          }
      } else {
        if(localStorage.getItem('magic')=="true"){
          localStorage.setItem('magic', 'false');
          window.location.reload();
          return;
        }else{
          let wcbutton: HTMLElement = (document.getElementById("walletConnecDiv")?.childNodes[0] as HTMLElement)?.children[0] as HTMLElement;
          wcbutton.click();
          return;
        }
      }
    }catch (error: any) {
      Sentry.captureException(error);
      window.location.reload();
    }
}

async function onScanSuccess(decodedText: string, decodedResult: Html5QrcodeResult) {
  html5QrCode.pause(true);
    let modstyl = document.getElementById("walletModal") as HTMLSelectElement ;
    if(!procs && modstyl.style.display == "none" || modstyl.style.display == ""){
        if(user){
            procs = 1;
           
            //const userInfo = await magic?.user.getInfo();
            setloadingStyle("flex");

            //@ts-ignore
            //var radioMethod = document.getElementById('walletrb').checked ? "wallet" : "ticket";

            var radioMethod = "";
            var radios = document.getElementsByName('method');
            for (var i = 0, length = radios.length; i < length; i++) {
              //@ts-ignore
              if (radios[i].checked) {
                //@ts-ignore
                radioMethod = radios[i].value;
                break;
              }
            }

            const body = qs.stringify({
            user: user?.address,
            uid: decodedText,
            pkey: process.env.REACT_APP_PKEY,
            chain:process.env.REACT_APP_OPEN_LOGIN_NETWORK,
            // @ts-ignore
            scankey: params.id,
            method: radioMethod
            });
    
            setAuthenticateStyle("hidden");
            const response = await axiosWP.post('/event_creation/admin_register_authenticator.php', body, {
              headers: {
                "Content-Type": "application/x-www-form-urlencoded"
              }
            });
            //setRetrieveResponse(response?.data?.su);
            
            validateWalletRegistration(response?.data);
            
        }else{
            procs = 0;
        }
    }
    html5QrCode.resume();
}


async function validateWalletRegistration(data: any){
  let response = "";
  debugger;
  if(data.length>0){
    setTotalTickets(data.length);
    for (let i = 0; i < data.length; i++) {
      if(data[i].su == 1){
        setRetrieveErr(false);
        setloadingStyle("none");
        /*let imgUrl = data[i].found;
        if(imgUrl.toLowerCase().includes("ipfs://")){
            imgUrl = imgUrl.replace("ipfs://","https://ipfs.io/ipfs/");
        }
        setRetrieveImg(imgUrl);*/
        response = (response + data[i].mssg + '\n');
        setInnerText(response);
        if(data[i].redmd) {
            setredeemedColorStyle("#ffa828");
        }else{
            setredeemedColorStyle("#5de5c9");
        }
        setmodalStyle("flex");
        procs = 0;
      }else if(data[i].su == 500) {
          Sentry.captureException(data[i].mssg);
          setloadingStyle("none");
          setRetrieveImg("https://dbloks.com/wp-content/themes/neve-child/images/failed.png");
          setredeemedColorStyle("#f17676");
          setInnerText("Please try again");
          setmodalStyle("flex");
          setRetrieveErr(true);
          procs = 0;
      }else{
          setloadingStyle("none");
          setRetrieveImg("https://dbloks.com/wp-content/themes/neve-child/images/failed.png");
          setredeemedColorStyle("#f17676");
          setInnerText(data[i].mssg);
          setmodalStyle("flex");
          setRetrieveErr(true);
          procs = 0;
      }
    }
  }else{
    setloadingStyle("none");
    setRetrieveImg("https://dbloks.com/wp-content/themes/neve-child/images/failed.png");
    setredeemedColorStyle("#f17676");
    setInnerText(data.mssg);
    setmodalStyle("flex");
    setRetrieveErr(true);
    procs = 0;
  }
}

async function validateRegistration(data: any){
    if(data.su == 1){
        setloadingStyle("none");
        let imgUrl = data.found;
        if(imgUrl.toLowerCase().includes("ipfs://")){
            imgUrl = imgUrl.replace("ipfs://","https://ipfs.io/ipfs/");
        }
        setRetrieveImg(imgUrl);
        setInnerText(data.mssg);
        if(data.redmd) {
            setredeemedColorStyle("#ffa828");
        }else{
            setredeemedColorStyle("#5de5c9");
        }
        setmodalStyle("flex");
        procs = 0;
    }else if(data.su == 500) {
        Sentry.captureException(data.mssg);
        setloadingStyle("none");
        setRetrieveImg("https://dbloks.com/wp-content/themes/neve-child/images/failed.png");
        setredeemedColorStyle("#f17676");
        setInnerText("Please try again");
        setmodalStyle("flex");
        procs = 0;
    }else{
        setloadingStyle("none");
        setRetrieveImg("https://dbloks.com/wp-content/themes/neve-child/images/failed.png");
        setredeemedColorStyle("#f17676");
        setInnerText(data.mssg);
        setmodalStyle("flex");
        procs = 0;
    }
}


function onScanFailed(decodedText: string, decodedResult: Html5QrcodeError) {
    //Sentry.captureException("Failed scanning code"+decodedText);
}

function startC(e: any){
    let qrboxFunction = function(viewfinderWidth: number, viewfinderHeight: number) {
        let minEdgePercentage = 0.7; // 70%
        let minEdgeSize = Math.min(viewfinderWidth, viewfinderHeight);
        let qrboxSize = Math.floor(minEdgeSize * minEdgePercentage);
        return {
            width: qrboxSize,
            height: qrboxSize
        };
    };

    let cameraId = document.getElementById("selectCamera") as HTMLSelectElement ;

    html5QrCode = new Html5Qrcode(
        "qr-reader", { formatsToSupport: [ Html5QrcodeSupportedFormats.QR_CODE ], verbose: true });
    const config = { fps: 10, qrbox: qrboxFunction };
    html5QrCode.start({ deviceId: { exact: cameraId.value} }, config, onScanSuccess, onScanFailed); 
}


  return (
    <div
      className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
      data-nc-id="QRAuth"
    >
      <header className="text-center max-w-2xl mx-auto mt-20">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          QR Authentication
        </h2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
        Scan the QR Code to validate Ticket ownership
        </span>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200" style={{ fontSize: "12px" }}>
        Select authentication method, default will be wallet
        </span>

        <input
        type="radio"
        name="method"
        value="wallet"
        id="walletrb"
        checked={authMethod == "wallet"}
        onChange={onOptionChange}
        />
        <label className="pr-2" htmlFor="walletrb">TiKEY</label>

        <input
          type="radio"
          name="method"
          value="ticket"
          id="ticketrb"
          checked={authMethod == "ticket"}
          onChange={onOptionChange}
        />
        <label className="pr-2" htmlFor="ticketrb">Paper Ticket</label>

        
      </header>
      <section className="text-neutral-600 text-sm md:text-base overflow-hidden" style={{ textAlign: "center" }}>
        
        <div className="loader" style={{ display: loadingStyle }}>
          <div className="loading"></div>
        </div><br></br>

        <div id="walletModal" className="modal" style={{display: modalStyle}}  data-modal-name="sample-modal2" data-modal-dismiss="">
            <div className="modal__dialog" style={{backgroundColor: redeemedColorStyle}} >
                <div id="valid" className="modal__content px-2" style={{textAlign: "center"}}>
                    {retrieveErr ? (
                        <div>
                        <img className="singleImg" src={retrieveImg} style={{maxHeight: "500px"}}></img><br></br>
                        <p className="pb-2" style={{whiteSpace:"pre-wrap"}}>{innerText}</p> 
                      </div>
                      ): 
                      (
                        <div>
                          <h1 className="text-2xl pt-2" style={{whiteSpace:"pre-wrap"}}>Total: {totalTickets}</h1>
                    <p className="pb-2" style={{whiteSpace:"pre-wrap"}}>{innerText}</p>
                        </div>
                      )
                      
                      }
                    
                </div>
            </div>
        </div>

        <p style={{ color: "white" }}>{retrieveMssg}</p>

        <div>
            <div style={{display: "flex",justifyContent: "center"}}>
                <div id="qr-reader" style={{display: qrReaderStyle,width:"800px"}}></div>
            </div>
            <br></br>
            <div style={{display: "flex",justifyContent: "center"}}>
                <select id="selectCamera" onChange={startC} style={{display: selectCameraStyle}}>
                    <option>Choose camera:</option>
                    {
                        cameraOptions.map((cameraOption, index) => {
                            return <option key={"opt" + index} value={cameraOption.id}>{cameraOption.label} 
                                </option>;
                        })
                    }
                </select>
            </div>
            <br></br>
        </div>

        <ButtonPrimary onClick={authenticate} className={`rounded bg-[#4d996a] ${authenticateStyle}`} type="button">AUTHENTICATE</ButtonPrimary><br />
      </section>
    </div>
  );
};

export default QRAuth;
