import { CheckIcon } from "@heroicons/react/solid";
import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import qs from "qs";
import { useParams } from "react-router-dom";
import { axiosWP } from "utils/api";


export interface RandomSelectorProps {
    className?: string;
}

const RandomSelector: FC<RandomSelectorProps> = ({ className = "" }) => {

    const [loadingStyle, setloadingStyle] = useState("none");
    const [retrieveMssg, setRetrieveMssg] = useState("");
    const [retrieveName, setRetrieveName] = useState("");
    const [availableStyle, setAvailableStyle] = useState("none");
    const params = useParams();

    const delay = (ms: number | undefined) => new Promise(res => setTimeout(res, ms));


    async function randomWinner() {

        setAvailableStyle("none");
        setloadingStyle("flex");
        setRetrieveMssg("");
        setRetrieveName("");

        await delay(1000);

        const body = qs.stringify({
            pkey: process.env.REACT_APP_PKEY,
            // @ts-ignore
            caddress: params.id
        });

        //setRetrieveMssg("NFT #1");
        //setRetrieveName("Gian Irizarry");

        const response = await axiosWP.post('/event_creation/WinnerSelector.php', body, {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        }); 

        if (response?.data?.su == 1) {
            setRetrieveMssg("NFT #"+response?.data.tokenId);
            setRetrieveName(response?.data.name);
        } else {
            setRetrieveMssg(response?.data.mssg);
        } 
        setAvailableStyle("block");
        setloadingStyle("none");
    }

    return (
        <div
            className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
            data-nc-id="RandomSelector"
        >
            <header className="text-center max-w-2xl mx-auto my-20">
                <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Giveaway Winner Selector
                </h2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                    Only digital collectible owners will be participating
                </span>
            </header>
            <section className="text-neutral-600 text-sm md:text-base overflow-hidden" style={{ textAlign: "center" }}>

                <div className="loader" style={{ display: loadingStyle }}>
                    <div className="loading"></div>
                </div>

                <div className="ulNftResults" style={{ display: availableStyle }}>
                    <img className="singleImg" src="https://res.cloudinary.com/cloudbloks/image/upload/v1688034889/Raffle_3_wcimwy.png"></img><br></br>
                    <p style={{ color: "white" }}>{retrieveMssg}</p>
                    <p style={{ color: "white" }}>{retrieveName}</p>
                </div><br />

                <ButtonPrimary onClick={randomWinner} className={`rounded bg-[#4d996a]`} type="button">Pick Winner</ButtonPrimary>
            </section>

        </div>
    );
};

export default RandomSelector;
