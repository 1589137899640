import React, { Suspense} from "react";
import MyRouter from "routers/index";
import { BrowserRouter } from "react-router-dom";
import './i18n';
import { useTranslation } from 'react-i18next';


const App = () => {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <BrowserRouter basename="/">
        <MyRouter />
      </BrowserRouter>
    </div>
  );
}

export default App;
