import React, { FC } from "react";
import img from "images/plus.png";

export interface NextPrevProps {
  className?: string;
  currentPage?: number;
  totalPage?: number;
  btnClassName?: string;
  onClick?: () => void;
}

const NextPrev: FC<NextPrevProps> = ({
  className = "",
  onClick = () => {},
  btnClassName = "w-10 h-10"
}) => {
  const [focus, setFocus] = React.useState<"left" | "right">("right");

  return (
    <div
      className={`nc-NextPrev relative flex items-center text-neutral-500 dark:text-neutral-400 ${className}`}
      data-nc-id="NextPrev"
      data-glide-el="controls"
    >
      <button
        className={`${btnClassName}
          border-neutral-200 dark:border-neutral-6000 rounded-full flex items-center justify-center border-2`}
        onClick={(e) => {
          e.preventDefault();
          onClick();
        }}
        title="Prev"
        data-glide-dir="<"
      >
        <img
          className={`block max-h-12`}
          src={img}
          alt="Logo"
        />
      </button>
    </div>
  );
};

export default NextPrev;
