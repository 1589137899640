import * as Sentry from '@sentry/react';
import { useMagic } from 'context/MagicProvider';
import { useUser } from 'context/UserContext';
import { useWeb3 } from 'context/Web3Context';
//import { magic } from 'libs/magic';
import qs from 'qs';
import { useCallback, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import analytics from 'utils/analytics';
import { axiosWP } from 'utils/api';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import Label from 'components/Label/Label';
import Input from 'shared/Input/Input';
import { useTranslation } from 'react-i18next';



interface ModalProps {
  isOpen: boolean;
  onClose: (event: any) => void;
}

export interface LoginWithSmsConfiguration {
  phoneNumber: string;
}

export interface LoginWithEmailOTPConfiguration {
  /**
   * Specify the email address of the user attempting to login.
   */
  email: string;
  /**
   * When `true`, a pre-built modal interface will show to the user, directing
   * them to check their email for the one time passcode (OTP) to complete their
   * authentication.
   *
   * When `false`, developers will be able to implement their own custom UI to
   * continue the email OTP flow.
   */
  showUI?: boolean;
  /**
   * Device Unrecognized UI will enforce showing up to secure user's login
   *
   * When set to true (default), an improved device recognition UI will be displayed to the user,
   * prompting them to verify their login by checking their email for device approval. This feature
   * enhances authentication security.
   *
   * This param will only be affect if showUI is false. When set to false,
   * developers have the flexibility to implement their own customized UI to
   * handle device check events, providing a more tailored user experience.
   */
  deviceCheckUI?: boolean;
  /**
   * Enterprise users with a custom SMTP can create custom email templates
   * from their dashboard. The default Magic loginWithOTP email will be
   * overriden when a variation is passed here.
   */
  overrides?: {
      variation?: string;
  };
}

  const Modal: React.FC<ModalProps> = ({ isOpen, onClose }) => {
  
    const [phone, setPhone] = useState<LoginWithSmsConfiguration>({phoneNumber:''});
  const [email, setEmail] = useState<LoginWithEmailOTPConfiguration>({email:''});
  const { t } = useTranslation();
  const [phoneError, setPhoneError] = useState(false);



  const [usePhone, setUsePhone] = useState(true);
  const [buttonText, setButtonText] = useState('Email');

  const { magic,web3 } = useMagic()
  const { fetchUser } = useUser()

  const handlePhone = (event: any) => {
    if (phoneError){ 
      setPhoneError(false);
    }
    setPhone({phoneNumber:event});
  };

  const handleEmail = (event: any) => {
    setEmail({email:event.target.value});
  };

  // Define the event handler for the button click
  const handleConnect = async (event: any) => {
    //event.target.disabled = true;
    //const accounts = await web3?.eth.getAccounts()
    //const address = accounts?.[0];
    if(localStorage.getItem('magic')=="true"){
      localStorage.setItem('magic', 'false');
      window.location.reload();
      return;
    }
      try {
        if(usePhone){
          if (!phone.phoneNumber.match(/^\+?\d{10,14}$/)) {
            setPhoneError(true);
            return;
          }else{
            setPhoneError(false);
            if(phone.phoneNumber != '') {
              const token = await magic?.auth.loginWithSMS(phone);
              await fetchUser();
            }else{
              return;
            }
          }
        }else{
            if(email.email != '') {
                await magic?.auth.loginWithEmailOTP(email);
                await fetchUser();
              }else{
                return;
              }
        }          
       
        // If connection to the wallet was successful, initialize new Web3 instance
        //initializeWeb3();

        const userInfo = await magic?.user.getInfo();
        localStorage.setItem('magic', 'true');

        const query = {
          user: userInfo?.publicAddress,
          email: userInfo?.email,
          pkey: process.env.REACT_APP_PKEY,
          phone: phone.phoneNumber
        };

        // Send Email
        const body = qs.stringify(query);
        const response = await axiosWP.post(
          '/event_creation/verify_user.php',
          body,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );

        if (response?.data?.su === 400) {
          Sentry.captureException(response?.data?.mssg);
          console.log('Error ocurred:', response?.data?.mssg);
        } else {
          analytics.track('logged_in');
        }
        //event.target.disabled = false;
        onClose(event); // Close the modal
      } catch (error) {
        // Log any errors that occur during the connection process
        console.error('handleConnect:', error);
        Sentry.captureException(error);
        //event.target.disabled = false;
      }
  };

  function authOption(){
    if(usePhone){
        setUsePhone(false);
        setButtonText('Phone Number');
    }else{
        setUsePhone(true);
        setButtonText('Email')
    }
    
  }


  return (
    <div className={`text-center min-h-screen px-1 md:px-4 modal2 ${isOpen ? 'open' : ''}`}>
      <div className="modal2-content">
        <div className="py-4 px-6 text-center relative border-b border-neutral-100 dark:border-neutral-700 md:py-5 bg-[#1F2937]">
          <span className="close" onClick={onClose}>&times;</span>
          <h3 className="text-base font-semibold text-neutral-900 lg:text-xl dark:text-neutral-200 mx-10">Login</h3>
          <span className="block text-sm mt-1 text-neutral-700 sm:text-base dark:text-neutral-200" style={{ fontSize: "12px" }}>
              {t("login-modal.subtitle")}
          </span>
        </div>

        <div className="space-x-3 m-5">
          <div className="grid grid-cols-1 rounded-md">
              <div>
                  {
                      usePhone ? 
                      <label className="block mb-2">
                          <Label>With Phone Number</Label>
                          <div>
                          <PhoneInput
                          defaultCountry="PR"
                          placeholder='Enter number'
                          value={phone.phoneNumber}
                          className="mt-1"
                          rules={{
                              required: true,
                              validate: (value: any) => isValidPhoneNumber(value),
                          }}
                          onChange={(e) => {
                              handlePhone(e);
                          }}
                          />
                          </div>
                      </label>
                      
                      :
                      <label className="block mb-2">
                          <Label>With Email</Label>
                          <div>
                          <Input
                          placeholder='Enter email'
                          type="text"
                          className="mt-1"
                          id="emailInput"
                          name="emailInput"
                          onChange={(e) => {
                              handleEmail(e);
                            }}
                          />
                          </div>
                      </label>
                  }
              <div>
              {phoneError && (
                <span className="block text-sm mt-1 text-neutral-700 sm:text-base dark:text-neutral-200" style={{ fontSize: "12px" }}>
                  Enter a valid phone number
                </span>
              )}
              <ButtonPrimary className="flex-1 w-full mt-2" onClick={handleConnect}>Login</ButtonPrimary>
             
              
               <p className="pt-2 pb-2">OR</p>
                <a onClick={authOption} className='w-full flex-1 nc-Button relative h-auto inline-flex items-center justify-center rounded-full transition-colors ttnc-ButtonSecondary border bg-white border-neutral-200 text-neutral-700 dark:bg-neutral-900 dark:text-neutral-300 dark:border-neutral-700 hover:bg-neutral-100 dark:hover:bg-neutral-800 text-sm sm:text-base font-medium px-4 py-3 sm:px-6 text-neutral-700 dark:text-neutral-200'>
                    {buttonText}
                </a>
               
             
                  </div>
                  <span className="block text-sm mt-2 sm:text-base text-red-700" style={{ fontSize: "13px" }}> 
                  </span>
              </div>
          </div>
      </div>
          
      </div>
    </div>
  );
};

const ConnectButton = () => {
  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = (event: any) => {
    event.target.disabled = true;
    setModalOpen(!modalOpen);
    event.target.disabled = false;
  };

  // Render the button component with the click event handler 
  return (
    <div>
      <ButtonPrimary id='btnLogin' onClick={toggleModal} sizeClass="px-4 py-2 sm:px-5">Login</ButtonPrimary>
      <Modal isOpen={modalOpen} onClose={toggleModal} />
    </div>
  );
};

export default ConnectButton;
