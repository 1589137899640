//import { Magic } from 'magic-sdk';
import * as Sentry from '@sentry/react';
import Cookies from 'js-cookie';
import { FC, useCallback, useEffect, useState } from 'react';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Logo from 'shared/Logo/Logo';

// Plugins
import MenuBar from 'shared/MenuBar/MenuBar';

import { Trans, useTranslation } from 'react-i18next';
import { useUser } from 'context/UserContext';
import DisconnectButton from 'components/DisconnectButton';
import ModalLogin from 'components/ModalLogin';
import ConnectButton from 'components/ConnectButton';
import AvatarDropdown from './AvatarDropdown';
//import { magic } from 'libs/magic';

export interface MainNav2LoggedProps {}

export interface SideComponent {
  isVisible: boolean;
  title?: string;
  message?: string;
}

const MainNav2Logged: FC<MainNav2LoggedProps> = () => {
  const [message, setMessage] = useState<SideComponent>({
    isVisible: false
  });
  
  const [, updateState] = useState({});

  const forceUpdate = useCallback(() => updateState({}), []);

  const [reload, setReload] = useState(true);
  const [isLogged, setIsLogged] = useState(false);

  const currentLocale = Cookies.get('i18next') || 'en';
  const [language, setLanguage] = useState(window.localStorage.i18nextLng);
  const { t, i18n } = useTranslation();

  const languages = [
    { name: 'EN', code: 'en', country_code: 'en' },
    { name: 'ES', code: 'es', country_code: 'es' },
  ];

  const handleChangeLocale = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const lang: string = e.target.value;
    setLanguage(lang);
    i18n.changeLanguage(lang);
  };

  const onSelectedNewLanguage = useCallback(() => {
    setReload(true);
  }, []);

  const handleChangeCurrency = (
    e: React.ChangeEvent<HTMLSelectElement>
  ): void => {
    const lang: string = e.target.value;
    setLanguage(lang);
    i18n.changeLanguage(lang);
    onSelectedNewLanguage();
  };

  async function getLoginState(){
    //const isLoggedIn = await magic.user.isLoggedIn();
    if(user){
      setIsLogged(true);
    }else{
      setIsLogged(false);
    }
  }

  const { user } = useUser();

  useEffect(() => {
    getLoginState();
    if(user){
      setMessage({ isVisible: false });
    }
  }, [user]);

  return (
    <div className={`nc-MainNav2Logged relative z-10 ${'onTop '}`}>
      <div className="container py-5 relative flex justify-between items-center space-x-4 xl:space-x-8">
        <div className="flex justify-start flex-grow items-center space-x-3 sm:space-x-8 lg:space-x-10">
          <Logo />
        </div>
        <div className="flex-shrink-0 flex items-center justify-end text-neutral-700 dark:text-neutral-100 space-x-1">
          <div className="switcher">
            <select
              onChange={handleChangeLocale}
              className="text-xs"
              value={language}
            >
              {languages.map(({ name, code, country_code }) => (
                <option key={code} value={code} selected={language}>
                  {name}
                </option>
              ))}
            </select>
          </div>
          <div id="walletConnecDiv" className="items-center xl:flex space-x-2">

            {!isLogged ? (
              <ConnectButton></ConnectButton>
      ) : (
        <>
          <DisconnectButton />
        </>
      )}
          </div>
          {
            isLogged ? (
              <AvatarDropdown />
            ):(
              <></>
            )
          }
          <MenuBar />
        </div>
      </div>
    </div>
  );
};

export default MainNav2Logged;
