import React, { FC, useEffect, useState } from "react";
import HeaderFilterSection from "components/HeaderFilterSection";
import Heading from "shared/Heading/Heading";
import CardNFT2 from "components/CardNFT2";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import axios from "utils/api"
import moment from "moment"
import qs from "qs"
import { useTranslation } from "react-i18next";
import CardEvents from "components/CardEvents";

export interface SectionGridFeatureNFT2Props {
  price: boolean;
}

const SectionGridFeatureNFT2: FC<SectionGridFeatureNFT2Props> = ({price}) => {

  const [events, setEvents] = useState([])

  useEffect(() => {

    const getContracts = async () => {

      const currentDay = moment().format('YYYY-MM-DD');
      const query = qs.stringify({
        fields: ['mainImage', 'price', 'eventDateTime', 'name', 'quantity', 'currency', 'id'],
        filters: {
          isActive: true,
          isHidden: false,
          eventDateTime: {
            $lt: currentDay
          }
        },
        pagination: {
          page: 1,
          pageSize: 10,
        },
        sort: ['eventDateTime:desc'],
        populate: 'mainImage'
      }, {
        encodeValuesOnly: true,
      })

      const reponse = await axios.get(`/api/contracts?${query}`);
      if (reponse.status === 200) {
        const allEvents = reponse.data.data
        setEvents(allEvents);
      } else {
        console.log("Error ocurred requesting contracts", reponse);
      }
    }

    getContracts()

  }, []);

  const { t, i18n } = useTranslation();
  return (
    <div className="nc-SectionGridFeatureNFT2 relative">

      <div className={`flex flex-col relative mb-12`}>
        <Heading>{t("header-filter-section.past-events")}</Heading>
      </div>
    
      <div className={`grid gap-6 lg:gap-8 sm:grid-cols-2 xl:grid-cols-3`}>
        {events.map((event, index) => (
          <CardEvents showPrice={price} event={event} key={index} />
        ))}
      </div>
      {/* <div className="flex mt-16 justify-center items-center">
        <ButtonPrimary loading>Show me more</ButtonPrimary>
      </div> */}
    </div>
  );
};

export default SectionGridFeatureNFT2;
