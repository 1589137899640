export interface StateProps {
  provider: string | null;
  email: string;
}

export default function wallet(
  state: StateProps = {
    provider: null,
    email: '',
  },
  action: any
) {
  switch (action.type) {
    case 'SET_WALLET':
      let {key = '', value = ''} = {...action}
      return {
        ...state,
        [`${key}`]: value
      }
    case 'SET_WALLET_MULTI':
      return {...state, ...action}
    default:
      return state
  }
}
