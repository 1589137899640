import { FC } from 'react';

export interface PagePrivacyPolicyProps {
  className?: string;
}

const PagePrivacyPolicy: FC<PagePrivacyPolicyProps> = ({ className = '' }) => {
  return (
    <div
      className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
      data-nc-id="PagePrivacyPolicy"
    >
      <header className="text-center max-w-2xl mx-auto my-20">
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          POLÍTICA DE PRIVACIDAD
        </h2>
        <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
          Última actualización: 26 de agosto de 2023
        </span>
      </header>
      <section className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
        <p>
          Esta Política de privacidad describe Nuestras políticas y
          procedimientos sobre la recopilación, el uso y la divulgación de Su
          información cuando utiliza el Servicio y le informa sobre Sus derechos
          de privacidad y cómo la ley lo protege.
        </p>
        <p>
          Usamos sus datos personales para proporcionar y mejorar el Servicio.
          Al usar el Servicio, acepta la recopilación y el uso de información de
          acuerdo con esta Política de privacidad. Esta Política de privacidad
          se ha creado con la ayuda del{' '}
          <a
            href="https://www.termsfeed.com/privacy-policy-generator/"
            target="_blank"
            rel="noreferrer"
          >
            Generador de políticas de privacidad de TermsFeed
          </a>
          .
        </p>
        <br></br>
        <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Interpretación y Definiciones
        </h1>
        <br></br>
        <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Interpretación
        </h2>
        <br></br>
        <p>
          Las palabras cuya letra inicial está en mayúscula tienen significados
          definidos bajo las siguientes condiciones. Las siguientes definiciones
          tendrán el mismo significado independientemente de que aparezcan en
          singular o en plural.
        </p>
        <br></br>
        <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Definiciones
        </h2>
        <br></br>
        <p>A los efectos de esta Política de Privacidad:</p>
        <ul>
          <li>
            <p>
              <strong>Cuenta</strong> significa una cuenta única creada para que
              Usted acceda a nuestro Servicio o partes de nuestro Servicio.
            </p>
          </li>
          <li>
            <p>
              <strong>Afiliado</strong> significa una entidad que controla, es
              controlada o está bajo el control común de una parte, donde
              &quot;control&quot; significa la propiedad del 50% o más de las
              acciones, participación accionaria u otros valores con derecho a
              voto para la elección de directores u otra autoridad
              administrativa.
            </p>
          </li>
          <li>
            <p>
              <strong>Compañía</strong> (referido como "la Compañía",
              "Nosotros", "Nosotros" o "Nuestro" en este Acuerdo) se refiere a
              dBloks LLC, 100 Calle Alondra Unidad 30951 San Juan PR 00924.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies</strong> son pequeños archivos que un sitio web
              coloca en su computadora, dispositivo móvil o cualquier otro
              dispositivo, y que contienen los detalles de su historial de
              navegación en ese sitio web entre sus múltiples usos.
            </p>
          </li>
          <li>
            <p>
              <strong>País</strong> se refiere a: Puerto Rico
            </p>
          </li>
          <li>
            <p>
              <strong>Dispositivo</strong> significa cualquier dispositivo que
              pueda acceder al Servicio, como una computadora, un teléfono
              celular o una tableta digital.
            </p>
          </li>
          <li>
            <p>
              <strong>Información personal</strong> es cualquier información que
              se relaciona con un individuo identificado o identificable.
            </p>
          </li>
          <li>
            <p>
              <strong>Servicio</strong> hace referencia al Sitio Web.
            </p>
          </li>
          <li>
            <p>
              <strong>Proveedor de servicio</strong> significa cualquier persona
              física o jurídica que procesa los datos en nombre de la Compañía.
              Se refiere a empresas de terceros o personas empleadas por la
              Empresa para facilitar el Servicio, proporcionar el Servicio en
              nombre de la Empresa, realizar servicios relacionados con el
              Servicio o ayudar a la Empresa a analizar cómo se utiliza el
              Servicio.
            </p>
          </li>
          <li>
            <p>
              <strong>Servicio de redes sociales de terceros</strong> rse
              refiere a cualquier sitio web o cualquier sitio web de red social
              a través del cual un Usuario puede iniciar sesión o crear una
              cuenta para usar el Servicio.
            </p>
          </li>
          <li>
            <p>
              <strong>Datos de uso</strong> se refiere a los datos recopilados
              automáticamente, ya sea generados por el uso del Servicio o de la
              propia infraestructura del Servicio (por ejemplo, la duración de
              una visita a la página).
            </p>
          </li>
          <li>
            <p>
              <strong>Sitio web</strong> se refiere a dBloks, accesible desde{' '}
              <a
                href="https://marketplace.dbloks.com/"
                rel="external nofollow noopener noreferrer"
                target="_blank"
              >
                https://marketplace.dbloks.com/
              </a>
            </p>
          </li>
          <li>
            <p>
              <strong>Tú</strong> significa la persona que accede o utiliza el
              Servicio, o la empresa u otra entidad legal en nombre de la cual
              dicha persona accede o utiliza el Servicio, según corresponda.
            </p>
          </li>
        </ul>
        <br></br>
        <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Recopilación y uso de sus datos personales
        </h1>
        <br></br>
        <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Tipos de datos recopilados
        </h2>
        <br></br>
        <h3>Información personal</h3>
        <p>
          Mientras usa Nuestro Servicio, podemos pedirle que nos proporcione
          cierta información de identificación personal que se puede usar para
          contactarlo o identificarlo. La información de identificación personal
          puede incluir, entre otros:
        </p>
        <ul>
          <li>
            <p>Dirección de correo electrónico</p>
          </li>
          <li>
            <p>Nombre y apellido</p>
          </li>
          <li>
            <p>Número de teléfono</p>
          </li>
          <li>
            <p>Dirección, estado, provincia, código postal, ciudad</p>
          </li>
          <li>
            <p>Datos de uso</p>
          </li>
        </ul>
        <h3>Datos de so</h3>
        <p>
          Los Datos de salvia se recopilan automáticamente cuando se utiliza el
          Servicio.
        </p>
        <p>
          Los datos de uso pueden incluir información como la dirección del
          protocolo de Internet de su dispositivo (por ejemplo, la dirección
          IP), el tipo de navegador, la versión del navegador, las páginas de
          nuestro Servicio que visita, la hora y la fecha de su visita, el
          tiempo dedicado a esas páginas, dispositivo único identificadores y
          otros datos de diagnóstico.
        </p>
        <p>
          Cuando accede al Servicio a través de un dispositivo móvil, podemos
          recopilar cierta información automáticamente, que incluye, entre
          otros, el tipo de dispositivo móvil que utiliza, la identificación
          única de su dispositivo móvil, la dirección IP de su dispositivo
          móvil, su sistema operativo, el tipo de navegador de Internet móvil
          que utiliza, identificadores únicos de dispositivos y otros datos de
          diagnóstico.
        </p>
        <p>
          También podemos recopilar información que su navegador envía cada vez
          que visita nuestro Servicio o cuando accede al Servicio a través de un
          dispositivo móvil.
        </p>
        <h3>Información de servicios de redes sociales de terceros</h3>
        <p>
          La Compañía le permite crear una cuenta e iniciar sesión para usar el
          Servicio a través de los siguientes Servicios de redes sociales de
          terceros:
        </p>
        <ul>
          <li>Google</li>
          <li>Facebook</li>
          <li>Twitter</li>
          <li>LinkedIn</li>
        </ul>
        <p>
          Si decide registrarse o concedernos acceso a un Servicio de redes
          sociales de terceros, podemos recopilar datos personales que ya están
          asociados con la cuenta de Su Servicio de redes sociales de terceros,
          como Su nombre, Su dirección de correo electrónico, Sus actividades. o
          Su lista de contactos asociada con esa cuenta.
        </p>
        <p>
          También puede tener la opción de compartir información adicional con
          la Compañía a través de la cuenta de su Servicio de redes sociales de
          terceros. Si elige proporcionar dicha información y Datos personales,
          durante el registro o de otra manera, le está dando permiso a la
          Compañía para usarla, compartirla y almacenarla de manera consistente
          con esta Política de privacidad.
        </p>
        <h3>Tecnologías de seguimiento y cookies</h3>
        <p>
          Usamos Cookies y tecnologías de seguimiento similares para rastrear la
          actividad en Nuestro Servicio y almacenar cierta información. Las
          tecnologías de seguimiento utilizadas son balizas, etiquetas y scripts
          para recopilar y rastrear información y para mejorar y analizar
          Nuestro Servicio. Las tecnologías que utilizamos pueden incluir:
        </p>
        <ul>
          <li>
            <strong>Cookies o Cookies de Navegador.</strong> Una cookie es un
            pequeño archivo colocado en su dispositivo. Puede indicar a su
            navegador que rechace todas las cookies o que indique cuándo se
            envía una cookie. Sin embargo, si no acepta las Cookies, es posible
            que no pueda utilizar algunas partes de nuestro Servicio. A menos
            que haya ajustado la configuración de su navegador para que rechace
            las Cookies, nuestro Servicio puede usar Cookies.
          </li>
          <li>
            <strong>Web Beacons.</strong> Ciertas secciones de nuestro Servicio
            y nuestros correos electrónicos pueden contener pequeños archivos
            electrónicos conocidos como beacons web (también denominados gifs
            transparentes, etiquetas de píxeles y gifs de un solo píxel) que
            permiten a la Compañía, por ejemplo, contar los usuarios que han
            visitado esas páginas. o abrió un correo electrónico y para otras
            estadísticas relacionadas con el sitio web (por ejemplo, registrar
            la popularidad de una determinada sección y verificar la integridad
            del sistema y del servidor).
          </li>
        </ul>
        <p>
          Las cookies pueden ser &quot;Persistentes&quot; o &quot;Sesión&quot;
          Galletas. Las cookies persistentes permanecen en su computadora
          personal o dispositivo móvil cuando se desconecta, mientras que las
          cookies de sesión se eliminan tan pronto como cierra su navegador web.
          Puede obtener más información sobre las cookies en{' '}
          <a
            href="https://www.termsfeed.com/blog/cookies/#What_Are_Cookies"
            target="_blank"
            rel="noreferrer"
          >
            Terminos de la web Termsfeed
          </a>
        </p>
        <p>
          Utilizamos cookies de sesión y persistentes para los fines
          establecidos a continuación:
        </p>
        <ul>
          <li>
            <p>
              <strong>Cookies necesarias/esenciales</strong>
            </p>
            <p>Tipo: Cookies de sesión</p>
            <p>Administrado por: Nosotros</p>
            <p>
              Propósito: estas cookies son esenciales para brindarle los
              servicios disponibles a través del sitio web y permitirle usar
              algunas de sus funciones. Ayudan a autenticar a los usuarios y
              previenen el uso fraudulento de cuentas de usuario. Sin estas
              Cookies, no se pueden proporcionar los servicios que ha
              solicitado, y solo utilizamos estas Cookies para proporcionarle
              esos servicios.
            </p>
          </li>
          <li>
            <p>
              <strong>
                Política de Cookies / Aviso de Aceptación de Cookies
              </strong>
            </p>
            <p>Tipo: Cookies persistentes</p>
            <p>Administrado por: Nosotros</p>
            <p>
              Finalidad: Estas Cookies identifican si los usuarios han aceptado
              el uso de cookies en el Sitio Web.
            </p>
          </li>
          <li>
            <p>
              <strong>Cookies de funcionalidad</strong>
            </p>
            <p>Tipo: Cookies persistentes</p>
            <p>Administrado por: Nosotros</p>
            <p>
              Propósito: estas cookies nos permiten recordar las elecciones que
              hace cuando usa el sitio web, como recordar sus datos de inicio de
              sesión o su preferencia de idioma. El propósito de estas Cookies
              es brindarle una experiencia más personal y evitar que tenga que
              volver a ingresar sus preferencias cada vez que use el sitio web.
            </p>
          </li>
        </ul>
        <p>
          Para obtener más información sobre las cookies que utilizamos y sus
          opciones con respecto a las cookies, visite nuestra Política de
          cookies o la sección Cookies de nuestra Política de privacidad.
        </p>
        <br></br>
        <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Uso de sus datos personales
        </h2>
        <br></br>
        <p>
          La Compañía puede utilizar los Datos Personales para los siguientes
          fines:
        </p>
        <ul>
          <li>
            <p>
              <strong>Para proporcionar y mantener nuestro Servicio</strong>,
              incluso para monitorear el uso de nuestro Servicio.
            </p>
          </li>
          <li>
            <p>
              <strong>Para administrar su cuenta:</strong> para gestionar Su
              registro como usuario del Servicio. Los Datos Personales que
              proporcione pueden darle acceso a diferentes funcionalidades del
              Servicio que están disponibles para Usted como usuario registrado.
            </p>
          </li>
          <li>
            <p>
              <strong>Para la ejecución de un contrato:</strong> el desarrollo,
              cumplimiento y realización del contrato de compra de los
              productos, artículos o servicios que haya adquirido o de cualquier
              otro contrato con Nosotros a través del Servicio.
            </p>
          </li>
          <li>
            <p>
              <strong>Para contactarte:</strong> Para contactarlo por correo
              electrónico, llamadas telefónicas, SMS u otras formas equivalentes
              de comunicación electrónica, como notificaciones automáticas de
              una aplicación móvil sobre actualizaciones o comunicaciones
              informativas relacionadas con las funcionalidades, productos o
              servicios contratados, incluidas las actualizaciones de seguridad,
              cuando sea necesario o razonable para su implementación.
            </p>
          </li>
          <li>
            <p>
              <strong>Para proporcionarle</strong> con noticias, ofertas
              especiales e información general sobre otros bienes, servicios y
              eventos que ofrecemos que son similares a los que ya compró o
              preguntó, a menos que haya optado por no recibir dicha
              información.
            </p>
          </li>
          <li>
            <p>
              <strong>Para gestionar sus solicitudes:</strong> Para atender y
              gestionar Sus solicitudes hacia Nosotros.
            </p>
          </li>
          <li>
            <p>
              <strong>Para transmisiones de empresas:</strong> Podemos usar Su
              información para evaluar o realizar una fusión, desinversión,
              reestructuración, reorganización, disolución u otra venta o
              transferencia de algunos o todos Nuestros activos, ya sea como una
              empresa en marcha o como parte de una quiebra, liquidación o
              procedimiento similar. en el que los Datos personales que tenemos
              sobre los usuarios de nuestro Servicio se encuentran entre los
              activos transferidos.
            </p>
          </li>
          <li>
            <p>
              <strong>Para otros fines</strong>: Podemos utilizar su información
              para otros fines, como el análisis de datos, la identificación de
              tendencias de uso, la determinación de la eficacia de nuestras
              campañas promocionales y para evaluar y mejorar nuestro Servicio,
              productos, servicios, marketing y su experiencia.
            </p>
          </li>
        </ul>
        <p>
          Podemos compartir su información personal en las siguientes
          situaciones:
        </p>
        <ul>
          <li>
            <strong>Con proveedores de servicios:</strong> Podemos compartir Su
            información personal con Proveedores de Servicios para monitorear y
            analizar el uso de nuestro Servicio, para contactarlo.
          </li>
          <li>
            <strong>Para transmisiones de empresas:</strong> Podemos compartir o
            transferir Su información personal en relación con, o durante las
            negociaciones de, cualquier fusión, venta de activos de la Compañía,
            financiamiento o adquisición de todo o una parte de Nuestro negocio
            a otra compañía.
          </li>
          <li>
            <strong>Con Afiliados:</strong> Podemos compartir Su información con
            Nuestros afiliados, en cuyo caso les exigiremos que respeten esta
            Política de privacidad. Los afiliados incluyen nuestra empresa
            matriz y cualquier otra subsidiaria, socios de empresas conjuntas u
            otras empresas que controlamos o que están bajo control común con
            nosotros.
          </li>
          <li>
            <strong>Con socios comerciales:</strong> Podemos compartir Su
            información con Nuestros socios comerciales para ofrecerle ciertos
            productos, servicios o promociones.
          </li>
          <li>
            <strong>WCon otros usuarios:</strong> cuando comparte información
            personal o interactúa de otra manera en las áreas públicas con otros
            usuarios, dicha información puede ser vista por todos los usuarios y
            puede distribuirse públicamente al exterior. Si interactúa con otros
            usuarios o se registra a través de un Servicio de redes sociales de
            terceros, Sus contactos en el Servicio de redes sociales de terceros
            pueden ver Su nombre, perfil, imágenes y descripción de Su
            actividad. De manera similar, otros usuarios podrán ver
            descripciones de Su actividad, comunicarse con Usted y ver Su
            perfil.
          </li>
          <li>
            <strong>con su consentimiento</strong>: Podemos divulgar su
            información personal para cualquier otro propósito con su
            consentimiento.
          </li>
        </ul>
        <br></br>
        <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Retención de sus datos personales
        </h2>
        <br></br>
        <p>
          La Compañía conservará sus Datos personales solo durante el tiempo que
          sea necesario para los fines establecidos en esta Política de
          privacidad. Conservaremos y utilizaremos sus datos personales en la
          medida necesaria para cumplir con nuestras obligaciones legales (por
          ejemplo, si estamos obligados a conservar sus datos para cumplir con
          las leyes aplicables), resolver disputas y hacer cumplir nuestros
          acuerdos y políticas legales.
        </p>
        <p>
          La Compañía también conservará los Datos de uso para fines de análisis
          interno. Los Datos de uso generalmente se retienen por un período de
          tiempo más corto, excepto cuando estos datos se utilizan para
          fortalecer la seguridad o mejorar la funcionalidad de Nuestro
          Servicio, o cuando estamos legalmente obligados a retener estos datos
          por períodos de tiempo más largos.
        </p>
        <br></br>
        <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Transferencia de sus datos personales
        </h2>
        <br></br>
        <p>
          Su información, incluidos los Datos personales, se procesa en las
          oficinas operativas de la Compañía y en cualquier otro lugar donde se
          encuentren las partes involucradas en el procesamiento. Significa que
          esta información puede transferirse y mantenerse en computadoras
          ubicadas fuera de Su estado, provincia, país u otra jurisdicción
          gubernamental donde las leyes de protección de datos pueden diferir de
          las de Su jurisdicción.
        </p>
        <p>
          Su consentimiento a esta Política de privacidad seguido de Su envío de
          dicha información representa Su acuerdo con esa transferencia.
        </p>
        <p>
          La Compañía tomará todas las medidas razonablemente necesarias para
          garantizar que sus datos se traten de forma segura y de acuerdo con
          esta Política de privacidad y no se realizará ninguna transferencia de
          sus datos personales a una organización o país, a menos que existan
          controles adecuados establecidos, incluida la seguridad de Sus datos y
          otra información personal.
        </p>
        <br></br>
        <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Eliminar sus datos personales
        </h2>
        <br></br>
        <p>
          Tiene derecho a eliminar o solicitar que lo ayudemos a eliminar los
          Datos personales que hemos recopilado sobre usted.
        </p>
        <p>
          Nuestro Servicio puede darle la capacidad de eliminar cierta
          información sobre Usted dentro del Servicio.
        </p>
        <p>
          Puede actualizar, modificar o eliminar su información en cualquier
          momento iniciando sesión en su cuenta, si tiene una, y visitando la
          sección de configuración de la cuenta que le permite administrar su
          información personal. También puede comunicarse con nosotros para
          solicitar acceso, corregir o eliminar cualquier información personal
          que nos haya proporcionado.
        </p>
        <p>
          Please note, however, that We may need to retain certain information
          when we have a legal obligation or lawful basis to do so.
        </p>
        <br></br>
        <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Divulgación de sus datos personales
        </h2>
        <br></br>
        <h3>Transacciones de negocios</h3>
        <p>
          Si la Compañía está involucrada en una fusión, adquisición o venta de
          activos, Sus Datos personales pueden transferirse. Le enviaremos un
          aviso antes de que sus Datos personales se transfieran y queden
          sujetos a una Política de privacidad diferente.
        </p>
        <h3>Cumplimiento de la ley</h3>
        <p>
          En determinadas circunstancias, es posible que se le solicite a la
          Compañía que divulgue sus Datos personales si así lo requiere la ley o
          en respuesta a solicitudes válidas de las autoridades públicas (por
          ejemplo, un tribunal o una agencia gubernamental).
        </p>
        <h3>Otros requisitos legales</h3>
        <p>
          La Compañía puede divulgar sus datos personales de buena fe cuando
          considere que esta acción es necesaria para lo siguiente:
        </p>
        <ul>
          <li>Cumplir con una obligación legal</li>
          <li>
            Pproteger y defender los derechos o la propiedad de la Compañía
          </li>
          <li>
            Prevenir o investigar posibles irregularidades en relación con el
            Servicio
          </li>
          <li>
            Proteger la seguridad personal de los Usuarios del Servicio o del
            público
          </li>
          <li>Pproteger contra la responsabilidad legal</li>
        </ul>
        <br></br>
        <h2 className="flex items-center text-3xl leading-[115%] md:text-2xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Seguridad de sus datos personales
        </h2>
        <br></br>
        <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Europa - Principio de responsabilidad proactiva
        </h2>
        <br></br>
        <p>
          Para el cumplimiento de los “Principio de responsabilidad proactiva",
          la corporación en adelante dBloks LLC garantiza la aplicación del
          Artículo 5 recogido en el RGPD. Para el cumplimiento “seguridad del
          tratamiento” de los datos personales. La corporación en adelante
          dBloks LLC garantiza la aplicación del Artículo 32 EU de RGDP. La
          corporación en adelante dBloks LLC garantiza el cumplimiento de las
          trasferencias de datos personales a terceros países u organizaciones
          internacionales, acuerdo al cumplimiento de la norma de “Transferencia
          basadas en una decisión de adecuación” que legitima a las entidades y
          corporaciones receptoras de datos se encuentren en un país, u
          territorio o uno o varios sectores específicos de ese país u
          organización declarado de nivel de protección adecuado. Enlace de
          verificación
          https://ec.europa.eu/commission/presscorner/detail/en/ip_23_3721{' '}
        </p>
        <p>
          La seguridad de sus datos personales es importante para nosotros, pero
          recuerde que ningún método de transmisión por Internet o método de
          almacenamiento electrónico es 100 % seguro. Si bien nos esforzamos por
          utilizar medios comercialmente aceptables para proteger sus datos
          personales, no podemos garantizar su seguridad absoluta.
        </p>
        <br></br>
        <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Privacidad de los niños
        </h1>
        <br></br>
        <p>
          Nuestro Servicio no se dirige a ninguna persona menor de 13 años. No
          recopilamos a sabiendas información de identificación personal de
          ninguna persona menor de 13 años. Si usted es un padre o tutor y sabe
          que su hijo nos ha proporcionado Datos personales, por favor
          Contáctenos. Si nos damos cuenta de que hemos recopilado Datos
          personales de cualquier persona menor de 13 años sin verificación del
          consentimiento de los padres, tomamos medidas para eliminar esa
          información de Nuestros servidores.
        </p>
        <p>
          Si necesitamos basarnos en el consentimiento como base legal para
          procesar su información y su país requiere el consentimiento de uno de
          sus padres, podemos solicitar el consentimiento de su padre antes de
          recopilar y usar esa información.
        </p>
        <br></br>
        <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Enlaces a otros sitios web
        </h1>
        <br></br>
        <p>
          Nuestro Servicio puede contener enlaces a otros sitios web que no son
          operados por Nosotros. Si hace clic en el enlace de un tercero, será
          dirigido al sitio de ese tercero. Le recomendamos encarecidamente que
          revise la Política de privacidad de cada sitio que visite.
        </p>
        <p>
          No tenemos control ni asumimos ninguna responsabilidad por el
          contenido, las políticas de privacidad o las prácticas de los sitios o
          servicios de terceros.
        </p>
        <br></br>
        <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Cambios a esta Política de Privacidad
        </h1>
        <br></br>
        <p>
          Es posible que actualicemos nuestra Política de privacidad de vez en
          cuando. Le notificaremos cualquier cambio publicando la nueva Política
          de Privacidad en esta página.
        </p>
        <p>
          Le informaremos por correo electrónico y/o un aviso destacado en
          Nuestro Servicio, antes de que el cambio entre en vigencia y
          actualizaremos la &quot;Última actualización&quot; fecha en la parte
          superior de esta Política de privacidad.
        </p>
        <p>
          Se le recomienda revisar esta Política de Privacidad periódicamente
          para cualquier cambio. Los cambios a esta Política de privacidad son
          efectivos cuando se publican en esta página.
        </p>
        <br></br>
        <h1 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100">
          Contacta con nosotros
        </h1>
        <br></br>
        <p>
          Si tiene alguna pregunta sobre esta Política de privacidad, puede
          contactarnos:
        </p>
        <ul>
          <li>Por correo electrónico: nft@dbloks.com</li>
        </ul>
      </section>
    </div>
  );
};

export default PagePrivacyPolicy;
