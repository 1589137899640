import CardLarge1 from "components/CardLarge1/CardLarge1";
import React, { FC, useState, useEffect } from "react";
import analytics from "utils/analytics";
import axios from "utils/api"
import moment from "moment"
import qs from "qs"

export interface SectionLargeSliderProps {
  className?: string;
}

export interface Event {
  id: number;
  attributes: {
    name: string;
    price: number;
    currency: string;
    date: string;
    quantity: number;
    eventDateTime: string;
    mainImage: {
      data: {
        attributes: {
          url: string;
        }
      }
    };
  };
}

const SectionLargeSlider: FC<SectionLargeSliderProps> = ({
  className = "",
}) => {

  const [slide, setSlide] = useState([])
  const [indexActive, setIndexActive] = useState(0);

  useEffect(() => {

    const getEvents = async () => {

      const currentDay = moment().format('YYYY-MM-DD');
      const query = qs.stringify({
        fields: ['mainImage', 'price', 'name','currency', 'id'],
        filters: {
          isFeatured: true,
          isActive: true,
          isHidden: false,
          eventDateTime: {
            $gt: currentDay
          }
        },
        sort: ['eventDateTime:asc'],
        populate: 'mainImage'
      }, {
        encodeValuesOnly: true,
      })

      const reponse = await axios.get(`/api/contracts?${query}`);
      if (reponse.status === 200) {

        var newSlide: any = [];

        const allEvents = reponse.data.data
        allEvents.forEach((event: any) => newSlide.push(event));

        setSlide(newSlide);
        
      } else {
        console.log("Error ocurred requesting contracts", reponse);
      }
    }

    getEvents()

  }, []);

  const handleClickNext = () => {
    analytics.track('selected_next_home_large_slider');
    setIndexActive((indexActive + 1) % slide.length);
  };

  const handleClickPrev = () => {
    analytics.track('selected_prev_home_large_slider');
    setIndexActive((indexActive + slide.length - 1) % slide.length);
  };

  if (!slide.length) {
    return (null)
  }

  return (
    <div className="bg-neutral-100/70 dark:bg-black/20 py-20 lg:py-32">
      <div className="container">
        <div className={`nc-SectionLargeSlider relative ${className}`}>
          {slide.map((event: any, index: number) =>
            indexActive === index ? (
              <CardLarge1
                key={index}
                isShowing
                featuredImgUrl={event.attributes.mainImage.data ? event.attributes.mainImage.data.attributes.url : ''}
                onClickNext={handleClickNext}
                onClickPrev={handleClickPrev}
                event={event}
              />
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

export default SectionLargeSlider;
