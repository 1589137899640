import { CheckIcon } from "@heroicons/react/solid";
import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface PageDeckProps {
    className?: string;
}

const PageDeck: FC<PageDeckProps> = ({ className = "" }) => {

    return (
        <div
            className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
            data-nc-id="PageDeck"
        >
            <header className="text-center max-w-2xl mx-auto my-20">
                <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    Our Pitch Deck
                </h2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                    Learn more about our product and its features
                </span>
            </header>
            <div className="deck-container">
                <div style={{ position: "relative" }}>
                    <div style={{
                        position: "relative", width: "100%", height: "100%", paddingTop: "56.2500%", paddingBottom: "0", boxShadow: "0 2px 8px 0 rgba(63,69,81,0.16)"
                        , overflow: "hidden", borderRadius: "8px", willChange: "transform"
                    }}>
                        <iframe loading="lazy" style={{ position: "absolute", width: "100%", height: "100%", top: "0", left: "0", border: "none", padding: "0", margin: "0" }}
                            src="https://www.canva.com/design/DAFmhNplnlg/view?embed" allowFullScreen allow="fullscreen">
                        </iframe>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PageDeck;
