import { useMagic } from "context/MagicProvider"
import { useUser } from "context/UserContext"
//import { useWeb3 } from "context/Web3Context"
import { useState } from "react"
//import { magic } from "libs/magic"
import ButtonPrimary from "shared/Button/ButtonPrimary"


const DisconnectButton = () => {
  // Get the initializeWeb3 function from the Web3 context
  //const { initializeWeb3 } = useWeb3()
  const [isLoading, setIsLoading] = useState(false)

  const { magic } = useMagic()
  const { fetchUser } = useUser()

  // Define the event handler for the button click
  const handleDisconnect = async () => {
    try {
      setIsLoading(true);

      // Try to disconnect the user's wallet using Magic's logout method
      await magic?.user.logout();
      await fetchUser();
      localStorage.setItem('magic', 'false');

      // After successful disconnection, re-initialize the Web3 instance
      //initializeWeb3()
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      // Log any errors that occur during the disconnection process
      console.log("handleDisconnect:", error)
    }
  }

  // Render the button component with the click event handler
  return <ButtonPrimary
  onClick={handleDisconnect}
  sizeClass="px-4 py-2 sm:px-5"
>
  {isLoading ? "Disconnecting..." : "Logout"}
</ButtonPrimary>
}

export default DisconnectButton