import React, { FC, useState } from "react";
import ButtonCircle from "shared/Button/ButtonCircle";
import rightImg from "images/SVG-subcribe2.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import Input from "shared/Input/Input";
import { ArrowSmRightIcon } from "@heroicons/react/outline";
import { axiosWP } from "utils/api";
import qs from "qs";
import analytics from "utils/analytics";
import * as Sentry from '@sentry/react';

export interface SectionSubscribe2Props {
  className?: string;
}

const SectionSubscribe2: FC<SectionSubscribe2Props> = ({ className = "" }) => {

  const [email, setEmail] = useState('');
  const [messageResponse, setmessageResponse] = useState('');

  const handleChange = (event: { target: { value: React.SetStateAction<string>; }; }) => {
    setEmail(event.target.value);
  };

   async function handleClick(){

    if(email == ""){
      setmessageResponse("Email can't be empty");
      return;
    }

    const query = {
      pkey: process.env.REACT_APP_PKEY,
      email: email
    };

    // Send Email
    const body = qs.stringify(query);
    const response = await axiosWP.post(
      '/event_creation/user_subscription.php',
      body,
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
      }
    );

    if (response?.data?.su === 400) {
      Sentry.captureException(response?.data?.mssg);
      console.log('Error ocurred:', response?.data?.mssg);
    } else if(response?.data?.su === 200) {
      setmessageResponse(response?.data?.mssg);
      console.log("subscribed");
    }else{
      setmessageResponse("Thanks for subscribing");
      analytics.track('user_subscription');
    }
  }

  return (
    <div
      className={`nc-SectionSubscribe2 relative flex flex-col lg:flex-row lg:items-center ${className}`}
      data-nc-id="SectionSubscribe2"
    >
      <div className="flex-shrink-0 mb-10 lg:mb-0 lg:mr-10 lg:w-2/5">
        <h2 className="font-semibold text-4xl">Never miss an event!</h2>
        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          Subcribe to our super-exclusive list and be the first to know
          about upcoming events
        </span>
       {/*  <ul className="space-y-4 mt-10">
          <li className="flex items-center space-x-4">
            <Badge name="01" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get more discount
            </span>
          </li>
          <li className="flex items-center space-x-4">
            <Badge color="red" name="02" />
            <span className="font-medium text-neutral-700 dark:text-neutral-300">
              Get premium magazines
            </span>
          </li>
        </ul>
        */}
        <div className="mt-10 relative max-w-sm">
        <Input
            required
            aria-required
            placeholder="Enter your email"
            type="email"
            rounded="rounded-full"
            onChange={handleChange}
            value={email}
          />
          <ButtonCircle
            type="submit"
            className="absolute transform top-1/2 -translate-y-1/2 right-1"
            onClick={handleClick}
          >
            <ArrowSmRightIcon className="w-6 h-6" />
          </ButtonCircle>
        </div>

        <span className="block mt-5 text-neutral-500 dark:text-neutral-400">
          {messageResponse}
        </span>
      </div>
      <div className="flex-grow">
        <NcImage src="https://res.cloudinary.com/cloudbloks/image/upload/v1700021728/CONFIRMED_1_cbckfg.png" />
      </div>
    </div>
  );
};

export default SectionSubscribe2;
