import React, { FC, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { Helmet } from "react-helmet";
import { useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Textarea from "shared/Textarea/Textarea";
import Comment from "shared/Comment/Comment";
import EmojiPicker, { EmojiClickData, SuggestionMode } from 'emoji-picker-react';
import { axiosWP } from "utils/api";
import * as Sentry from '@sentry/react';
import qs from "qs";
import analytics from "utils/analytics";
import { useUser } from "context/UserContext";
import { magic } from "libs/magic";
import GifPicker, { TenorImage } from 'gif-picker-react';






export interface PageConnectWalletProps {
  className?: string;
}

export interface Comments {
  name?: string;
  date?: string;
  comment?: string;
  gifUrl?: string;
  gifWidth?: number;
  gifHeight?: number;
  username?: string;
  image?: string;
}

export interface GifPreview {
  height?: number;
  url?: string;
  width?: number;
}

function useQuery() {
  return new URLSearchParams(useLocation().search);
}

const PageSuccessRegistration: FC<PageConnectWalletProps> = ({ className = "" }) => {
  const { t, i18n } = useTranslation();
  const [emoji, setEmoji] = useState(false);
  const [gif, setGif] = useState<GifPreview>();

  const [gifTenor, setGifTenor] = useState(false);
  
  const [messageResponse, setmessageResponse] = useState('');
  const [commentData, setCommentData] = useState<Comments[]>([]);
  const [userSubmit, setUserSubmit] = useState('');
  const [totalComments, setTotalComments] = useState(0);
  const [loadingStyle, setLoadingStyle] = useState("hidden");

  const { user } = useUser();

  let query = useQuery();
  let transactionUrl = query.get('transactionUrl') ? query.get('transactionUrl') : "";
  let event = query.get('event') ? query.get('event') : "";
  let name = query.get('name') ? query.get('name') : "";

  function setEmojiOption() {
    if(emoji) {
      setEmoji(false);
    }
    else {
      setEmoji(true);
      setGifTenor(false);
    }
  }

  function setGifOption() {
    if(gifTenor) {
      setGifTenor(false);
    }
    else {
      setGifTenor(true);
      setEmoji(false);
    }
  }
  

  useEffect(() => {
    setLoadingStyle("flex");
    loadComments();
    window.scrollTo(0, 0);
  }, [userSubmit,user]);

  async function loadComments(){
    if (user) {
      const userInfo = await magic.user.getInfo();
      if(event == ""){
        setmessageResponse("Event can't be empty");
        return;
      }
  
      const query = {
        pkey: process.env.REACT_APP_PKEY,
        event: event,
        user: userInfo.publicAddress,
        chain:process.env.REACT_APP_OPEN_LOGIN_NETWORK,
      };
  
      const body = qs.stringify(query);
      const response = await axiosWP.post(
        '/event_creation/retrieve_event_comment.php',
        body,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );
  
      if (response?.data?.su === 200) {
        analytics.track('event_comment_retrieved');
        setLoadingStyle("hidden");
        setCommentData(response?.data?.result);
        setTotalComments(response?.data?.result.length);
      } else{
        Sentry.captureException(response?.data?.mssg);
        console.log('Error ocurred:', response?.data?.mssg);
        setLoadingStyle("hidden");
        alert(response?.data?.mssg);
      }
    }
  }

  async function submitComment() {
    if (user) {
      var commentArea = document.getElementById("commentText") as HTMLTextAreaElement;
      const userInfo = await magic.user.getInfo();

      if(commentArea.value == "" && !gif?.url){
        setmessageResponse("Comment can't be empty");
        return;
      }

      if(event == ""){
        setmessageResponse("Event can't be empty");
        return;
      }

      if(name == ""){
        setmessageResponse("Name can't be empty");
        return;
      }
      setEmoji(false);
      setGifTenor(false);

      const query = {
        pkey: process.env.REACT_APP_PKEY,
        user: userInfo.publicAddress,
        event: event,
        name: name,
        gif: gif,
        comment: commentArea.value,
        chain:process.env.REACT_APP_OPEN_LOGIN_NETWORK,

      };

      const body = qs.stringify(query);
      const response = await axiosWP.post(
        '/event_creation/event_comment.php',
        body,
        {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded',
          },
        }
      );

      if (response?.data?.su === 200) {
        analytics.track('event_comment');
        setUserSubmit(gif?.url ? gif?.url : commentArea.value);
        commentArea.value = "";
        setGif({});
      } else{
        Sentry.captureException(response?.data?.mssg);
        console.log('Error ocurred:', response?.data?.mssg);
      }
    }
  }

  function getClickedEmoji(emojiData: EmojiClickData) {
    var commentArea = document.getElementById("commentText") as HTMLTextAreaElement;
    commentArea.value = commentArea.value + emojiData.emoji;
  }

  function getClickedGif(ternorData: TenorImage) {
    setGif(ternorData.preview);
    setGifTenor(false);
  }

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Comments ({totalComments})
        </h3>
        <div className="nc-SingleCommentForm mt-5">
          {gif?.url && <div className="flex justify-center items-center mb-2">
            <img
                src={gif?.url}
                width={gif?.width}
                height={gif?.height}
                alt="Selected GIF"
            />
          </div>
          }
          <Textarea placeholder="Hype up the comment section" className="h-10" id="commentText" />
          <div className="mt-2 space-x-3 text-right">
          <a onClick={setGifOption}><img style={{width:'24px', cursor: 'pointer', display:'inherit'}} src="https://res.cloudinary.com/cloudbloks/image/upload/v1705374968/gif_1_p1li65.png"></img></a>
          <a onClick={setEmojiOption}><img style={{width:'24px', cursor: 'pointer', display:'inherit'}} src="https://res.cloudinary.com/cloudbloks/image/upload/v1705190484/pngwing.com_1_np1hfy.png"></img></a>
            <ButtonPrimary onClick={submitComment}>Submit</ButtonPrimary>
          </div>

          {
            gifTenor && 
            <GifPicker clientKey="tiike" onGifClick={getClickedGif} tenorApiKey={"AIzaSyDnyAlZwkmc3e6avDOU5dW4agnvwlDbGmI"} height="400px" width="18em" />
          }
          {
            emoji && 
            <EmojiPicker style={{marginLeft:'auto', marginRight:'0',marginTop:'7px', zIndex:'1'}} suggestedEmojisMode={SuggestionMode.RECENT} onEmojiClick={getClickedEmoji} searchDisabled={true} height="400px" width="18em" />
            }
        </div>
      </div>
    );
  };

  const renderCommentLists = () => {

    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          {
            commentData.map((comment, index) => {
              index++;
              return <li key={"li" + index}><Comment key={"comment" + index} name={comment.name} text={comment.comment} created={comment.date} gifUrl={comment.gifUrl} gifWidth={comment.gifWidth} gifHeight={comment.gifHeight}  userName={comment.username} image={comment.image} /></li>
             })
          }
        </ul>
      </div>
    );
  };

  return (
    <div
      className={`nc-PageConnectWallet ${className}`}
      data-nc-id="PageSuccessRegistration"
    >
      <Helmet>
        <title>{t("ntf-process-order.registration-successful")}</title>
      </Helmet>
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-3xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              {t("ntf-process-order.registration-successful")}
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              {t("ntf-process-order.registration-message")}
            </span>
            
            {/*
              transactionUrl ?
              <span className="block mt-8 text-neutral-500 dark:text-neutral-400">
                <h3>{t("ntf-process-order.blockchain-receipt")}</h3>
                <a
                  className="text-neutral-6000 dark:text-neutral-300 hover:text-black dark:hover:text-white"
                  style={{wordBreak: "break-word"}}
                  target={'_blank'}
                  href={transactionUrl}
                >
                  {transactionUrl}
                </a>
              </span> : null
            */}
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          {/*<div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
            <div className="pt-2 ">
              <ButtonPrimary href={"/my-collectibles"} className="flex-1">
                <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path
                    d="M9.57 5.92993L3.5 11.9999L9.57 18.0699"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                  <path
                    d="M20.5 12H3.67004"
                    stroke="currentColor"
                    strokeWidth="1.5"
                    strokeMiterlimit="10"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>

                <span className="ml-2">{t("ntf-process-order.see-your-collectibles")}</span>
              </ButtonPrimary>
            </div>
          </div>*/}

          {
            <div className={`items-center justify-center ${loadingStyle}`}>
              <svg className="animate-spin text-green-500" width="60px" height="60px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path fillRule="evenodd" clipRule="evenodd" d="M13.917 7A6.002 6.002 0 0 0 2.083 7H1.071a7.002 7.002 0 0 1 13.858 0h-1.012z"/>
              </svg> 
            </div>
          }

          {user? renderCommentForm() : null}
        {user? renderCommentLists(): null}
        </div>
      </div>
    </div>
  );
};

export default PageSuccessRegistration;
