import React, { FC, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";

export interface ModalDeleteProps {
  show: boolean;
  onCloseModalDelete: () => void;
  props?: {
    title?: string,
    payment?: boolean
  }
}

const ModalSimple: FC<ModalDeleteProps> = ({ show, onCloseModalDelete, props }) => {

  const [frontStyle, setfrontStyle] = useState("");
  const [backStyle, setbackStyle] = useState("");

  function toggleClasses() {

    setfrontStyle("animate");
    setbackStyle("animate");
  }
  
  setTimeout(function() {
    
    toggleClasses();
    
    setInterval(function() {
  
      toggleClasses();
    }, 3400/2);
  }, 3400/4);

  const renderContent = () => {

    return (
      <form action="#">
        {
          props?.payment ? 
          <div className="flex items-center justify-center">
          {/* <svg className="animate-spin text-green-500" width="60px" height="60px" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" fill="currentColor"><path fillRule="evenodd" clipRule="evenodd" d="M13.917 7A6.002 6.002 0 0 0 2.083 7H1.071a7.002 7.002 0 0 1 13.858 0h-1.012z"/>
            </svg> */
            }
            <div className="credit-card">
            <div className={`front animate ${frontStyle}`}>
              <i className="box"></i>
              <i className="logo"></i>
              <span className="number">1234 5602 1204 3452</span>
            </div>
            <div className={`back animate ${backStyle}`}>
              <i className="scan"></i>
              <i className="info"></i>
            </div>
          </div>
          </div>
          :
          <div className="flex items-center justify-center">
            <div className="ticket-container">
              <div className="item-container">
                <img className="item" src="https://res.cloudinary.com/cloudbloks/image/upload/v1700529681/tciket_zpczda.png"></img>
                <img className="item" src="https://res.cloudinary.com/cloudbloks/image/upload/v1700529681/tciket_zpczda.png"></img>
                <img className="item" src="https://res.cloudinary.com/cloudbloks/image/upload/v1700529681/tciket_zpczda.png"></img>
                <img className="item" src="https://res.cloudinary.com/cloudbloks/image/upload/v1700529681/tciket_zpczda.png"></img>
                <img className="item" src="https://res.cloudinary.com/cloudbloks/image/upload/v1700529681/tciket_zpczda.png"></img>
              </div>
              <div className="card">
                <img width="55px" height="auto" src="https://res.cloudinary.com/cloudbloks/image/upload/v1700523777/Color-Logo-no-background_tgrazi.png"></img>
              </div>
            </div>
          </div>
        }
        
       
       {/* <div className="flex items-center justify-center mt-2">
          Powered by <img className="w-1/5 content-center ml-2" style={{minWidth:"125px"}} src="https://assets.website-files.com/635a63404bede087240e8c38/635dda49482d4a2eb40968ae_Biconomy%20White%20Horizontal%20Lockup.svg" alt=""></img>
        </div>
        */}

      </form>
    )
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalDelete}
      contentExtraClass="max-w-screen-sm"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle={props?.title ? props?.title : ""}
    />
  );
};

export default ModalSimple;