import React, { FC } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import NcModal from "shared/NcModal/NcModal";

export interface ModalDeleteProps {
  show: boolean;
  onCloseModalDelete: () => void;
  props?: {
    title?: string,
    message?: string
  }
}

const ModalSimple: FC<ModalDeleteProps> = ({ show, onCloseModalDelete, props }) => {

  const renderContent = () => {
    return (
      <form action="#">
        { props?.title ? 
          <h3 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200 border-none">
            {props.title}
          </h3> : null
        }
        { props?.message ? 
          <span className="text-sm">
            {props.message}
          </span> : null
        }
        <div className="mt-4 space-x-3">
          <ButtonSecondary type="button" onClick={onCloseModalDelete}>
            Cancel
          </ButtonSecondary>
        </div>
      </form>
    );
  };

  const renderTrigger = () => {
    return null;
  };

  return (
    <NcModal
      isOpenProp={show}
      onCloseModal={onCloseModalDelete}
      contentExtraClass="max-w-screen-sm"
      renderContent={renderContent}
      renderTrigger={renderTrigger}
      modalTitle=""
    />
  );
};

export default ModalSimple;