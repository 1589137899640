import { Magic } from "magic-sdk"
import * as Sentry from '@sentry/react';
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react"
const { Web3 } = require("web3")


/*type MagicContextType = {
  magic: Magic | null
  web3: typeof Web3 | null
}

const MagicContext = createContext<MagicContextType>({
  magic: null,
  web3: null,
});*/

type MagicContextType = {
  magic: Magic | null
  web3: typeof Web3 | null
  switchBase: () => void
  switchPolygon: () => void
  network: NetworkType
}

const MagicContext = createContext<MagicContextType>({
  magic: null,
  web3: null,
  switchBase: () => {},
  switchPolygon: () => {},
  network: 'polygon'
})


type NetworkType = 'polygon' | 'base';

export const useMagic = () => useContext(MagicContext)

const MagicProvider = ({ children }: { children: ReactNode }) => {
  const [magic, setMagic] = useState<Magic | null>(null)
  const [web3, setWeb3] = useState<typeof Web3 | null>(null)
  const [network, setNetwork] = useState<NetworkType>('polygon')

  useEffect(() => {
    /*if (process.env.REACT_APP_MAGICLINK_KEY) {
        try{
            const magic = new Magic(process.env.REACT_APP_MAGICLINK_KEY || "", {
                network: {
                    // @ts-ignore
                    rpcUrl: process.env.REACT_APP_RPC_TARGET,
                    // @ts-ignore
                    chainId: process.env.REACT_APP_CHAIN_ID,
                },
              })
        
              setMagic(magic)
              setWeb3(new Web3((magic as any).rpcProvider))
        } catch (error) {
            // Log any errors that occur during the connection process
            Sentry.captureException(error);
        }
    }*/

    const initializeMagic = (network: NetworkType) => {
      try{
        let magicInstance: Magic;
        if(network === 'polygon'){
          magicInstance = new Magic(process.env.REACT_APP_MAGICLINK_KEY || "", {
            network: {
                // @ts-ignore
                rpcUrl: process.env.REACT_APP_RPC_TARGET,
                // @ts-ignore
                chainId: process.env.REACT_APP_CHAIN_ID,
            },
          });
        }else{
          magicInstance = new Magic(process.env.REACT_APP_MAGICLINK_KEY || "", {
            network: {
                // @ts-ignore
                rpcUrl: process.env.REACT_APP_RPC_TARGET_BASE,
                // @ts-ignore
                chainId: process.env.REACT_APP_CHAIN_ID_BASE,
            },
          });
        }

        setMagic(magicInstance);
        setWeb3(new Web3((magicInstance as any).rpcProvider));

      }catch (error) {
        // Log any errors that occur during the connection process
        Sentry.captureException(error);
     }
    };

    initializeMagic(network)
  }, [network])

  const switchBase = () => {
    setNetwork('base')
  }

  const switchPolygon = () => {
    setNetwork('polygon')
  }

  const value = useMemo(() => {
    return {
      magic,
      web3,
      switchBase,
      switchPolygon,
      network
    }
  }, [magic, web3, network])

  return <MagicContext.Provider value={value}>{children}</MagicContext.Provider>
}

export default MagicProvider

