import React, { FC } from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import NcImage from "shared/NcImage/NcImage";
import { nftsImgs } from "contains/fakeData";
import ItemTypeImageIcon from "./ItemTypeImageIcon";
import LikeButton from "./LikeButton";
import Prices from "./Prices";
import { ClockIcon } from "@heroicons/react/outline";
import ItemTypeVideoIcon from "./ItemTypeVideoIcon";

export interface CardUserEventsProps {
  className?: string;
  isLiked?: boolean;
  event: {
    id: number;
    attributes: {
      name: string;
      quantity: number;
      eventDateTime: string;
      mainImage: {
        data: {
          attributes: {
            url: string;
          }
        }
      };
    };
  };
}

const CardUserEvents: FC<CardUserEventsProps> = ({ className = "", isLiked,event }) => {
    const attributes = event.attributes;
    const image = attributes.mainImage.data ? attributes.mainImage.data.attributes.url : ''
  return (
    <div
      className={`nc-CardNFT relative flex flex-col group !border-0 [ nc-box-has-hover nc-dark-box-bg-has-hover ] ${className}`}
      data-nc-id="CardUserEvents"
    >
      <div className="relative flex-shrink-0 ">
        <div>
          <NcImage
            containerClassName="flex aspect-w-11 aspect-h-12 w-full h-0 rounded-3xl overflow-hidden z-0"
            src={image}
            className="object-cover w-full h-full group-hover:scale-[1.03] transition-transform duration-300 ease-in-out will-change-transform"
          />
        </div>
        <div className="absolute top-3 inset-x-3 flex"></div>
      </div>

      <div className="p-4 py-5 space-y-3">
        <h2 className={`text-lg font-medium`}>
        {attributes.name}
        </h2>
      </div>

      <Link to={"/event/" + event.id} className="absolute inset-0"></Link>
    </div>
  );
};

export default CardUserEvents;
