import React, { FC } from "react";
import { NavLink } from "react-router-dom";
import ButtonPrimary, { ButtonPrimaryProps } from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface FollowButtonProps extends ButtonPrimaryProps {
  isFollowing?: boolean;
  eventID?: string
}

const FollowButton: FC<FollowButtonProps> = ({
  className = "relative z-10",
  sizeClass = "px-4 py-1.5 min-w-[84px]",
  fontSize = "text-sm font-medium",
  isFollowing,
  eventID
}) => {
  const [following, setFollowing] = React.useState(isFollowing);

  return following ? (
    <NavLink to={"/gallery/"+eventID}>
      <ButtonPrimary
        className={className}
        sizeClass={sizeClass}
        fontSize={fontSize}
      >
        Gallery
      </ButtonPrimary>
    </NavLink>
    
  ) : (
    <></>
  );
};

export default FollowButton;
