import axios from "axios";

const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || '';
const apiKey = process.env.REACT_APP_API_KEY || '';

const axiosInstance = axios.create({
  baseURL: apiEndpoint,
  headers: {
      Authorization: "Bearer " + apiKey,
      Accept: "application/json",
      "Content-Type": "application/json"
  }
});

export const axiosWP = axios.create({
  baseURL: 'https://lamp.dbloks.com',
  headers: {
      "Content-Type": "application/x-www-form-urlencoded"
  }
});

export default axiosInstance;