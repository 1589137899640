import mixpanel from 'mixpanel-browser';

const debug = process.env.REACT_APP_NODE_ENV === 'production' ? false : true
const apiKey = process.env.REACT_APP_MIXPANEL_API_KEY || ''
mixpanel.init(apiKey, {debug}); 

let analytics = {
  track: (name: string, props?: any) => {
    mixpanel.track(name, props);
  },
  identify: (id: string) => {
    mixpanel.identify(id);
  },
  alias: (id: string) => {
    mixpanel.alias(id);
  },
  people: {
    set: (props: any) => {
        mixpanel.people.set(props);
    },
  },
};

export default analytics;