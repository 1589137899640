import React from "react";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Helmet } from "react-helmet";
import SectionLargeSlider from "./SectionLargeSlider";
import SectionHero2 from "components/SectionHero/SectionHero2";
import SectionGridFeatureNFT2 from "./SectionGridFeatureNFT2";
import SectionGridPastNFT from "./SectionGridPastNFT";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      <Helmet>
        <title>tiike Marketplace</title>
      </Helmet>
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />

      <div className="container relative mt-12 mb-20 sm:mb-24 sm:mt-20 lg:mb-32">
        {/* SECTION HERO */}
        <SectionHero2 />

        {/* SECTION 2 */}
        {/* <SectionHowItWork className="mt-24 lg:mt-40 xl:mt-48" /> */}
      </div>

      {/* SECTION LAERGE SLIDER */}
      <SectionLargeSlider />

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">

        {/* SECTION */}
      <SectionGridFeatureNFT2 />

      </div>

      <div className="container relative space-y-24 my-24 lg:space-y-32 lg:my-32">

        {/* SECTION */}
        <div className="relative py-20 lg:py-28">
          <BackgroundSection className="bg-neutral-100/70 dark:bg-black/20 " />
          <SectionGridPastNFT price={false} />
        </div>

      </div>
    </div>
  );
}

export default PageHome;
