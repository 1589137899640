import { FC, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import ButtonPrimary from "shared/Button/ButtonPrimary";
//For api calls
import axios from "axios";

//Imports for wallet connect
import qs from "qs";
import { useAppSelector } from "app/hooks";
import { ethers } from "ethers";
import { useUser } from "context/UserContext";
//import { magic } from "libs/magic";
import { axiosWP } from "utils/api";
import * as Sentry from '@sentry/react';
import { useMagic } from "context/MagicProvider";

//For rainbow wallet
let allRedeemed = true;

const delay = (ms: number | undefined) => new Promise(res => setTimeout(res, ms));

export interface PageSubcriptionProps {
    className?: string;
}

const MyTikey: FC<PageSubcriptionProps> = ({ className = "" }) => {
    const [headerText, setHeaderText] = useState("");
    const [availableStyle, setAvailableStyle] = useState("block");
    const [loadingStyle, setloadingStyle] = useState("none");
    const [authenticateStyle, setAuthenticateStyle] = useState("block");
    const [retrieveResponse, setRetrieveResponse] = useState(0);
    const [imgSrc, setimgSrc] = useState("");
    const [retrieveFound, setRetrieveFound] = useState([]);
    const [retrieveMssg, setRetrieveMssg] = useState("");
    const [connectedWalletAddress, setconnectedWalletAddress] = useState("");
    const buttonRef = useRef(null);
    const { t, i18n } = useTranslation();
    const { user } = useUser();
    const { magic } = useMagic()


    //***************************************** */

    useEffect(() => {
        displayCollectibles();
    },[user])

    async function displayCollectibles(){
        try{
            //const isLoggedIn = await magic.user.isLoggedIn();
            if (user) {
                //const userInfo = await magic?.user.getInfo();
    
                const body = qs.stringify({
                    user: user?.address,
                    pkey: process.env.REACT_APP_PKEY
                });
    
                setAuthenticateStyle("hidden");
                setloadingStyle("flex");
    
                const response = await axiosWP.post(
                    '/event_creation/verify_wallet.php',
                    body,
                    {
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                      },
                    }
                  );
    
                setRetrieveResponse(response?.data?.su);
    
                if (response?.data?.su == 200) {
                    setimgSrc(response?.data?.mssg);
                    setloadingStyle("none");
                } else {
                    setRetrieveMssg(response?.data?.mssg);
                    setloadingStyle("none");
                    setAuthenticateStyle("hidden");
                }
            }else{
                setRetrieveResponse(0);
                setAuthenticateStyle("block");
            }      
        }catch(error: any){
            Sentry.captureException(error);
            window.location.reload();
        }
    }

    async function authenticate() {
        try{
            //const isLoggedIn = await magic.user.isLoggedIn();
            if (user) {
                //const userInfo = await magic?.user.getInfo();
    
                const body = qs.stringify({
                    user: user?.address,
                    pkey: process.env.REACT_APP_PKEY
                });
    
                setAuthenticateStyle("hidden");
                setloadingStyle("flex");
    
                const response = await axiosWP.post(
                    '/event_creation/verify_wallet.php',
                    body,
                    {
                      headers: {
                        'Content-Type': 'application/x-www-form-urlencoded',
                      },
                    }
                  );
    
                setRetrieveResponse(response?.data?.su);
    
                if (response?.data?.su == 200) {
                    setimgSrc(response?.data?.mssg);
                    setloadingStyle("none");
                } else {
                    setRetrieveMssg(response?.data?.mssg);
                    setloadingStyle("none");
                    setAuthenticateStyle("hidden");
                }
            } else {
                if(localStorage.getItem('magic')=="true"){
                    localStorage.setItem('magic', 'false');
                    window.location.reload();
                    return;
                  }else{
                    let wcbutton: HTMLElement = (document.getElementById("walletConnecDiv")?.childNodes[0] as HTMLElement)?.children[0] as HTMLElement;
                    wcbutton.click();
                    return;
                  }
            }
        }catch(error: any){
            Sentry.captureException(error);
            window.location.reload();
        }
    }

    return (
        <div
            className={`nc-PageSubcription container pb-24 lg:pb-32 ${className}`}
            data-nc-id="MyTikey"
        >
            <header className="text-center max-w-2xl mx-auto mt-20 mb-0">
                <h2 className="flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
                    {t("user-profile.my-tikey")}
                </h2>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200">
                </span>
                <span className="block text-sm mt-2 text-neutral-700 sm:text-base dark:text-neutral-200" style={{ fontSize: "12px" }}>
                    {t("user-profile.tikey-notice")}
                </span>
            </header>
            <section className="text-neutral-600 text-sm md:text-base overflow-hidden" style={{ textAlign: "center" }}>

                <div className="loader" style={{ display: loadingStyle }}>
                    <div className="loading"></div>
                </div><br></br>

                {retrieveResponse == 0 ? (
                    <></>
                ) : retrieveResponse == 200 ? (
                        <div className="ulNftResults">
                            <img className="singleImg" src={"data:image/png;base64,"+imgSrc}></img><br></br>
                        </div>
                    ) : (
                        <div className="ulNftResults">
                            <img className="singleImg" src="https://dbloks.com/wp-content/themes/neve-child/images/failed.png"></img><br></br>
                            <p style={{ color: "white" }}>{retrieveMssg}</p>
                        </div>
                    )
                }
                <ButtonPrimary onClick={authenticate} className={`rounded bg-[#4d996a] ${authenticateStyle}`} type="button">{t("user-profile.see-tikey")}</ButtonPrimary><br />
            </section>
        </div>
    );
};

export default MyTikey;
