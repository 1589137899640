import React from "react";
import { Link } from "react-router-dom";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Comment from "shared/Comment/Comment";
import NcImage from "shared/NcImage/NcImage";
import SocialsList from "shared/SocialsList/SocialsList";
import Textarea from "shared/Textarea/Textarea";
import { Helmet } from "react-helmet";
import { _getImgRd, _getPersonNameRd, _getTitleRd } from "contains/fakeData";

const BlogRebrand = () => {
  const renderHeader = () => {
    return (
      <header className="container rounded-xl">
        <div className="max-w-screen-md mx-auto space-y-5">
          <Badge href="##" color="blue" name="Business" />
          <h1
            className=" text-neutral-900 font-semibold text-3xl md:text-4xl md:!leading-[120%] lg:text-4xl dark:text-neutral-100 max-w-4xl "
            title="Quiet ingenuity: 120,000 lunches and counting"
          >
            dBloks Reimagined: Embracing a New Identity
          </h1>
          <span className="block text-base text-neutral-500 md:text-lg dark:text-neutral-400 pb-1">
          We are on a mission, and it's committed to blending technology and entertainment while ensuring simplicity, security, and a personalized touch.
          </span>

          <div className="w-full border-b border-neutral-100 dark:border-neutral-800"></div>
          <div className="flex flex-col items-center sm:flex-row sm:justify-between">
            <div className="nc-PostMeta2 flex items-center flex-wrap text-neutral-700 text-left dark:text-neutral-200 text-sm leading-none flex-shrink-0">
              <Avatar
                containerClassName="flex-shrink-0"
                sizeClass="w-8 h-8 sm:h-11 sm:w-11 "
                imgUrl="https://res.cloudinary.com/cloudbloks/image/upload/v1698097599/DSC05453_1_td2qnc.jpg"
              />
              <div className="ml-3">
                <div className="flex items-center">
                  <a
                    className="block font-semibold"
                    href="/ncmaz/author/the-demo-author-slug"
                  >
                    Gian Irizarry
                  </a>
                </div>
                <div className="text-xs mt-[6px]">
                  <span className="text-neutral-700 dark:text-neutral-300">
                    Oct 30, 2023
                  </span>
                  <span className="mx-2 font-semibold">·</span>
                  <span className="text-neutral-700 dark:text-neutral-300">
                    5 min read
                  </span>
                </div>
              </div>
            </div>
            <div className="mt-3 sm:mt-1.5 sm:ml-3">
              <SocialsList />
            </div>
          </div>
        </div>
      </header>
    );
  };

  const renderContent = () => {
    return (
      <div
        id="single-entry-content"
        className="prose prose-sm !max-w-screen-md sm:prose lg:prose-lg mx-auto dark:prose-invert"
      >
        <p>
        We're absolutely thrilled to bring forth an evolved dBloks, navigating from the familiar harbors into uncharted territories, coming soon at <a href="http://tiike.com" target="_blank">tiike.com</a>! This significant leap isn’t just a brand reshuffle; it’s a harmonious synthesis of who we’ve been, who we are, and who we’re metamorphosing into.
        </p>
        <h3>Building a Transparent and Fair Future</h3>
        <p>
        Our sights have perpetually been set on realizing a transparent and frictionless ticketing experience. To achieve this, dBloks recognizes that our brand should not merely reflect but exude our vision, thereby becoming an inspiration and a pillar upon which transformative solutions are built using our technology.
        </p>

        <h3>Collaborating with <a href="https://www.instagram.com/altoonsdesigns/" target="_blank">Altoons Designs</a></h3>
        <figure>
          <img
            src="https://res.cloudinary.com/cloudbloks/image/upload/v1698686632/1_juesoc.png"
            alt="nc blog"
            className="rounded-2xl"
          />
          <figcaption>
          Over the course of the past few months, we’ve mingled thoughts, sketched ideas, and blended colors with the astute minds at Altoons Designs. Our collective ambition was to carve out a design framework that not only fortifies our existing brand but also splendidly illuminates our future path, reflecting our relentless pursuit of innovative excellence.
          </figcaption>
        </figure>

        <h3>Our Values</h3>
        <p>
        tiike envisions itself as a hidden yet pervasive layer, empowering artists and consumers everywhere silently, yet significantly. Thus, the adoption of the new design intentionally underscores our core values:
        </p>

        <ol>
          <li><b>Transparency & Fairness: </b>Through the use of a new color pallet offering freshness and vibrancy while displaying transparency in the main icon image.</li>
          <figure>
          <img
            src="https://res.cloudinary.com/cloudbloks/image/upload/v1698694717/Color_Palette_dl22ca.png"
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>
          <li><b>Community & Culture: </b>
          Represented by ticket that has been used by the community for 100s of years since the Roman age through a tessera and now finally in true digital format living on the blockchain.
          </li>
          <figure>
          <img
            src="https://res.cloudinary.com/cloudbloks/image/upload/v1698697062/ticket_fold_gpjytx.png"
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>
          <li><b>Innovation & Technology: </b>
          Hidden in the two dots of the i we are subtlety using blocks representing the blockchain behind the scenes of our technology where each ticket is created without any complexity presented to the user.
          </li>
          <figure>
          <img
            src="https://res.cloudinary.com/cloudbloks/image/upload/v1698697061/dot_blockchain_jtue1s.png"
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>
        </ol>

        

        <h3>Your Digital Passport to Seamless Entertainment</h3>
        <p>
        A unified ticketing system where each ticket is represented by a unique identifier linked to the TiKEY.
        </p>

        <figure>
          <img
            src="https://res.cloudinary.com/cloudbloks/image/upload/v1698686632/2_oatygf.png"
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>

        <ul>
          <li>
          <b>Universal Access - </b>One unique digital key, the TiKEY, allows users to access any event ticketed through the tiike platform, eliminating the need for multiple tickets or QR codes.
          </li>
          <li>
          <b>Integrated Wallet - </b>The TiKEY is linked to a user's integrated wallet on the platform (on tiike), ensuring a fluid transaction experience without the typical hassles associated with Web3 wallets.
          </li>
          <li>
          <b>Secure and Immutable - </b>Leveraging blockchain technology, each TiKEY is cryptographically secure, ensuring that each user's access rights and purchase history are tamper-proof.
          </li>
          <li>
          <b>Personalized Experience - </b>The more events a user attends via tiike, the more personalized their TiKEY becomes. It can offer tailored event suggestions, early bird access, or even unique experiences based on user preferences.
          </li>
          <li>
          <b>Loyalty and Rewards - </b>Frequent users can earn rewards or exclusive perks, enhancing loyalty to the platform. This could be in the form of discounts, special access, rare merch, and/or collectibles, etc.
          </li>
        </ul>

        <figure>
          <img
            src="https://res.cloudinary.com/cloudbloks/image/upload/v1699151746/before_7_bxgwvb.png"
            alt="nc blog"
            className="rounded-2xl"
          />
        </figure>

        <h3>A Journey, Together</h3>
        <p>
        dBloks, now rejuvenated invites you, our cherished community, to be co-authors in this exciting new chapter. Our evolution, painted against the vast digital canvas, will continue to unravel, and it's your footprints, dear community, that will indelibly mark our shared journey forward.
        </p>
        <p>
        Navigate through our new website, experience our redefined identity, and continue to walk with us as we sculpt this digital age, bit by bit, block by block, into a shape that ensures value, in every conceivable form, courses freely across our digital realm. Welcome to the renewed dBloks!
        </p>
        <figure style={{display:"flex",alignItems: "center", justifyContent: "center"}}>
          <img
            src="https://res.cloudinary.com/cloudbloks/image/upload/v1698699054/Color-Logo-white-background_ru4rav.png"
            alt="nc blog"
            className="rounded-2xl"
            style={{maxHeight:"432px"}}
          />
        </figure>
      </div>
    );
  };

  const renderTags = () => {
    return (
      <div className="max-w-screen-md mx-auto flex flex-wrap">
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Garden
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Jewelry
        </a>
        <a
          className="nc-Tag inline-block bg-white text-sm text-neutral-600 py-2 rounded-lg border border-neutral-100  md:px-4 dark:bg-neutral-700 dark:border-neutral-700 hover:border-neutral-200 dark:hover:border-neutral-6000 mr-2 mb-2"
          href="##"
        >
          Tools
        </a>
      </div>
    );
  };

  const renderAuthor = () => {
    return (
      <div className="max-w-screen-md mx-auto ">
        <div className="nc-SingleAuthor flex">
          <Avatar sizeClass="w-11 h-11 md:w-24 md:h-24"  imgUrl="https://res.cloudinary.com/cloudbloks/image/upload/v1698097599/DSC05453_1_td2qnc.jpg"/>
          <div className="flex flex-col ml-3 max-w-lg sm:ml-5 space-y-1">
            <span className="text-xs text-neutral-400 uppercase tracking-wider">
              WRITEN BY
            </span>
            <h2 className="text-lg font-semibold text-neutral-900 dark:text-neutral-200">
              <a href="/ncmaz/author/the-demo-author-slug">Gian Irizarry</a>
            </h2>
            <span className="text-sm text-neutral-500 sm:text-base dark:text-neutral-300">
              Message here
              <a
                className="text-primary-6000 font-medium ml-1"
                href="https://www.linkedin.com/in/gianrizarry/"
              >
                LinkedIn
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderCommentForm = () => {
    return (
      <div className="max-w-screen-md mx-auto pt-5">
        <h3 className="text-xl font-semibold text-neutral-800 dark:text-neutral-200">
          Responses (14)
        </h3>
        <form className="nc-SingleCommentForm mt-5">
          <Textarea />
          <div className="mt-2 space-x-3">
            <ButtonPrimary>Submit</ButtonPrimary>
            <ButtonSecondary>Cancel</ButtonSecondary>
          </div>
        </form>
      </div>
    );
  };

  const renderCommentLists = () => {
    return (
      <div className="max-w-screen-md mx-auto">
        <ul className="nc-SingleCommentLists space-y-5">
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
          <li>
            <Comment />
            <ul className="pl-4 mt-5 space-y-5 md:pl-11">
              <li>
                <Comment isSmall />
              </li>
            </ul>
          </li>
        </ul>
      </div>
    );
  };

  const renderPostRelated = (_: any, index: number) => {
    return (
      <div
        key={index}
        className="relative aspect-w-3 aspect-h-4 rounded-3xl overflow-hidden group"
      >
        <Link to={"/blog-single"} />
        <NcImage
          className="object-cover w-full h-full transform group-hover:scale-105 transition-transform duration-300"
          src={_getImgRd()}
        />
        <div>
          <div className="absolute bottom-0 inset-x-0 h-1/2 bg-gradient-to-t from-black"></div>
        </div>
        <div className="flex flex-col justify-end items-start text-xs text-neutral-300 space-y-2.5 p-4">
          <Badge name="Categories" />
          <h2 className="block text-lg font-semibold text-white ">
            <span className="line-clamp-2">{_getTitleRd()}</span>
          </h2>

          <div className="flex">
            <span className="block text-neutral-200 hover:text-white font-medium truncate">
              {_getPersonNameRd()}
            </span>
            <span className="mx-1.5 font-medium">·</span>
            <span className="font-normal truncate">May 20, 2021</span>
          </div>
        </div>
        <Link to={"/blog-single"} />
      </div>
    );
  };

  return (
    <div className="nc-PageSingle pt-8 lg:pt-16 ">
      <Helmet>
        <title>dBloks Reimagined: Embracing a New Identity</title>
      </Helmet>
      {renderHeader()}
      <NcImage
        className="w-full rounded-xl"
        containerClassName="container my-10 sm:my-12 "
        src="https://res.cloudinary.com/cloudbloks/image/upload/v1699151587/before_6_cma2zq.png"
      />

      <div className="nc-SingleContent container space-y-10">
        {renderContent()}
        <div className="max-w-screen-md mx-auto border-b border-t border-neutral-100 dark:border-neutral-700"></div>
        {renderAuthor()}
      </div>
      <br></br>
    </div>
  );
};

export default BlogRebrand;