import axios from 'axios';
import FormItem from 'components/FormItem';
import Label from 'components/Label/Label';
import { useUser } from 'context/UserContext';
//import { magic } from 'libs/magic';
import qs from 'qs';
import React, { FC, useEffect, useState } from 'react';
import ReactS3Client from 'react-aws-s3-typescript';
import { Helmet } from 'react-helmet';
import { Trans, useTranslation } from 'react-i18next';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input';
import 'react-phone-number-input/style.css';
import { useHistory } from 'react-router-dom';
import ButtonPrimary from 'shared/Button/ButtonPrimary';
import Input from 'shared/Input/Input';
import Select from 'shared/Select/Select';
import Textarea from 'shared/Textarea/Textarea';
import * as Sentry from '@sentry/react';
import { useMagic } from 'context/MagicProvider';


// import S3FileUpload from 'react-s3';
export interface CreateEventProps {
  className?: string;
}

export interface SideComponent {
  loading: boolean;
  isVisible: boolean;
  title?: string;
  message?: string;
}

const S3_BUCKET = process.env.REACT_APP_AWS_BUCKET;
const REGION = process.env.REACT_APP_AWS_REGION;
const ACCESS_KEY = process.env.REACT_APP_AWS_ACCESS_KEY_ID;
const SECRET_ACCESS_KEY = process.env.REACT_APP_SECRET_ACCESS_KEY;
const S3URL = process.env.REACT_APP_S3URL;

const s3Config = {
  bucketName: S3_BUCKET,
  region: REGION,
  accessKeyId: ACCESS_KEY,
  secretAccessKey: SECRET_ACCESS_KEY,
  s3Url: S3URL,
};

const delay = (ms: number | undefined) =>
  new Promise((res) => setTimeout(res, ms));

const CreateEventPage: FC<CreateEventProps> = ({ className = '' }) => {
  const { user } = useUser();
  const { magic } = useMagic()

  // @ts-ignore
  const s3 = new ReactS3Client(s3Config);
  const [loadingStyle, setloadingStyle] = useState('none');
  const history = useHistory();
  const [message, setMessage] = useState<SideComponent>({
    isVisible: false,
    loading: false,
  });
  const [TypeInputDisplay, setInputDisplay] = useState('hidden');

  // Attrbutes to send on email
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [title, setTitle] = useState('');
  const [type, setType] = useState('');
  const [otherType, setOtherType] = useState(null);
  const [date, setDate] = useState('');
  const [quantity, setQuantity] = useState('');
  const [description, setDescription] = useState('');
  const [image, setImage] = useState<File>();

  useEffect(() => {
    const init = async () => {
      try {
        //const isLoggedIn = await magic.user.isLoggedIn();
        if (user) {
          const userInfo = await magic?.user.getInfo();
          const walletEmail = userInfo?.email;
          if (walletEmail) {
            setEmail(walletEmail);
          }
        }
      } catch (error: any) {
        Sentry.captureException(error);
        window.location.reload();
      }
    };

    init();
  });

  function loginFirst() {
    if (!user?.address) {
      if(localStorage.getItem('magic')=="true"){
        localStorage.setItem('magic', 'false');
        window.location.reload();
        return;
      }else{
        let wcbutton: HTMLElement = (document.getElementById("walletConnecDiv")?.childNodes[0] as HTMLElement)?.children[0] as HTMLElement;
        wcbutton.click();
        return;
      }
    }
  }

  const handleSubmit = async (event: React.SyntheticEvent) => {
    try {
      event.preventDefault();
      //const isLoggedIn = await magic.user.isLoggedIn();
      if (user) {
        const userInfo = await magic?.user.getInfo();

        // TODO: If no wallet is connected it should ask for wallet connection
        // @ts-ignore
        if (userInfo.email.length === 0) {
          console.log('No wallet connected');
          setMessage({
            isVisible: true,
            title: 'Connect Wallet',
            message: 'Please connect wallet in order to complete transaction.',
            loading: false,
          });
          return;
        }

        setloadingStyle('flex');

        await delay(500);

        const res: any = await s3
          .uploadFile(image!, image!.name)
          .then((data) => {
            return data;
          })
          .catch((err) => console.error(err));

        // Send Email
        const body = qs.stringify({
          email: email ? email : userInfo?.email,
          useraddress: userInfo?.publicAddress,
          name,
          phone,
          title,
          type,
          date,
          quantity,
          description,
          imageurl: res.location, // Here goes the image url after posting the image to the S3 bucket
          pkey: process.env.REACT_APP_PKEY,
        });

        console.log(body);
        const response = await axios.post(
          'https://lamp.dbloks.com/event_creation/CreateEvent.php',
          body,
          {
            headers: {
              'Content-Type': 'application/x-www-form-urlencoded',
            },
          }
        );

        if (response?.data?.su === 200) {
          console.log(
            'Event creation email sent successfully',
            response?.data?.mssg
          );
          history.push('/event-created');
        } else {
          console.log(
            'Error ocurred sending event creation email:',
            response?.data?.mssg
          );
        }

        setloadingStyle('none');
        
      }else{
        console.log(document.getElementById('walletConnecDiv'));
        let wcbutton: HTMLElement = (document.getElementById("walletConnecDiv")?.childNodes[0] as HTMLElement)?.children[0] as HTMLElement;
        wcbutton.click();
        console.log('Make sure you have a wallet connected!');
        setMessage({ isVisible: false, loading: false });
        return;
      }
    } catch (error: any) {
        Sentry.captureException(error);
        window.location.reload();
    }

    setloadingStyle('none');
  };

  const handleEmail = (event: any) => {
    console.info(event.target.value);
    setEmail(event.target.value);
  };

  const handleName = (event: any) => {
    console.info(event.target.value);
    setName(event.target.value);
  };

  const handlePhone = (event: any) => {
    console.info(event);
    setPhone(event);
  };

  const handleTitle = (event: any) => {
    console.info(event.target.value);
    setTitle(event.target.value);
  };

  const handleType = (event: any) => {
    console.info(event.target.value);
    setType(event.target.value);
  };

  const handleOtherType = (event: any) => {
    console.info(event.target.value);
    setType(event.target.value);
  };

  function validateType(event: any) {
    if (event.target.value == 'Other') {
      setInputDisplay('block');
    } else {
      setInputDisplay('hidden');
    }
  }

  const handleDate = (event: any) => {
    console.info(event.target.value);
    setDate(event.target.value);
  };

  const handleQuantity = (event: any) => {
    console.info(event.target.value);
    setQuantity(event.target.value);
  };

  const handleDescription = (event: any) => {
    console.info(event.target.value);
    setDescription(event.target.value);
  };

  const handleImage = (event: any) => {
    const file = event.target.files[0];
    console.log(file);
    const validExtensions = ['png', 'jpeg', 'jpg', 'gif'];
    var fileReader = new FileReader();
    fileReader.onloadend = function (e) {
      // @ts-ignore
      var arr = new Uint8Array(e.target.result).subarray(0, 4);
      var header = '';
      for (var i = 0; i < arr.length; i++) {
        header += arr[i].toString(16);
      }
      // Check the file signature against known types
      let type = '';
      console.log('Header: ', header);
      switch (header) {
        case '89504e47':
          type = 'image/png';
          break;
        case '47494638':
          type = 'image/gif';
          break;
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
        case 'ffd8ffdb':
          type = 'image/jpeg';
          break;
        default:
          type = 'unknown'; // Or you can use the blob.type as fallback
          break;
      }
      const fileExtension = type.split('/')[1];
      if (validExtensions.includes(fileExtension)) {
        setImage(file);
      } else {
        alert(`File type ${type} not supported`);
      }
    };
    fileReader.readAsArrayBuffer(file);
  };

  const { t, i18n } = useTranslation();

  return (
    <div
      className={`nc-PageUploadItem ${className}`}
      data-nc-id="PageUploadItem"
    >
      <Helmet>
        <title>tiike Marketplace</title>
      </Helmet>
      <div className="container">
        <div className="my-12 sm:lg:my-16 lg:my-24 max-w-4xl mx-auto space-y-8 sm:space-y-10">
          {/* HEADING */}
          <div className="max-w-2xl">
            <h2 className="text-3xl sm:text-4xl font-semibold">
              {t('create-event-form.create-new-event')}
            </h2>
            <span className="block mt-3 text-neutral-500 dark:text-neutral-400">
              {t('create-event-form.contact-info-message')}
            </span>
          </div>
          <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
          <form className="grid grid-cols-1 gap-6" onSubmit={handleSubmit}>
            <div className="mt-10 md:mt-0 space-y-5 sm:space-y-6 md:sm:space-y-8">
              {/* ---- */}
              <h3 className="text-lg sm:text-2xl font-semibold">
                {t('create-event-form.contact-information')}
              </h3>
              {/* ---- */}
              <FormItem label={t('create-event-form.fullname')}>
                <Input
                  required
                  aria-required
                  placeholder={t('create-event-form.placeholder.enter-name')}
                  type="text"
                  className="mt-1"
                  onChange={(e) => {
                    loginFirst();
                    handleName(e);
                  }}
                  onClick={loginFirst}
                />
              </FormItem>

              {/* ---- */}
              <FormItem label={t('create-event-form.email')}>
                <Input
                  required
                  aria-required
                  placeholder={t('create-event-form.placeholder.enter-email')}
                  type="email"
                  name="email"
                  className="mt-1"
                  defaultValue={email}
                  onChange={(e) => {
                    loginFirst();
                    handleEmail(e);
                  }}
                  onClick={loginFirst}
                />
              </FormItem>

              {/* ---- */}
              <FormItem label={t('create-event-form.phone-number')}>
                <PhoneInput
                  defaultCountry="PR"
                  placeholder={t('create-event-form.placeholder.enter-phone')}
                  value={phone}
                  className="mt-1"
                  rules={{
                    required: true,
                    validate: (value: any) => isValidPhoneNumber(value),
                  }}
                  onChange={(e) => {
                    loginFirst();
                    handlePhone(e);
                  }}
                />
              </FormItem>

              <h3 className="text-lg sm:text-2xl font-semibold">
                {t('create-event-form.event-information')}
              </h3>
              {/* ---- */}
              <FormItem label={t('create-event-form.event-title')}>
                <Input
                  required
                  aria-required
                  placeholder={t('create-event-form.placeholder.enter-title')}
                  type="text"
                  className="mt-1"
                  onChange={(e) => {
                    loginFirst();
                    handleTitle(e);
                  }}
                  onClick={loginFirst}
                />
              </FormItem>

              {/* ---- */}
              <FormItem label={t('create-event-form.event-type')}>
                <Select
                  onChange={(e) => {
                    loginFirst();
                    validateType(e);
                    handleType(e);
                  }}
                  onClick={loginFirst}
                >
                  <option value="">
                    {t('create-event-form.selection-items.pick-a-type')}
                  </option>
                  <option
                    value={t('create-event-form.selection-items.live-music')}
                  >
                    {t('create-event-form.selection-items.live-music')}
                  </option>
                  <option
                    value={t('create-event-form.selection-items.convention')}
                  >
                    {t('create-event-form.selection-items.convention')}
                  </option>
                  <option
                    value={t('create-event-form.selection-items.concert')}
                  >
                    {t('create-event-form.selection-items.concert')}
                  </option>
                  <option
                    value={t('create-event-form.selection-items.festival')}
                  >
                    {t('create-event-form.selection-items.festival')}
                  </option>
                  <option value={t('create-event-form.selection-items.expo')}>
                    {t('create-event-form.selection-items.expo')}
                  </option>
                  <option value={t('create-event-form.selection-items.meet')}>
                    {t('create-event-form.selection-items.meet')}
                  </option>
                  <option
                    value={t(
                      'create-event-form.selection-items.exclusive-event'
                    )}
                  >
                    {t('create-event-form.selection-items.exclusive-event')}
                  </option>
                  <option value="Other">
                    {t('create-event-form.selection-items.other')}
                  </option>
                </Select>
              </FormItem>
              <label className={`block mb-2 ${TypeInputDisplay}`}>
                <Label>
                  {t('create-event-form.selection-items.other-type')}
                </Label>

                <Input
                  required={TypeInputDisplay == 'block' ? true : false}
                  name="otherType"
                  placeholder={t('create-event-form.selection-items.other')}
                  type="text"
                  className="mt-1"
                  id="newTypeInput"
                  onChange={(e) => {
                    loginFirst();
                    handleOtherType(e);
                  }}
                  onClick={loginFirst}
                />
              </label>

              {/* ---- */}
              <FormItem label={t('create-event-form.event-date')}>
                <Input
                  required
                  type="datetime-local"
                  className="mt-1"
                  onChange={(e) => {
                    loginFirst();
                    handleDate(e);
                  }}
                  onClick={loginFirst}
                />
              </FormItem>

              {/* ---- */}
              <FormItem label={t('create-event-form.number-of-tickets')}>
                <Input
                  required
                  aria-required
                  placeholder=""
                  type="number"
                  className="mt-1"
                  min={10}
                  max={10000}
                  onChange={(e) => {
                    loginFirst();
                    handleQuantity(e);
                  }}
                  onClick={loginFirst}
                />
              </FormItem>

              {/* ---- */}
              <FormItem
                label={t('create-event-form.event-description')}
                desc={
                  <div>
                    <Trans i18nKey="create-event-form.description-message"></Trans>{' '}
                    {/* <span className="text-green-500">Markdown</span> syntax is
                  supported. */}
                  </div>
                }
              >
                <Textarea
                  rows={6}
                  className="mt-1.5"
                  placeholder="..."
                  onChange={(e) => {
                    loginFirst();
                    handleDescription(e);
                  }}
                  onClick={loginFirst}
                />
              </FormItem>

              <div>
                <h3 className="text-lg sm:text-2xl font-semibold">
                  {t('create-event-form.pick-your-nft-art')}
                </h3>
                <div className="mt-5 ">
                  <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-neutral-300 dark:border-neutral-6000 border-dashed rounded-xl">
                    <div className="space-y-1 text-center">
                      <svg
                        className="mx-auto h-12 w-12 text-neutral-400"
                        stroke="currentColor"
                        fill="none"
                        viewBox="0 0 48 48"
                        aria-hidden="true"
                      >
                        <path
                          d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                          strokeWidth="2"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                        ></path>
                      </svg>
                      <div className="flex text-sm text-neutral-6000 dark:text-neutral-300">
                        <label
                          htmlFor="file-upload"
                          className="relative cursor-pointer  rounded-md font-medium text-primary-6000 hover:text-primary-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-primary-500"
                        >
                          <span>
                            {t('create-event-form.drag-and-drop.upload-file')}
                          </span>
                          <input
                            required
                            aria-required
                            id="file-upload"
                            name="file-upload"
                            type="file"
                            className="sr-only"
                            accept="image/*"
                            onChange={(e) => {
                              loginFirst();
                              handleImage(e);
                            }}
                            onClick={loginFirst}
                          />
                        </label>
                        <p className="pl-1">
                          {t(
                            'create-event-form.drag-and-drop.or-drag-and-drop'
                          )}{' '}
                          {image?.name}
                        </p>
                      </div>
                      <p className="text-xs text-neutral-500 dark:text-neutral-400">
                        {t('create-event-form.drag-and-drop.file-max-size')}
                      </p>
                    </div>
                  </div>
                </div>
              </div>

              <div className="w-full border-b-2 border-neutral-100 dark:border-neutral-700"></div>
              <div className="loader" style={{ display: loadingStyle }}>
                <div className="loading"></div>
              </div>
              <br></br>

              {/* ---- */}
              <div className="pt-2 flex flex-col sm:flex-row space-y-3 sm:space-y-0 space-x-0 sm:space-x-3 ">
                <ButtonPrimary className="flex-1">
                  {t('create-event-form.submit-event')}
                </ButtonPrimary>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default CreateEventPage;
