import React, { FC, useState, useEffect } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import { useAppSelector } from "app/hooks";
import LikeSaveBtns from "./LikeSaveBtns";
import EventTime from "./EventTime";
import ModalSimple from "components/ModalSimple";
import ModalLoading from "components/ModalLoading";
import axios from "axios";
import * as Sentry from "@sentry/react";
import Plus from "shared/PlusMinus/Plus";
import Minus from "shared/PlusMinus/Minus";
import { useHistory, useParams } from "react-router-dom";
import Input from "shared/Input/Input";
import AccordionInfo from "./AccordionInfo";
import { loadStripe, StripeElementsOptions } from '@stripe/stripe-js';
import { useTranslation } from "react-i18next";
import {
  CardElement,
  Elements,
  useStripe,
  useElements
} from '@stripe/react-stripe-js';
import { ethers } from "ethers";
import qs from 'qs';
import Label from "components/Label/Label";
import Swal from 'sweetalert2'


const apiEndpoint = process.env.REACT_APP_API_ENDPOINT || '';
const apiKey = process.env.REACT_APP_API_KEY || '';
const axiosInstance = axios.create({
  baseURL: apiEndpoint,
  headers: {
    Authorization: "Bearer " + apiKey,
    Accept: "application/json",
    "Content-Type": "application/json"
  }
});

const stripePublicKey = process.env.REACT_APP_STRIPE_PUBLIC_KEY || '';
const stripePromise = loadStripe(stripePublicKey);

export interface NftDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

export interface SideComponent {
  loading: boolean;
  isVisible: boolean;
  title?: string;
  message?: string;
}

export interface CheckoutFormType {
  onError: (value: string | undefined) => void;
  onSubmit: (value: string | undefined) => void;
}

interface MintResult {
  error?: string;
  hash?: string;
  txn?: {
    hash: string
  };
  formatedTokenIds?: [number];
}

export interface Contract {
  abi: object;
  name: string;
  price: number;
  address: string;
  network: string;
  askEmail: boolean;
  description: string;
  eventDateTime: string;
  networkScanner: string;
  municipalityTaxPercent: number;
  stateTaxPercent: number;
  mainImage: {
    data: {
      attributes: {
        url: string;
      }
    }
  };
}

interface SmartContractEvent {
  event: string
  args: {
    tokenId: object
  }
}

interface PaymentOptionsProps {
  contract: Contract;
  price: number;
}

interface CheckoutProps {
  contract: Contract;
  id: string;
}

interface PageParams {
  id: string;
}

const mintTicket = async (biconomy: any, address: string, abi: any): Promise<MintResult> => {
  try {

    const provider = new ethers.providers.Web3Provider(biconomy);
    const { chainId } = await provider.getNetwork();
    if (chainId != 80001 && chainId != 137) {
      console.log("User is in incorred network");
      return { error: 'Your wallet is in a incorrect network. Please change wallet network to polygon network.' };
    }

    console.log("Started smart contract mint...");

    const signer = provider.getSigner();
    const connectedContract = new ethers.Contract(address, abi, signer);
    const txn = await connectedContract.mint();

    console.log("Miting...please wait.");

    const txReceipt = await txn.wait();

    const mintEvents = txReceipt.events.filter((event: SmartContractEvent) => event.event === "Transfer");
    const tokenIds = mintEvents.map((mintEvent: SmartContractEvent) => mintEvent.args.tokenId)
    const formatedTokenIds = tokenIds.map((tokenId: any) => tokenId.toNumber())

    return { txn, formatedTokenIds }

  } catch (error: any) {
    console.error(error)
    Sentry.captureException(error);

    if (error.reason === 'execution reverted: Sale is not active') {
      throw Error('Sale is not active')
    }

    throw (error)
  }
}

function formatPrice(price: number) {
  return price / 100
}

function calculateTax(contract: Contract, total: number) {

  var stateTax = 0
  if (contract.stateTaxPercent) {
    stateTax = parseInt(((contract.stateTaxPercent / 100) * total).toFixed(0)); // change this for math module for more accuracy
  }

  var municipalityTax = 0;
  if (contract.municipalityTaxPercent) {
    municipalityTax = parseInt(((contract.municipalityTaxPercent / 100) * total).toFixed(0));
  }

  var price = stateTax + municipalityTax;
  return price;
}

const style = {
  color: 'blue',
  backgroundImage: '',
};

const stripeStyleOptions = {
  style: {
    base: {
      iconColor: '#c4f0ff',
      color: '#fff',
      fontWeight: '500',
      fontFamily: 'Roboto, Open Sans, Segoe UI, sans-serif',
      fontSize: '16px',
      fontSmoothing: 'antialiased',
      ':-webkit-autofill': {
        color: '#fce883',
      },
      '::placeholder': {
        color: '#4cc3ff',
      },
    },
    invalid: {
      iconColor: '#FFC7EE',
      color: '#FFC7EE',
    },
  },
};

const BuyButton = (contract: Contract) => {
  const { t, i18n } = useTranslation();
  return (
    <div>
      <div className="mt-8 flex flex-col sm:flex-row space-y-2 sm:space-y-0 sm:space-x-3">
        <ButtonPrimary
          className="flex-1"
        >
          <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
            <path
              d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
            <path
              d="M7 12H14"
              stroke="currentColor"
              strokeWidth="1.5"
              strokeLinecap="round"
              strokeLinejoin="round"
            />
          </svg>
          <span className="ml-2.5">
            {
              contract.price === 0 ? "Register for event" : "Buy Ticket"
            }
          </span>
        </ButtonPrimary>
      </div>
    </div>
  )
}

const CardOption = () => {
  return (
    <div>
      <div className="my-4 border-none">
        <div>
          <p>Enter card information</p>
          <CardElement options={stripeStyleOptions} className="block w-full border-neutral-200 focus:border-primary-300 focus:ring focus:ring-primary-200 focus:ring-opacity-50 bg-white dark:border-neutral-700 dark:focus:ring-primary-6000 dark:focus:ring-opacity-25 dark:bg-neutral-900 disabled:bg-neutral-200 dark:disabled:bg-neutral-800 rounded-full h-11 px-4 py-3 shadow-lg border-0 dark:border" />
        </div>
      </div>
    </div>
  )
}

const EmailOption = () => {

  const email = useAppSelector((state) => state.wallet.email);

  return (
    <div>
      <div className="my-4 border-none">
        <p>Enter Email Address</p>
        <Input
          required
          aria-required
          placeholder="Enter your email"
          type="email"
          rounded="rounded-full"
          name="email"
          defaultValue={email}
        />
      </div>
    </div>
  )
}

const PaymentOptions: React.FC<PaymentOptionsProps> = ({ contract, price }) => {

  if (!contract.address) {
    return (
      <div>
        <div className="my-4 border-none">
          <p>Loading...</p>
        </div>
      </div>
    )
  }

  if (contract.price === 0) {
    return (
      <div>
        {
          contract.askEmail ?
            <EmailOption /> : null
        }
      </div>
    )
  }


  return (
    <div>

      {
        contract.askEmail ?
          <EmailOption /> : null
      }

      <CardOption />

      {/* ---------- 7 ----------  */}
      {/* PRICE */}

      <div className="pb-9 pt-14">
        <div className="flex flex-col sm:flex-row items-baseline p-6 border-2 border-green-500 rounded-xl relative w-30">
          <span className="absolute bottom-full translate-y-1 py-1 px-1.5 bg-white dark:bg-neutral-900 text-sm text-neutral-500 dark:text-neutral-400">
            Price
          </span>
          <span className="text-3xl xl:text-4xl font-semibold text-green-500">
            ${price}
          </span>
        </div>
      </div>
    </div>
  )
}

const Checkout: React.FC<CheckoutProps> = ({ contract, id }) => {

  const [basePrice, setBasePrice] = useState(contract.price);
  const [price, setPrice] = useState(formatPrice(basePrice));
  const [paymentIntentId, setPaymentIntentId] = useState("");
  const [clientSecret, setClientSecret] = useState("pi_3LWyDlA7z1eMDaeK0sR79qAX_secret_FsDs3uMvNivwijCjBEQxOHiu2");

  const providerWeb3Auth = useAppSelector((state) => state.wallet.provider);
  const biconomy = useAppSelector((state) => state.wallet.biconomy);
  const walletemail = useAppSelector((state) => state.wallet.email);


  useEffect(() => {

    // Get payment intent
    const fetchData = async () => { // this request is redundant as main request is main in primary component. Issue with component update

      var { data: response1 } = await axiosInstance.get('/api/contracts/' + id);
      var contract = response1.data.attributes

      var tax = calculateTax(contract, contract.price);
      var price = parseInt(contract.price) + tax;

      setPrice(formatPrice(price))
      setBasePrice(contract.price)

      var paymentIntent = await axiosInstance.post('/api/create-payment-intent', {
        firstName: 'Fred',
        lastName: 'Flintstone',
        price: contract.price
      });

      if (paymentIntent.data.statusCode == 200) {
        setPaymentIntentId(paymentIntent.data.id);
        setClientSecret(paymentIntent.data.client_secret);
      } else {
        console.log("Error courred setting payment intent client secret", paymentIntent.data);
      }

    }

    fetchData().catch(error => {
      console.error(error);
      Sentry.captureException(error);
    });

  }, []);

  function loginFirst() {
    if (!providerWeb3Auth && document.getElementById("w3a-modal") == null) {
      let wcbutton: HTMLElement = document.getElementById("walletConnecDiv")?.children[0] as HTMLElement;
      wcbutton.click();
      let modalFullName: HTMLElement = document.getElementById("fullNameInput") as HTMLElement;
      modalFullName.blur();
      let modalCompany: HTMLElement = document.getElementById("companyInput") as HTMLElement;
      modalCompany.blur()
      return;
     }
  }


  const [name, setName] = useState('')
  const [company, setCompany] = useState('')

  // Stripe
  const stripe = useStripe();
  const elements = useElements();


  // User
  const history = useHistory();
  const [message, setMessage] = useState<SideComponent>({ isVisible: false, loading: false });

  const handleSubmit = async (event: React.SyntheticEvent) => {
    event.preventDefault();

    setMessage({ isVisible: false, loading: true });

    const initializeMint = async (total: number, account: string, orderId: number, email: string) => {

      try {
        // Mint using biconomy
        const { error: mintError, txn, formatedTokenIds } = await mintTicket([biconomy], contract.address, contract.abi);

        if (mintError) {
          Sentry.captureException(mintError);
          setMessage({ isVisible: true, title: 'Mint Error', message: mintError, loading: false });
          return
        }


        if (!txn) {
          Sentry.addBreadcrumb({category: "User", message: "User: " + walletemail})
          Sentry.captureException("Error ocurred generating transaction. Minting error");
          setMessage({ isVisible: true, title: 'Mint Error', message: 'Error ocurred generating transaction. Please contact dbloks support.', loading: false });
          return
        }


        // Check if transaction is in production or testing
        var url = contract.networkScanner + '/tx/' + txn.hash;


        // Create order in dBloks
        const order = {
          data: {
            email: walletemail,
            name,
            total,
            company,
            quantity: 1,
            transactionHash: txn.hash,
            blockchainTransactionUrl: url
          }
        };

        const { data: orderCreated } = await axiosInstance.post('/api/orders', order);
  
        if (orderCreated.data.id) {
          console.log("Successfully created order in DB");
        } else {
          Sentry.captureException(orderCreated.data);
          console.log("Error ocurred creating order:", orderCreated);
        }

        const formatPrice = 0
        const query = {
          url,
          email: walletemail,
          orderId,
          transactionHash: txn.hash,
          tokenId: formatedTokenIds,
          userAddress: account,
          nftQty: 1,
          pricePerNFT: formatPrice,
          pkey: process.env.REACT_APP_PKEY
        }

        // Send Email
        const body = qs.stringify(query);
        const response = await axios.post('https://dbloks.com/wp-content/themes/neve-child/investor_mixer/MintMail.php', body, {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded"
          }
        });

        if (response?.data?.su === 200) {
          console.log("Email sent successfully", response?.data?.mssg);
        } else {
          Sentry.captureException(response?.data);
          console.log("Error ocurred sending email:", response?.data?.mssg);
        }

        setMessage({isVisible: false, loading: false});
  
        history.push("/success-registration?transactionUrl=" + url);

      } catch (error: any) {
        console.error(error);
        Sentry.captureException(error);

        if (error.message === 'Sale is not active') {
          return history.push("/error-registration?message=" + error.message)
        }

        history.push("/error-registration");
      }
    }

    if (!providerWeb3Auth) {
      let wcbutton: HTMLElement = document.getElementById("walletConnecDiv")?.children[0] as HTMLElement;
      wcbutton.click();
      console.log("Make sure you have a wallet connected!");
      setMessage({ isVisible: false, loading: false });
      return;
    }

    const library = new ethers.providers.Web3Provider(providerWeb3Auth);
    const accounts = await library.listAccounts();

    // TODO: If no wallet is connected it should ask for wallet connection
    // @ts-ignore
    if (accounts.length === 0) {
      console.log("No wallet connected");
      setMessage({ isVisible: true, title: 'Connect Wallet', message: 'Please connect wallet in order to complete transaction.', loading: false })
      return
    }

    const target = event.target as typeof event.target & {
      name: { value: string },
      company: { value: string }
    };

    const name = target.name.value
    const company = target.company.value

    setName(name)
    setCompany(company)

    if (contract.price === 0) {

      const body = qs.stringify({
        name,
        company,
        email: walletemail,
        nftQty: 1,
        pricePerNFT: 0,
        totalPrice: 0,
        userAddress: accounts[0],
        contractAddress: contract.address,
        pkey: process.env.REACT_APP_PKEY
      });
  
      // Send Email
      const response = await axios.post('https://dbloks.com/wp-content/themes/neve-child/investor_mixer/EmailManager.php', body, {
        headers: {
          "Content-Type": "application/x-www-form-urlencoded"
        }
      });
  
      if (response?.data?.su === 200) {
        console.log("Order email sent successfully", response?.data?.mssg);
      } else if(response?.data?.su === 401) {
        setMessage({ isVisible: true, title: 'Registration Message', message: "Account already registered", loading: false });
        return;
      } else{
        Sentry.captureException(response.data);
        console.log("Error ocurred sending order email:", response?.data?.mssg);
      }

      return initializeMint(0, accounts[0], response?.data?.mssg, walletemail);
    }

    // Validate stripe
    if (elements == null || stripe == null) {
      return;
    }

    const card = elements.getElement(CardElement)!;
    const { error, paymentIntent: payment } = await stripe.confirmCardPayment(clientSecret, {
      payment_method: {
        card: card
      },
      receipt_email: walletemail
    });


    if (error) {
      console.log(error.message);
      Sentry.captureException(error);
      setMessage({ isVisible: true, title: 'Card Error', message: error.message, loading: false })
      return
    }

    // Format Body
    const formatPrice = 0
    const body = qs.stringify({
      email: walletemail,
      userAddress: accounts[0],
      nftQty: 1,
      pricePerNFT: formatPrice,
      totalPrice: formatPrice,
      pkey: process.env.REACT_APP_PKEY
    });

    // Send Email
    const response = await axios.post('https://dbloks.com/wp-content/themes/neve-child/investor_mixer/EmailManager.php', body, {
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      }
    });

    if (response?.data?.su === 200) {
      console.log("Payment email sent successfully", response?.data?.mssg);
    } else {
      Sentry.captureException(response?.data);
      console.log("Error ocurred sending payment email:", response?.data?.mssg);
      return setMessage({ isVisible: true, title: 'Order Error', message: 'Order could not be created.', loading: false })
    }

    initializeMint(0, accounts[0], response?.data?.mssg, walletemail);

  };

  const [roleInputDisplay, setInputDisplay] = useState("hidden");

  function validateRole(e: any){
    if(e.target.value == "Other"){
      setInputDisplay("block");
    }else{
      setInputDisplay("hidden");
    }
  }
  

  return (
    <form onSubmit={handleSubmit}>
      <div className="divide-y divide-neutral-100 dark:divide-neutral-800">

        {/* ---------- 6 ----------  */}
        <div className="py-9 border-none">
          <EventTime time={contract.eventDateTime} />
        </div>

        <ModalLoading show={message.loading} props={{ title: 'Lookout for an email from nft@dbloks.com that will arrive in your inbox shortly.' }} onCloseModalDelete={() => { console.log("Selected close on loading modal") }} />

        <ModalSimple show={message.isVisible} props={{ title: message?.title, message: message?.message }} onCloseModalDelete={() => setMessage({ isVisible: false, loading: false })} />

        <PaymentOptions contract={contract} price={price} />

        <div className="pb-9 pt-10">

          <div className="grid grid-cols-1">
            <label className="block mb-2">
              <Label>Full name</Label>

              <Input
                placeholder="Full Name"
                type="text"
                className="mt-1"
                id="fullNameInput"
                name="name"
                required
                onChange={loginFirst}
                onClick={loginFirst}
              />
            </label>

            <label className="block mb-2">
              <Label>Company</Label>

              <Input
                placeholder="Company"
                type="text"
                className="mt-1"
                id="companyInput"
                name="company"
                required
                onChange={loginFirst}
                onClick={loginFirst}
              />
            </label>
          </div>

          <BuyButton {...contract} />

          <div className="mt-10">
            <AccordionInfo
              address={contract.address}
              description={contract.description}
            />
          </div>

        </div>
      </div>
    </form>
  );
};

const InvestorMixerPage: FC<NftDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {

  let { id } = useParams<PageParams>();

  const [clientSecret, setClientSecret] = useState("pi_3LWyDlA7z1eMDaeK0sR79qAX_secret_FsDs3uMvNivwijCjBEQxOHiu2");
  const [contract, setContract] = useState<Contract>({
    abi: [{}],
    name: "",
    price: 0,
    address: "",
    network: "",
    askEmail: true,
    description: "",
    eventDateTime: "",
    networkScanner: "",
    municipalityTaxPercent: 0,
    stateTaxPercent: 0,
    mainImage: {
      data: {
        attributes: {
          url: ''
        }
      }
    }
  });

  useEffect(() => {

    // Get payment intent
    const fetchData = async () => {

      var { data: res } = await axiosInstance.get('/api/contracts/' + id + '?populate=mainImage');
      var contract = res.data.attributes
      setContract(contract)

    }

    fetchData().catch(error => {
      console.error(error)
      Sentry.captureException(error);
    });

  }, []);

  const options: StripeElementsOptions = {
    clientSecret
  };

  const image = contract.mainImage.data ? contract.mainImage.data.attributes.url : '';

  return (
    <div
      className={`nc-NftDetailPage  ${className}`}
      data-nc-id="NftDetailPage"
    >
      {/* MAIN  */}
      <main className="container flex">
        <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-10 md:gap-14">
          {/* CONTENT */}
          <div className="space-y-8 lg:space-y-10">
            {/* HEADING */}
            <div className="space-y-5">
              <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
                {contract.name}
              </h2>
            </div>
            <div className="relative">
              <NcImage
                src={image}
                containerClassName="aspect-w-11 aspect-h-12 rounded-3xl overflow-hidden"
              />
            </div>

            {/* <AccordionInfo /> */}
          </div>

          {/* SIDEBAR */}
          <div className="pt-10 lg:pt-0 xl:pl-10 border-t-2 border-neutral-200 dark:border-neutral-700 lg:border-t-0">
            <Elements stripe={stripePromise} options={options}>
              <Checkout contract={contract} id={id} />
            </Elements>
          </div>
        </div>
      </main>

    </div>
  );
};

export default InvestorMixerPage;